glympse.Loc.Langs["zh"] =
glympse.Loc.Langs["zh_hans"] =
{
    locale: ["zh","zh_hans"],
    generated: "2014-09-04T21:11:34.2050721Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "目的地",
        M_EXPIRED: "已到期",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "群组",
        M_JOIN_GROUP: "加入群组",
        M_KMH: "公里\/小时",
        M_MPH: "英里\/小时",
        M_OFF: "关闭",
        M_ON: "开启",
        M_SETTINGS: "设置",
        M_UNITS: "速度单位",
        M_CLOSE: "关闭",
        M_VIEW_MAP: "查看地图",
        M_SEND_GLYMPSE: "发送 Glympse",
        M_VIEW : "查看",
        M_MAP: "地图",
        M_AGO: "前",
        M_LAST_UPDATED: "更新于 ",
        M_SPEED: "速度",
        M_EXPIRES_IN: "到期前还有",
        M_ARRIVES_IN: "到达前还有",
        M_DAY: "天",
        M_DAYS: "天",
        M_HOUR: "小时",
        M_HOURS: "小时",
        M_LOADING: "正在加载，请稍侯……",
        M_MINUTE: "分钟",
        M_MINUTES: "分钟",
        M_NEVER: "从不",
        M_SECOND: "秒",
        M_SECONDS: "秒",
        M_REMAINING: "剩余",
        M_EXPIRED_GLYMPSE: "%1$s 发出的分享邀请已在 %2$s前过期",
        M_EXPIRED_GLYMPSE_BTN: "查看上次更新",
        M_INVALID_GLYMPSE: "分享邀请 %1$s 已不可用",
        M_NO_LOCATION: "正在获取 %1$s 的位置，请稍候……",
        M_NO_MEMBERS: "群 %1$s 中目前没有用户。正在等待分享邀请……",
        M_NO_USERS: "没有用户在主题 %1$s 中分享位置。正在等待位置……",
        M_WAITING_TWITTER: "正在等待 %1$s 分享其位置",
        M_REQUEST_GLYMPSE: "%1$s 发出的分享您位置的请求",
        M_DOWNLOAD_APP: "获取应用",
        M_LAUNCH_APP: "在应用程序中查看",
        M_GET_ADDR: "正在获取地址",
        M_SHARE_LOC: "分享您的位置！",
        M_STREET: "街道",
        M_SATELLITE: "卫星",
        M_TRAFFIC: "交通",
        M_YOUR_LOCATION: "您的位置",
        M_DEALS: "交易",
        M_WEATHER: "天气",
        M_HELP: "帮助",
        M_FEEDBACK: "反馈",
        M_DEBUG: "调试",
        M_SUBMIT_A_BUG: "提交缺陷",
        M_SEND_MESSAGE: "发送消息",
        M_TAG: "标签",
        M_ALTITUDE: "海拔",
        M_METERS: "米",
        M_FEET: "英尺",
        messages: [
            "快到了！",
            "看看吧！",
            "上路了！",
            "要回家喽！",
            "今晚就住这儿了！",
            "我来了，你在哪儿？",
            "嘿，看我到哪儿了！",
            "马上就到。",
            "我快到了！",
            "我爱上 Glympse 了！",
            "凉菜忘了，但我带回来了意大利腊肠。",
            "我想我迷路了。",
            "就在附近……",
            "正在登记入住。",
            "正要走。",
            "我们在这儿见。",
            "在这儿见面吃午饭。",
            "到时来见我。",
            "不趁我在这儿告诉我需要什么？",
            "要晚了 :(",
            "一会儿见。",
            "堵车了。",
            "你还真是跟得紧！",
            "要吃的吗？",
            "看我。"
        ],
        M_NEVER_LOCATION: "无法获取 %1$s 的位置",
        M_TERRAIN: "地形",
        M_DOWNLOAD_ACTIVE: "...来分享您的位置",
        M_DOWNLOAD_INACTIVE: "...来请求新的 Glympse",
        M_DOWNLOAD_APP_ALT: "获取 Glympse",
        M_HERE_DL_MOBILE: "获取 Glympse",
        M_HERE_DL_FULL: "获取 Glympse",
        M_NO_NAME: "朋友"
    }
};
