glympse.Loc.Langs["cs"] =
glympse.Loc.Langs["cs_cz"] =
{
    locale: ["cs","cs_cz"],
    generated: "2014-09-04T21:11:34.4390751Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Cíl",
        M_EXPIRED: "Neplatné",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Skupina",
        M_JOIN_GROUP: "Připojit se ke skupině",
        M_KMH: "km\/h",
        M_MPH: "mil za hodinu",
        M_OFF: "VYPNOUT",
        M_ON: "ZAPNOUT",
        M_SETTINGS: "Nastavení",
        M_UNITS: "Jednotky",
        M_CLOSE: "Zavřít",
        M_VIEW_MAP: "Prohlédnout si mapu",
        M_SEND_GLYMPSE: "Poslat Glympse",
        M_VIEW : "Prohlížení",
        M_MAP: "Mapa",
        M_AGO: "před",
        M_LAST_UPDATED: "Aktualizováno",
        M_SPEED: "Rychlost",
        M_EXPIRES_IN: "Platnost vyprší za",
        M_ARRIVES_IN: "Dorazí za",
        M_DAY: "den",
        M_DAYS: "dny",
        M_HOUR: "hodina",
        M_HOURS: "hodiny",
        M_LOADING: "Nahrává se, počkejte prosím…",
        M_MINUTE: "minuta",
        M_MINUTES: "minuty",
        M_NEVER: "nikdy",
        M_SECOND: "sekunda",
        M_SECONDS: "sekundy",
        M_REMAINING: "zbývající",
        M_EXPIRED_GLYMPSE: "Platnost Glympse od %1$s vypršela před %2$s",
        M_EXPIRED_GLYMPSE_BTN: "Prohlédnout si poslední aktualizaci",
        M_INVALID_GLYMPSE: "Glympse %1$s již není k dispozici",
        M_NO_LOCATION: "Nahrávání pozice %1$s Počkejte, prosím…",
        M_NO_MEMBERS: "Ve skupině %1$s momentálně nikdo není. Čekám na nějaký Glympse...",
        M_NO_USERS: "Nikdo nesdílí téma %1$s. Čekám na pozici…",
        M_WAITING_TWITTER: "Čekám až %1$s bude sdílet svou pozici",
        M_REQUEST_GLYMPSE: "%1$s vás žádá, abyste začali sdílet svou pozici",
        M_DOWNLOAD_APP: "Získat aplikaci",
        M_LAUNCH_APP: "View in App",
        M_GET_ADDR: "Stahuji adresu",
        M_SHARE_LOC: "Sdílet vaši pozici!",
        M_STREET: "Ulice",
        M_SATELLITE: "Satelitní náhled",
        M_TRAFFIC: "Provoz",
        M_YOUR_LOCATION: "Vaše pozice",
        M_DEALS: "Nabídky",
        M_WEATHER: "Počasí",
        M_HELP: "Pomoc",
        M_FEEDBACK: "Zpětná vazba",
        M_DEBUG: "Ladění",
        M_SUBMIT_A_BUG: "Zaslat chybu v aplikaci",
        M_SEND_MESSAGE: "Poslat zprávu",
        M_TAG: "Dát babu",
        M_ALTITUDE: "Nadmořská výška",
        M_METERS: "m n. m.",
        M_FEET: "ft",
        messages: [
            "Už jsem skoro tam!",
            "Tohle musíš vidět!",
            "Jsem na cestě!",
            "Jsem na cestě domů!",
            "Tady budu dnes večer!",
            "Jsem zde, kde jsi ty?",
            "Mrkni, kde právě jsem!",
            "Hned tam budu.",
            "Už jsem skoro tam!",
            "Glympse se mi začíná vážně líbit!",
            "Zapomněl\/a jsem salát, ale mám salám.",
            "Asi jsem se ztratil\/a.",
            "Jsem poblíž…",
            "Jsem u recepce.",
            "Právě odcházím.",
            "Sejdeme se tady.",
            "Zde se sejdeme na oběd.",
            "Sejdeme se, až dorazím.",
            "Budeš něco potřebovat, zatímco jsem tady?",
            "Mám zpoždění :(",
            "Za chvilku se uvidíme.",
            "Trčím v dopravní zácpě.",
            "Máš babu!",
            "Chceš něco k jídlu?",
            "Hlídej mě."
        ],
        M_NEVER_LOCATION: "Umístění uživatele %1$s není k dispozici",
        M_TERRAIN: "Terén",
        M_DOWNLOAD_ACTIVE: "...ke sdílení svého umístění",
        M_DOWNLOAD_INACTIVE: "...k zažádání o nový Glympse",
        M_DOWNLOAD_APP_ALT: "Získat Glympse",
        M_HERE_DL_MOBILE: "Získat Glympse",
        M_HERE_DL_FULL: "Získat Glympse",
        M_NO_NAME: "Přítel"
    }
};
