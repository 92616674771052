glympse.IMG["ss-alt20"] = {
    "meta": {"name":"ss-alt20.png","w":490,"h":403}
    , "airlineActive": {"x":175,"y":260,"w":70,"h":90}
    , "airlineColor": {"x":247,"y":260,"w":70,"h":90}
    , "airlineExpired": {"x":103,"y":260,"w":70,"h":90}
    , "dest": {"x":433,"y":185,"w":45,"h":71}
    , "h_check": {"x":255,"y":219,"w":30,"h":30}
    , "h_checkin": {"x":377,"y":260,"w":42,"h":42}
    , "h_checkinDest": {"x":253,"y":141,"w":48,"h":48}
    , "h_chevDown": {"x":224,"y":195,"w":36,"h":20}
    , "h_chevUp": {"x":186,"y":195,"w":36,"h":20}
    , "h_layers": {"x":377,"y":304,"w":40,"h":40}
    , "h_lock": {"x":225,"y":219,"w":28,"h":32}
    , "h_logo": {"x":1,"y":71,"w":300,"h":68}
    , "h_logo_white": {"x":1,"y":1,"w":300,"h":68}
    , "h_markerHalo": {"x":303,"y":1,"w":128,"h":128}
    , "h_minus": {"x":262,"y":195,"w":34,"h":7}
    , "h_myIcon": {"x":1,"y":362,"w":40,"h":40}
    , "h_nubCenter": {"x":43,"y":362,"w":18,"h":19}
    , "h_plus": {"x":189,"y":219,"w":34,"h":34}
    , "h_pointerLeft": {"x":433,"y":258,"w":55,"h":47}
    , "h_tabBg": {"x":1,"y":141,"w":183,"h":76}
    , "h_unlock": {"x":151,"y":219,"w":36,"h":34}
    , "h_watermark": {"x":1,"y":219,"w":148,"h":39}
    , "here": {"x":1,"y":260,"w":100,"h":100}
    , "hereBlue": {"x":186,"y":141,"w":65,"h":52}
    , "markerActive": {"x":433,"y":1,"w":56,"h":90}
    , "markerColor": {"x":319,"y":260,"w":56,"h":90}
    , "markerExpired": {"x":433,"y":93,"w":56,"h":90}
    , "markerMap": {"x":433,"y":307,"w":41,"h":52}
    , "unknown": {"x":303,"y":131,"w":120,"h":120}
};
