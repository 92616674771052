glympse.Loc.Langs["th"] =
glympse.Loc.Langs["th_th"] =
{
    locale: ["th","th_th"],
    generated: "2014-09-04T21:11:35.1722845Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "ปลายทาง",
        M_EXPIRED: "หมดอายุ",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "กลุ่ม",
        M_JOIN_GROUP: "เข้าร่วมกลุ่ม",
        M_KMH: "กม.\/ชม.",
        M_MPH: "ไมล์ต่อชม.",
        M_OFF: "ปิด",
        M_ON: "เปิด",
        M_SETTINGS: "การตั้งค่า",
        M_UNITS: "หน่วย",
        M_CLOSE: "ปิด",
        M_VIEW_MAP: "ดูแผนที่",
        M_SEND_GLYMPSE: "ส่ง Glympse",
        M_VIEW : "ดู",
        M_MAP: "แผนที่",
        M_AGO: "ที่แล้ว",
        M_LAST_UPDATED: "อัพเดทเมื่อ",
        M_SPEED: "ความเร็ว",
        M_EXPIRES_IN: "หมดอายุใน",
        M_ARRIVES_IN: "จะถึงภายใน",
        M_DAY: "วัน",
        M_DAYS: "วัน",
        M_HOUR: "ชม.",
        M_HOURS: "ชม.",
        M_LOADING: "กำลังโหลด กรุณารอ…",
        M_MINUTE: "นาที",
        M_MINUTES: "นาที",
        M_NEVER: "ไม่เคย",
        M_SECOND: "วิ",
        M_SECONDS: "วิ",
        M_REMAINING: "เหลือ",
        M_EXPIRED_GLYMPSE: "Glympse จาก %1$s หมดอายุเมื่อ %2$s ที่แล้ว",
        M_EXPIRED_GLYMPSE_BTN: "ดูอัพเดทล่าสุด",
        M_INVALID_GLYMPSE: "Glympse %1$s ไม่สามารถดูได้แล้ว",
        M_NO_LOCATION: "กำลังโหลดตำแหน่งที่ตั้งของ %1$s กรุณารอ...",
        M_NO_MEMBERS: "ไม่มีใครอยู่ในกลุ่ม %1$s กำลังรอ Glympses... ",
        M_NO_USERS: "ไม่มีใครแชร์ในหัวข้อ %1$s กำลังรอตำแหน่งที่ตั้ง...",
        M_WAITING_TWITTER: "กำลังรอให้ %1$s แชร์ตำแหน่งที่ตั้ง",
        M_REQUEST_GLYMPSE: "คำขอจาก %1$s เพื่อแชร์ตำแหน่งที่ตั้งของคุณ",
        M_DOWNLOAD_APP: "ดาวน์โหลดแอพ",
        M_LAUNCH_APP: "ดูในแอพ",
        M_GET_ADDR: "กำลังโหลดที่อยู่",
        M_SHARE_LOC: "แชร์ตำแหน่งที่ตั้งของคุณ!",
        M_STREET: "ถนน",
        M_SATELLITE: "ดาวเทียม",
        M_TRAFFIC: "จราจร",
        M_YOUR_LOCATION: "ตำแหน่งที่ตั้งของคุณ",
        M_DEALS: "ส่วนลด",
        M_WEATHER: "สภาพอากาศ",
        M_HELP: "ช่วยเหลือ",
        M_FEEDBACK: "ผลตอบรับ",
        M_DEBUG: "แก้ไขข้อผิดพลาด",
        M_SUBMIT_A_BUG: "ส่งข้อผิดพลาด",
        M_SEND_MESSAGE: "ส่งข้อความ",
        M_TAG: "แท็ก",
        M_ALTITUDE: "ความสูง",
        M_METERS: "ม.",
        M_FEET: "ฟุต",
        messages: [
            "เกือบถึงแล้ว!",
            "ดูนี่สิ!",
            "กำลังไป!",
            "กำลังกลับบ้านแล้ว!",
            "คืนนี้ฉันจะไปสนุกที่นี่!",
            "ฉันอยู่ที่นี่ แล้วเธอล่ะ?",
            "เฮ้ ดูสิว่าฉันอยู่ที่ไหน!",
            "ฉันกำลังรีบไป",
            "ฉันเกือบถึงแล้ว!",
            "ฉันตกหลุมรัก Glympse เข้าแล้วล่ะ!",
            "ฉันลืมสลัด แต่จะเอาซาลามี่ไปแทน",
            "ฉันว่าฉันหลงทางแล้วล่ะ",
            "อยู่แถวนี้...",
            "แค่เช็คอินเฉยๆ",
            "กำลังจะออกไปแล้ว",
            "มาเจอกันที่นี่ดีกว่า",
            "มาเจอฉันมื้อกลางวันที่นี่นะ",
            "เจอกันเมื่อฉันไปถึงที่นั่นนะ",
            "จะเอาอะไรจากที่นี่ไหม?",
            "สายแล้วล่ะ :(",
            "เดี๋ยวเจอกันนะ",
            "รถติดอยู่เลย",
            "แท็กเธอด้วย!",
            "อยากจะกินอะไรไหม?",
            "คอยดูฉันนะ"
        ],
        M_NEVER_LOCATION: "ไม่พบที่ตั้งของ %1$s",
        M_TERRAIN: "ภูมิประเทศ",
        M_DOWNLOAD_ACTIVE: "...เพื่อแบ่งปันตำแหน่งที่ตั้งของคุณ",
        M_DOWNLOAD_INACTIVE: "…เพื่อขอ Glympse ใหม่",
        M_DOWNLOAD_APP_ALT: "ดาวน์โหลด Glympse",
        M_HERE_DL_MOBILE: "ดาวน์โหลด Glympse",
        M_HERE_DL_FULL: "ดาวน์โหลด Glympse",
        M_NO_NAME: "เพื่อน"
    }
};
