glympse.Loc.Langs["pl"] =
glympse.Loc.Langs["pl_pl"] =
{
    locale: ["pl","pl_pl"],
    generated: "2014-09-04T21:11:34.3610741Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Cel",
        M_EXPIRED: "Upłynęło",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupa",
        M_JOIN_GROUP: "Dołącz do grupy",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "WŁĄCZ",
        M_ON: "WYŁĄCZ",
        M_SETTINGS: "Ustawienia",
        M_UNITS: "Jednostki",
        M_CLOSE: "Zamknij",
        M_VIEW_MAP: "Zobacz mapę",
        M_SEND_GLYMPSE: "Wyślij Glympse",
        M_VIEW : "Zobacz",
        M_MAP: "Mapa",
        M_AGO: "temu",
        M_LAST_UPDATED: "Zaktualizowane",
        M_SPEED: "Prędkość",
        M_EXPIRES_IN: "Upływa za",
        M_ARRIVES_IN: "Przybywa za",
        M_DAY: "dzień",
        M_DAYS: "dni",
        M_HOUR: "godz.",
        M_HOURS: "godz.",
        M_LOADING: "Ładowanie, proszę czekać...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "nigdy",
        M_SECOND: "sek",
        M_SECONDS: "sek",
        M_REMAINING: "pozostało",
        M_EXPIRED_GLYMPSE: "Zaproszenie Glympse od %1$s upłynęło %2$s temu",
        M_EXPIRED_GLYMPSE_BTN: "Zobacz ostatnią aktualizację",
        M_INVALID_GLYMPSE: "Zaproszenie Glympse %1$s nie jest już dostępne",
        M_NO_LOCATION: "Ustalanie lokalizacji  %1$s Proszę czekać...",
        M_NO_MEMBERS: "Aktualnie nie ma nikogo w grupie %1$s Oczekiwanie na zaproszenia Glympse...",
        M_NO_USERS: "Nikt nie rozmawia na temat %1$s Oczekiwanie na lokalizacje...",
        M_WAITING_TWITTER: "Oczekiwanie na podanie lokalizacji przez %1$s ",
        M_REQUEST_GLYMPSE: "Prośba od %1$s o podanie twojej lokalizacji",
        M_DOWNLOAD_APP: "Pobierz aplikację",
        M_LAUNCH_APP: "Zobacz w aplikacji",
        M_GET_ADDR: "Odzyskiwanie adresu",
        M_SHARE_LOC: "Podaj swoją lokalizację!",
        M_STREET: "Ulica",
        M_SATELLITE: "Satelita",
        M_TRAFFIC: "Ruch drogowy",
        M_YOUR_LOCATION: "Twoja lokalizacja",
        M_DEALS: "Biznes",
        M_WEATHER: "Pogoda",
        M_HELP: "Pomoc",
        M_FEEDBACK: "Opinie",
        M_DEBUG: "Debugowanie",
        M_SUBMIT_A_BUG: "Zgłoś błąd",
        M_SEND_MESSAGE: "Wyślij wiadomość",
        M_TAG: "Tag",
        M_ALTITUDE: "Szerokość geograficzna",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Już prawie!",
            "Sprawdź to!",
            "Jestem w drodze!",
            "Jestem w drodze do domu!",
            "Tutaj dziś zabawię!",
            "Jestem tutaj, a Ty gdzie? ",
            "Hej, zobacz gdzie jestem!",
            "Będę tam.",
            "Już prawie jestem!",
            "Zakochuję się w Glympse!",
            "Zapomniało mi się o sałacie, ale biorę ze sobą salami.",
            "Nie wiem, gdzie jestem.",
            "W okolicy...",
            "Tylko sprawdzam.",
            "Właśnie wychodzę.",
            "Spotkajmy się tu.",
            "Spotkajmy się tu na lunch.",
            "Kiedy już dotrę, spotkajmy się tam.",
            "Potrzebujesz czegoś, póki tu jeszcze jestem?",
            "Spóźnię się  =(",
            "Za chwilę się widzimy.",
            "Stoję w korku.",
            "Tag, gonisz!",
            "Chcesz coś do jedzenia?",
            "Obserwuj mnie."
        ],
        M_NEVER_LOCATION: "Lokalizacja %1$s nie jest dostępna.",
        M_TERRAIN: "Teren",
        M_DOWNLOAD_ACTIVE: "...aby podzielić się swoją lokalizacją",
        M_DOWNLOAD_INACTIVE: "...aby poprosić o nową lokalizację Glympse",
        M_DOWNLOAD_APP_ALT: "Pobierz Glympse",
        M_HERE_DL_MOBILE: "Pobierz Glympse",
        M_HERE_DL_FULL: "Pobierz Glympse",
        M_NO_NAME: "Znajomy"
    }
};
