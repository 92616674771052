// Namespace/common class setup
//var glympse;

(function(window)
{
	//if (glympse) return;

	var g =
	{
		// Global settings
		  PixelScale: null
		, fonts: 'Asap,Calibri,sans-serif'
		, ssEx: ''	// Sprite sheet name extension from default
		, assetBase: 'https://d2qgdsybzkcpgh.cloudfront.net'

		// Namespace
		, viewer: { cnt: 0, apps: {} }
		, controllers: {}
		, data: {}
		, models: {
			bots: {}
			, map: {}
			, net: {
				extern: {}
				, providers: {}
			}
		}
		, structs: {}
		, views: {
			controls: {}
			, map: {}
			, markers: {}
		}
		, Area: {}
		, Helpers: {}
		, IMG: {}

		// Defines
		, TravelMode: {
			Driving: "driving",
			Airline: "airline",
			Default: "driving"
		}

		, excludeConfig: {	// Config exclude flags
			None: 0,
			Hosted: 1,
			QueryString: 2,
			All: 3
		}

		, balloonMode: {	// Avatar balloon mode
			None: -1,
			Full: 0,
			NameOnly: 1,
			NameAvatar: 2,
			EtaSimple: 3,
			NameInfo: 4
		}

		, destLabelMode: {	// Destination label mode
			None: -1,
			Name: 0,
			EtaSimple: 1
		}

		, MSG: {	// Messaging between views/controllers

			//  ToggleFollowUser: 0		//"ToggleFollowUser"
			//, ToggleFollowDest: 1		//"ToggleFollowDest"
			//, ToggleTraffic: 2		//"ToggleTraffic"
			//, UpdateMetric: 3			//"UpdateMetric"
			//, SetSatellite: 4			//"SetSatellite"
			//  MapComponentsLoaded: 5	//"MapComponentsLoaded"
			  UiReady: 6				//"UiReady"
			, IntroDisplayed: 7			//"IntroDisplayed"
			, InviteLoaded: 8			//"InviteLoaded"
			, InviteLoadComplete: 9		//"InviteLoadComplete"
			, ChangedActiveInvite: 10	//"ChangedActiveInvite"
			, GetNewInviteView: 11		//"GetNewInviteView"
			, InviteListChanged: 12		//"InviteListChanged"
			, ToggleFullHeaderView: 13	//"ToggleFullHeaderView"
			, ClickedDownload: 14		//"ClickedDownload"
			, InviteRemoved: 15			//"InviteRemoved"
			, SelectedUser: 16			//"SelectedUser"
			, DlgExpiredUser: 17		//"DlgExpiredUser"
			, DlgUnknownInvite: 18		//"DlgUnknownInvite"
			, DlgGettingLocation: 19	//"DlgGettingLocation"
			, NoGroupUsers: 20			//"NoGroupUsers"
			, DlgRequestGlympse: 21		//"DlgRequestGlympse"
			, SelectedDetail: 22
			, GroupLoaded: 23
			, NoTopicUsers: 24
			, NewTrackedItemList: 25
			, GetMap: 26
			, AddInvites: 27
			, AddTwitters: 28
			, AddTopics: 29
			, AddGroups: 30
			, CfgLoaded: 31
			//, NavigateTag: 32
			, GotLocation: 33
			, TilesLoaded: 34
			//, AutoviewSelection: 35
			, RemoveInvites: 36
			, DlgNoInvites: 37
			, ShowOptions: 38
			, ChangeOption: 39
			, OptionsComplete: 40
			, UpdateZoom: 41
			, ToggleLock: 42
			, SetLockState: 43
			, SetHeaderToggle: 44
			, WatermarkClicked: 45
			, Refresh: 46
			, GetInvites: 47
			, SetPadding: 48
			, AddMarkers: 49
			, IgnoreDestinations: 50
			, GenerateRoute: 51
			, UpdateSetting: 52
			, ZoomChanged: 53
			, GetMapContainer: 54
			, DeactivateInviteView: 55
		}
		, events: {	// Internal event definitions
			INIT: 'gly_init',
			READY: 'gly_ready',
			ERROR: 'gly_error',
			PROPERTIES: 'gly_properties',
			DATA: 'gly_data',
			ETA: 'gly_eta',
			INVITE_ADDED: 'gly_invite_added',
			INVITE_REMOVED: 'gly_invite_removed',
			INVITE_CLICKED: 'gly_invite_clicked',
			INVITE_VIEW_CHANGED: 'gly_invite_view_changed',
			MARKER_CLICKED: 'gly_marker_clicked',
			INVITE_ERROR: 'gly_invite_error',
			OAUTH_TOKEN_ERROR: 'gly_oauth_error',
			ZOOM_CHANGED: 'gly_zoom_changed',
			USER_DETAILS_CHANGED: 'gly_user_details_changed',
			NO_VISIBLE_DATA: 'gly_no_visible_data'
		}

		, broadcastTypes: {	// "Flags" to invoke additional events
			  DATA: 'DATA'	// Notify when data or properties objects appear in an invites/ API response (data = data/properties received in Object format)
			, ETA: 'ETA'	// Notify when local ETA clock updates (data = current ETA, in seconds)
			, INVITE_STATUS: 'INVITE_STATUS'	// Notification when an invite is added or removed (INVITE_ADDED or INVITE_REMOVED)
			, ZOOM: 'ZOOM'	// Notify when map zoom is changed
		}

		, dialogFlags: {
			  NoLocation: 1
			, Expired: 2
			, InvalidInvite: 4
			, EmptyGroup: 8
		}

		// Settings UI
		, ui: {
			options: {
				mapType: "mapType",
				mapTypeDefault: "normal",
				mapTypeDefaultAlt: "street",
				mapTypeDefaultOld: "roadmap",
				toggle: "toggle",
				traffic: "showTraffic",
				units: "units",
				unitsMetric: "metric",
				unitsImperial: "imperial",
				zoom: "zoom"
			}
			, positions: {// UI layout positions
				LEFT: "LEFT",
				LEFT_BOTTOM: "LEFT_BOTTOM",
				LEFT_TOP: "LEFT_TOP",
				TOP_CENTER: "TOP_CENTER",
				TOP_LEFT: "TOP_LEFT",
				TOP_RIGHT: "TOP_RIGHT",
				RIGHT: "RIGHT",
				RIGHT_BOTTOM: "RIGHT_BOTTOM",
				RIGHT_TOP: "RIGHT_TOP",
				BOTTOM_CENTER: "BOTTOM_CENTER",
				BOTTOM_LEFT: "BOTTOM_LEFT",
				BOTTOM_RIGHT: "BOTTOM_RIGHT"
			}
		}

		, progress: {
			// Loader
			Init: "init",			// Loader initialized
			Core: "core",			// GMaps loader / HERE core.js load
			Svc: "svc",				// HERE services.js load
			Events: "events",		// HERE events.js load
			Canvas: "canvas",		// canvas check
			App: "app",				// glympse viewer app load
			Finalize: "finalize",	// All external loads completed
			ExtSsCfg: "ExtSsCfg",	// Front-loaded extCfg
			Font: "font",			// font checks

			// App
			Invite: "invite",		// Glympse API query (initial)
			//Intro: "intro",		// intro loading
			Ss: "ss",				// sprite sheet loading
			ExtCfg: "extCfg"		// external config loading
		}
	};

	// Reuse some settings
	var opts = g.ui.options;
	opts.entries = [
	  { id: "Street", label: "M_STREET", cfg: { option: opts.mapType, val: [opts.mapTypeDefault, opts.mapTypeDefaultAlt, opts.mapTypeDefaultOld] } }
	, { id: "Satellite", label: "M_SATELLITE", cfg: { option: opts.mapType, val: "satellite" } }
	, { id: "Terrain", label: "M_TERRAIN", cfg: { option: opts.mapType, val: "terrain" } }
	, { id: "Traffic", label: "M_TRAFFIC", cfg: { option: opts.traffic, val: opts.toggle } }
	, { id: "Units", label: "M_UNITS", cfg: { option: opts.units, val: opts.toggle } }
	];

	// Create global reference
	window.glympse = g;

})(window);
