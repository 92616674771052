glympse.uiThemeAlternate =
{
    application: {
        scaleTargetSuffix: "-alt",
        defaultDownloadLink: "http://here.com/download"
    },
    defProfile: {
        hideHalo: true,
        color: "32C6F4"
    },
    global: {
        altUi: true,
        ssEx: "h_",
        defaults: {
            balloonColor: "rgba(39,49,66,0.8)"
        }
    },
    appHeader: {
        btnTxt: {
            fontStyle: "normal",
            shadowBlur: null,
            shadowOffset: null
        },
        btnBg: {
            fill: "#00C9FF"
        },
        cBtnHeight: 38,
        cBtnPaddingText: 50,
        cBtnTextFontSize: 16,
        clrBg: {
            bg: "#273142"
        },
        ctaPadding: {
            right: 5
        },
        dlBtn: {
            colorDown: "#FFFFFF",
            colorUp: "#FFFFFF",
            bgDown: "#0099CF",
            bgUp: "#00C9FF"
        },
        logoSpriteName: "here",
        logoPadding: {
            top: 0,
            left: 0
        },
        txtFontSize: 18,
        txtInfo: {
            fill: "#00C9FF"
        },
        txtLeftMargin: 16,
        txtLeftMarginMin: 4,
        txtRightMargin: 4
    },
    avatar: {
        defClrActive: "#00C9FF",
        defClrBalloon: "rgba(39,49,66,0.8)"
    },
    destination: {
        bgCornerRadius: 2,
        cBgColor: "rgba(39,49,66,0.8)"
    },
    inviteView: {
        cEtaColorAir: "#124191"
    },
    lockButton: {
        btnBorder: "1px solid #BEC8D2",
        clrBtnBgStroke: "#BEC8D2",
        fillCircUp: "rgba(255, 255, 255, 0.8)",
        fillCircDown: "rgba(0, 201, 255, 0.8)"
    },
    options: {
        btnBorder: "1px solid #BEC8D2",
        clrBgStroke: "#BEC8D2",
        fillCircUp: "rgba(255, 255, 255, 0.8)",
        fillCircDown: "rgba(0, 201, 255, 0.8)"
    },
    optionsPanel: {
        btn: {
            colorDown: "#FFFFFF",
            colorUp: "#FFFFFF",
            bgDown: "#4E5867",
            bgUp: "#000000",
            width: 230
        },
        defClrBgBack: "#EDF3F5",
        defItemHighlight: "rgba(0, 201, 255, 0.6)",
        defItemNotSelected: "#000000",
        defItemOff: "#000000",
        defItemSelected: "#000000",
        mapTypeControl: {
            type: "radio",
            bgConfig: {
                stroke: "rgba(151, 161, 173, 0.8)",
                strokeWidth: 2
            }
        },
        unitToggle: {
            hasShadow: false,
            background: {
                bg: "#BEC8D2"
            },
            foreground: {
                color: "#FFFFFF",
                bg: {
                    fillLinearGradientStartPoint: [0, 0],
                    fillLinearGradientEndPoint: [0, 30],
                    fillLinearGradientColorStops: [0, "#335DB1", 1, "#264990"]
                }
            }
        },
        trafficToggle: {
            hasShadow: false,
            width: 70,
            toggleColor: "#FFFFFF",
            enabledBg: {
                bg: "#00C9FF"
            },
            disabledBg: {
                bg: "#BEC8D2"
            }
        },
        panelCornerRadius: 0,
        uiColor: "#00C9FF",
        labelFontWeight: "normal"
    },
    simpleDialog: {
        avatarCornerRadius: 0,
        okButton: {
            colorUp: "#FFFFFF",
            colorDown: "#FFFFFF",
            bgDown: "#4E5867",
            bgUp: "#00C9FF",
            cornerRadius: 0
        }
    },
    userHeader: {
        clrExpires: "#00C9FF",
        clrName: "#4D5766",
        clrPoweredBy: "#000000",
        clrSpeed: "#4D5766",
        clrUpdated: "#98A2AE",
        /* Seems we can delete container object - properties inactive and active have never been used, height is duplicated.
            See container object {height, bgDown and bgUp} in uiTheme.js
        container: {
            height: 100,
            inactive: {
                bg: {
                    fillLinearGradientStartPoint: [0, 0],
                    fillLinearGradientEndPoint: [0, 100],
                    fillLinearGradientColorStops: [0.02, "#f5f5f6", 1, "#FFFFFF"]
                }
            },
            active: {
                bg: {
                    fillLinearGradientStartPoint: [0, 0],
                    fillLinearGradientEndPoint: [0, 100],
                    fillLinearGradientColorStops: [0.02, "#d5d5d6", 1, "#f4f4f4"]
                }
            }
        },*/
        showInfoIcon: false,
        tabSepStyles: {
            borderColor: "#F2F2F4",
            borderWidth: 1,
            height: 5
        }
    },
    userDetail: {
        avatar: {
            cfgBgShadow: false,
            size: 68
        },
        bgRadius: 0,
        bgRadiusAlt: 0,
        btn: {
            colorDown: "#FFFFFF",
            colorUp: "#FFFFFF",
            bgDown: "#4E5867",
            bgUp: "#000000",
            margin: "10px 12px 20px",
            width: "auto"
        },
        clrExpiresText: "#00C9FF",
        cfgInfoFillColor: "#273142",
        cfgInfoFontStyle: "bold",
        clrProgressBg: "#626C75",
        clrProgressFg: "#00C9FF",
        color: 0xffffff,
        defBgInfo: "#EDF3F5",
        defBgInfoAlt: "#EDF3F5",
        defLabel: "#BEC8D2",
        defTextCommon: "#4D5766",
        defUpdated: "#98A2AE",
        destAlt: null,        // id of an alternate icon to use for the destination (different than "dest")
        destFontStyle: "bold",
        footer: {
            color: "#000000",
            glympse: null
        },
        panelHeader: {
            background: "#FFFFFF",
            color: "#000000",
            glympse: null,            // Use internally generated id
            css: {
                marginLeft: 0,
                paddingTop: 9,
                lineHeight: 1
            }
        },
        partnerLogo: "hereBlue",
        separatorColor: "#BEC8D2",
        separatorColorAlt: "#EDF3F5",
        usrMessage: {
            color: "#ffffff",
            defBg: "#4E5867",
            defBgAlt: "#4E5867",
            textAlign: "center"
        },
        usrName: {
            color: "#231F20",
            fontSize: 25,
            fontSizeMin: 16,
            fontStyle: "bold"
        },
        useAltBtnBg: true,
        useAltProgress: true,
        version: {
            color: "#c5c5c5",
            colorAlt: "#e8e8e8",
            fontSize: 9
        }
    },
    zoomButtons: {
        imgMinusPos: {
            x: 11,
            y: 61
        },
        btnBorder: "1px solid #BEC8D2",
        btnGroupMarginTop: -16,
        clrBgStroke: "#BEC8D2",
        fillBtnBackground: null,
        fillCircUp: "rgba(255, 255, 255, 0.8)",
        fillCircDown: "rgba(0, 201, 255, 0.8)"
    }
};
