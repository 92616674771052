glympse.Loc.Langs["ro"] =
glympse.Loc.Langs["ro_ro"] =
{
    locale: ["ro","ro_ro"],
    generated: "2014-09-04T21:11:36.0146953Z",
    strings: {
        M_ETA: "Timp până la destinație",
        M_DESTINATION: "Destinație",
        M_EXPIRED: "Expirat",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grup",
        M_JOIN_GROUP: "Intră în grup",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "Oprit",
        M_ON: "Pornit",
        M_SETTINGS: "Setări",
        M_UNITS: "Unități",
        M_CLOSE: "Închide",
        M_VIEW_MAP: "Vezi harta",
        M_SEND_GLYMPSE: "Trimite Glympse",
        M_VIEW : "Vezi",
        M_MAP: "Harta",
        M_AGO: "în urmă",
        M_LAST_UPDATED: "updatat",
        M_SPEED: "viteza",
        M_EXPIRES_IN: "Expiră în",
        M_ARRIVES_IN: "Ajunge în",
        M_DAY: "ziua",
        M_DAYS: "zile",
        M_HOUR: "oră",
        M_HOURS: "ore",
        M_LOADING: "Se încarcă, vă rog așteptați...",
        M_MINUTE: "minute",
        M_MINUTES: "minute",
        M_NEVER: "niciodată",
        M_SECOND: "secunde",
        M_SECONDS: "secunde",
        M_REMAINING: "rămase",
        M_EXPIRED_GLYMPSE: "Glympse de la %1$s a expirat de %2$s",
        M_EXPIRED_GLYMPSE_BTN: "Vezi ultima updatare",
        M_INVALID_GLYMPSE: "Glympse %1$s nu mai este disponibil",
        M_NO_LOCATION: "Obțin locația pentru %1$s Vă rog așteptați...",
        M_NO_MEMBERS: "Nimeni nu este acum in grup %1$s Aștept Glympse...",
        M_NO_USERS: "Nimeni nu comentează pe subiectul %1$s Aștept locații...",
        M_WAITING_TWITTER: "Aștept ca %1$s să dea locația",
        M_REQUEST_GLYMPSE: "Solicitare de la %1$s să vă dați locația",
        M_DOWNLOAD_APP: "Descarcă Aplicația",
        M_LAUNCH_APP: "Vezi în aplicații",
        M_GET_ADDR: "Obținere adresă",
        M_SHARE_LOC: "Da-ți-vă locația!",
        M_STREET: "strada",
        M_SATELLITE: "satelit",
        M_TRAFFIC: "trafic",
        M_YOUR_LOCATION: "Locația dumneavoastră",
        M_DEALS: "oferte",
        M_WEATHER: "vreme",
        M_HELP: "ajutor",
        M_FEEDBACK: "Feedback",
        M_DEBUG: "depanare",
        M_SUBMIT_A_BUG: "Prezintă o problemă",
        M_SEND_MESSAGE: "Trimite mesaj",
        M_TAG: "etichetă",
        M_ALTITUDE: "Altitudinea",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Aproape am ajuns!",
            "Fii atent la asta!",
            "Am plecat!",
            "Mă îndrept spre casă acum!",
            "Uite unde mă distrez diseară!",
            "Eu sunt aici, tu unde ești?",
            "Hei, uite unde sunt!",
            "Ajung imediat.",
            "Aproape am ajuns!",
            "Mă îndrăgostesc de Glympse!",
            "Am uitat salata, dar aduc salamul.",
            "Cred că m-am rătăcit.",
            "Prin vecini...",
            "Tocmai am intrat.",
            "Tocmai am plecat.",
            "Hai să ne întâlnim aici.",
            "Hai să ne întâlnim aici să luăm masa.",
            "Ne întâlnim când ajung acolo.",
            "Ai nevoie de ceva de aici?",
            "Întârzii :(",
            "Ne vedem imediat.",
            "Blocat în trafic.",
            "Te-am atins, tu ești!",
            "Vrei să mâncăm ceva?",
            "Privește-mă."
        ],
        M_NEVER_LOCATION: "Locația pentru %1$s nu este disponibilă",
        M_TERRAIN: "Teren",
        M_DOWNLOAD_ACTIVE: "...pentru a distribui locația ta",
        M_DOWNLOAD_INACTIVE: "...pentru a cere un nou Glympse",
        M_DOWNLOAD_APP_ALT: "Descarcă Glympse",
        M_HERE_DL_MOBILE: "Descarcă Glympse",
        M_HERE_DL_FULL: "Descarcă Glympse",
        M_NO_NAME: "Un prieten"
    }
};
