/**
 * Glympse Public Group -> ![group_name] -> invite lookup
 * @author Glympse Inc., (c) 2012
 */
glympse.models.net.PublicGroup = function (cfgInvite, cfg)
{
	var idGroup = cfgInvite.id;

	// Fields
	var accessToken;
	var initialRequest = true;
	var isLoaded = false;
	var lastUpdate = 0;
	var next = 0;
	var retryAttempts = 0;
	var maxRetryAttempts = 3;
	var authTokenErrT = 0;
	var svr = cfg.services;
	var that = this;
	var inviteGroup = [];
	var users = [];
	var inviteRemove = [];

	var stopPolling = false;

	var g = glympse;
	var lib = g.lib;

	var login = { username: 'viewer', password: 'password', api_key: cfg.apiKey };
	var urlEvents = (svr + 'groups/' + idGroup + '/events');
	var urlInitial = (svr + 'groups/' + idGroup );
	var urlLogin = (svr + 'account/login');
	var urlInitialParams = cfg.extCfg ? {} : {branding: true};
	var httpHeaders = cfg.requestHeaders;


	///////////////////////////////////////////////////////////////////////////////////
	// PROPERTIES
	///////////////////////////////////////////////////////////////////////////////////

	this.cfgInvite = cfgInvite;

	this.loaded = function () { return isLoaded; };
	this.getGroupId = function () { return idGroup; };
	this.getTicketGroup = function () { return inviteGroup; };
	this.getInviteRemove = function () { return inviteRemove; };
	this.getUpdate = function (callback) { requestGroup(callback); };
	this.getAccessToken = function () { return accessToken; };
	this.getStopPolling = function () { return stopPolling; };


	///////////////////////////////////////////////////////////////////////////////////
	// PUBLICS
	///////////////////////////////////////////////////////////////////////////////////

	this.reset = function ()
	{
		isLoaded = false;
		inviteGroup = [];
		inviteRemove = [];
	};

	// Initiate the search
	this.load = function (callback)
	{
		retryAttempts = maxRetryAttempts;
		requestGroup(callback);
	};

	this.toString = function ()
	{
		return ('PublicGroup: cfg=' + this.cfgInvite
				+ ', id=' + idGroup
				+ ', invites=' + inviteGroup.length
				+ ', remove=' + inviteRemove.length
				+ ', users=' + users.length
				+ ', loaded=' + isLoaded
				);
	};


	///////////////////////////////////////////////////////////////////////////////////
	// CALLBACKS
	///////////////////////////////////////////////////////////////////////////////////

	function requestGroup(callback)
	{
		try
		{
			//console.log('AccessToken=' + accessToken);
			if (!accessToken)
			{
				//console.log('!!get access token!! -- ' + initialRequest + ' -- ' + retryAttempts);
				accessToken = g.authService.getAuthToken();
				updateHttpHeaders();
				//console.log('AccessToken=' + accessToken + ' -- ' + retryAttempts);
				//console.log('request token=' + lib.getCookie(tagAuthToken));
				if (retryAttempts > 0)
				{
					$.ajax({
						url: urlLogin,
						data: login,
						dataType: 'json',
						headers: httpHeaders
					})
					.done(function (data)
					{
						processLogin(data, callback);
					})
					.fail(function (xOptions, status)
					{
						console.log('PGLE:' + status);
						processLogin(null, callback);
					});

					return;
				}
			}

			if (initialRequest)
			{
				//console.log('::: :: INITIAL REQUEST :: ::: -- ' + idGroup);
				retryAttempts = 0;

				var sub = idGroup.toString().toLowerCase();
				if (sub === 'bryanaroundseattle')
				{
					stopPolling = true;
					//glympse.Profiles.users.splice(1, glympse.Profiles.users.length - 1);
					cfg.users.splice(1, cfg.length - 1);
					rafTimeout(function ()
					{
						processGroupInitial({ result: 'ok', response: { type: 'group', id: '119', events: 1, members: [{ id: 'DNA7-4HDZ-03WHE', invite: 'demobot0'}], public: true, name: '!BryanTheRussel' }, meta: { code: 200, time: new Date().getTime()} }, callback);
					}, 200);
				}
				else if (sub === 'preview') {
					stopPolling = true;
					cfg.users.splice(1, cfg.length - 1);
					rafTimeout(function ()
					{
						processGroupInitial({ result: 'ok', response: { type: 'group', id: '119', events: 1, members: [{ id: 'DNA7-4HDZ-03WHE', invite: 'demobot0'}], public: true, name: '!preview' }, meta: { code: 200, time: new Date().getTime()} }, callback);
					}, 200);
				}
				else if (sub === 'seattleteam')
				{
					stopPolling = true;
					//glympse.Profiles.users.splice(0, 1);
					cfg.users.splice(0, 1);
					rafTimeout(function ()
					{
						processGroupInitial({ result: 'ok', response: { type: 'group', id: '119', events: 1, members: [
											{ id: 'DNA7-4HDZ-03WH0', invite: 'demobot0' }
											, { id: 'DNA7-4HDZ-03WH1', invite: 'demobot1' }
											, { id: 'DNA7-4HDZ-03WH2', invite: 'demobot2' }
											, { id: 'DNA7-4HDZ-03WH3', invite: 'demobot3' }
											, { id: 'DNA7-4HDZ-03WH4', invite: 'demobot4' }
											, { id: 'DNA7-4HDZ-03WH5', invite: 'demobot5' }
											, { id: 'DNA7-4HDZ-03WH6', invite: 'demobot6' }
											, { id: 'DNA7-4HDZ-03WH7', invite: 'demobot7' }
											], public: true, name: '!SeattleTeam'
						}, meta: { code: 200, time: new Date().getTime() }
						}, callback);
					}, 200);
				}
				else
				{
					$.ajax({
						url: urlInitial,
						data: urlInitialParams,
						dataType: 'json',
						headers: httpHeaders
					})
						.done(function(data)
                                {
                                    processGroupInitial(data, callback);
                                })
						.fail(function(xOptions, status)
							{
								console.log('PGIE:' + status);
								processGroupInitial(null, callback);
							});
				}
			}
			else
			{
				//console.log('::: :: EVENTS REQUEST :: :::');
				$.ajax({
					url: urlEvents,
					data: { next: next },
					dataType: 'json',
					headers: httpHeaders
				})
					.done(function(data)
						{
							processGroupUpdate(data, callback);
						})
					.fail(function(xOptions, status)
						{
							console.log('PGEE:' + status);
							processGroupUpdate(null, callback);
						});

			}
		}
		catch (e)
		{
			lib.logException(e, arguments, 'grpRequest');
		}

	}

	// Update auth header with latest accessToken setting
	function updateHttpHeaders()
	{
		delete httpHeaders.Authorization;
		if (!accessToken) return;

		httpHeaders.Authorization = 'Bearer ' + accessToken;
	}

	// Process anon Glympse login
	// FIXME: Centralize with providers/V2
	function processLogin(data, callback)
	{
		try
		{
			//console.log('** Group login **: Response=' + ((data === null) ? 'null' : data.result));
			if (data && data.result === 'ok')
			{
				retryAttempts = 0;
				accessToken = data.response.access_token;
				updateHttpHeaders();
				g.authService.saveAuthToken(accessToken);
				requestGroup(callback);

				return;
			}
		}
		catch (e)
		{
			lib.logException(e, arguments, 'grpProcessLogin');
		}

		if (retryAttempts < maxRetryAttempts)
		{
			//console.log('retry...');
			accessToken = null;
			updateHttpHeaders();
			retryAttempts++;

			rafTimeout(function()
			{
				requestGroup(callback);
			}, lib.computeBackoff(retryAttempts));

			return;
		}

		//console.log('RequestGroup: error getting access_token : result=' + data.result);
		callback(that, false);
	}

	// Parse returned initial Glympse Public Group results
	function processGroupInitial(data, callback)
	{
		inviteGroup = [];
		isLoaded = true; // We're done, even if there is an error
		try
		{
			//console.log('processGroupInitial: got data: ' + data + ' -- ' + data.result);
			if (hasErrors(data, null))
			{
				// Handle empty/unknown group error
				if ((data && data.result === 'failure' && data.meta && data.meta.error === 'group')
				|| retryAttempts >= maxRetryAttempts
				   )
				{
					//console.log('No members in group');
					callback(that, true);
				}
				else
				{
					retryAttempts++;
					//console.log('*** FAIL');
					isLoaded = false;			// We'll retry due to unexpected error
					callback(that, false);
				}
				return;
			}

			parseGroupListResponse(data);
			initialRequest = false;
		}
		catch (e)
		{
			lib.logException(e, arguments, 'grpProcessInitial');
		}

		//console.log('PublicGroup: Invites for #' + idGroup + ' (' + inviteGroup.length + ' total) -- users=' + users.length);
		//console.log('zzzzthis=' + that + ' -- ' + that.getInviteGroup().length + ' -- ' + (that.getInviteGroup() === inviteGroup) + ' -- ' + callback);
		if (callback)
		{
			callback(that, true);
		}
	};

	// Parse returned subsequent Glympse Public Group event results
	function processGroupUpdate(data, callback)
	{
		inviteGroup = [];
		inviteRemove = [];
		isLoaded = true; // We're done, even if there is an error

		try
		{
			//console.log('processGroupUpdate: got data: ' + data + ' -- ' + data.result);
			if (hasErrors(data, callback))
			{
				return;
			}

			var o = data.response;

			if (o.type === 'group')
			{
				parseGroupListResponse(data);
			}
			else //if (o.type === 'events')
			{
				next = (o.events) ? (o.events + 1) : 0; // o.next;
				lastUpdate = data.meta.time;
				//console.log('EVENTS next = ' + next);

				// Handle new invites from new/pre-existing clients, and people leaving the group
				if (o.items)
				{
					var user;
					for (var i = o.items.length - 1; i >= 0; i--)
					{
						var item = o.items[i];
						//console.log('UPDATE type=' + item.type);
						if (item.type === 'invite' || item.type === 'swap')
						{
							var inv = item.invite;

							if (item.type === 'swap')
							{
								console.log('GOT A SWAP -- member=' + item.member + ' -- invite=' + item.invite);
							}

							// Queue the new invite code for retrieval
							dbgGroup('ADD', inv);
							inviteGroup.push(inv);

							// See if we already have the user of the new 'invite'
							user = findUser(item.member);
							//console.log('[INVITE]member=' + item.member + '(found=' + (user != null) + ') oldInvite=' + (user && user.invite) + ', newInvite=' + inv);
							if (user)
							{
								// If so, queue to remove the old invite and replace with the new one
								dbgGroup('REMOVE', user.invite);
								inviteRemove.push(user.invite);
								user.invite = inv;
							}
							else
							{
								// Otherwise, they are a new user to track
								users.push({ id: item.member, invite: inv });
							}
						}
						else if (item.type === 'leave')
						{
							//console.log('[LEAVE]member=' + item.member + ' -- found=' + (user != null));
							// If user is leaving, remove their invite and user list entry
							user = findUser(item.member);
							if (user)
							{
								dbgGroup('REMOVE', user.invite);
								inviteRemove.push(user.invite);
								users.splice(users.indexOf(user), 1);
							}
						}
					}
				}
			}
		}
		catch (e)
		{
			lib.logException(e, arguments, 'grpProcessUpdate');
		}

		//console.log('PublicGroup: Invites for #' + idGroup + ' (' + inviteGroup.length + ' total), to remove: ' + inviteRemove.length);

		if (callback)
		{
			callback(that, true);
		}
	};


	///////////////////////////////////////////////////////////////////////////////////
	// INTERNAL
	///////////////////////////////////////////////////////////////////////////////////

	function dbgGroup(status, invite)
	{
		if (!cfg.dbgGroup)
		{
			return;
		}

		console.log(idGroup.toString().toLowerCase() + ': ' + status + ' - ' + invite);
	}

	function parseGroupListResponse(data)
	{
		var i, o = data.response;
		next = o.events + 1;
		lastUpdate = data.meta.time;

		// Handle inline viewing configuration
		if (!cfg.extCfg && o.branding)
		{
			cfgInvite.setProfile(lib.parseCfg(cfg, o.branding, cfgInvite.profile));
			that.color = lib.colorCss(cfgInvite.profile.color);
			that.cfgInvite.setProfile(cfgInvite.profile);
		}
		else
		{
			// If we don't have a custom theme, lock to the default so any subsequent
			// invite themes don't accidentally override
			cfg.initialized = true;
		}

		if (o.members)
		{
			var mbrs = o.members;
			var len = mbrs.length;
			//console.log('members:' + JSON.stringify(mbrs, null, '    '));
			// Sync up existing user list with their current invites
			for (i = users.length - 1; i >= 0; i--)
			{
				var u = users[i];
				for (var j = len - 1; j >= 0; j--)
				{
					var m = mbrs[j];

					if (u.id === m.id)
					{
						// Check if we have a new invite code for an existing user
						if (u.invite != m.invite)
						{
							dbgGroup('ADD', m.invite);
							inviteGroup.push(m.invite);
							dbgGroup('REMOVE', u.invite);
							inviteRemove.push(u.invite);
						}

						// User still exists in the current list, so don't remove
						u = null;
						break;
					}
				}

				// If not in the new list, remove the user
				if (u)
				{
					dbgGroup('REMOVE', u.invite);
					inviteRemove.push(u.invite);
					users.splice(i, 1);
				}
			}

			// Locate and add any new users
			for (i = len - 1; i >= 0; i--)
			{
				var cli = mbrs[i];

				// Don't add a new user if they already exist in the current user list
				if (findUser(cli.id))
				{
					continue;
				}

				if (cli.invite)
				{
					//console.log('id=' + cli.id + ', invite=' + cli.invite);
					users.push(cli);
					dbgGroup('ADD', cli.invite);
					inviteGroup.push(cli.invite);
				}
			}
		}
	}

	function findUser(id)
	{
		for (var j = users.length - 1; j >= 0; j--)
		{
			var user = users[j];

			if (user.id === id)
			{
				return user;
			}
		}

		return null;
	}

	function hasErrors(data, callback)
	{
		if (!data || data.result != 'ok')
		{
			var m = (data && data.meta);
			var isRealError = true;
			if (retryAttempts < 3 && (m && (m.error === 'oauth_token' || m.error === 'access_denied')))
			{
				if (cfg.authToken)
				{
					var t = new Date().getTime();
					retryAttempts = maxRetryAttempts; // If auth_token error, do not fallback to anon and stop trying

					if ((t - authTokenErrT) > 10000)
					{
						console.log('>> AT error -- invalid passed AT: \'' + cfg.authToken + '\' ... stopping any additional requests');

						authTokenErrT = t;

						var cel = cfg.containerElement;

						if (cel)
						{
							var cfgEvent = { detail: { id: cfgInvite.id, owner: null, ref: null, data: data } };

							cel.dispatchEvent(new CustomEvent(g.events.OAUTH_TOKEN_ERROR, cfgEvent));
						}
					}
				}
				else
				{
					if ((m.error_detail || '').indexOf('expired') > 0)
					{
						console.log('PGHE:AT.exchange');
						login.anon_exchange = accessToken;
					}
					else
					{
						console.log('PGHE:AT.retrying');
					}

					accessToken = null;
					updateHttpHeaders();
				}

				retryAttempts++;

				if (callback)
				{
					rafTimeout(function()
					{
						requestGroup(callback);
					}, lib.computeBackoff(retryAttempts));
				}

				return isRealError;
			}

			// Group errors usually mean empty group, so reset the next pointer
			if (m && m.error)// === 'invalid_argument22')
			{
				console.log('group error -- resetting Next');
				next = 0;
				retryAttempts = 0;
				isRealError = false;

				// Remove all users as well
				for (var i = users.length - 1; i >= 0; i--)
				{
					dbgGroup('REMOVE', users[i].invite);
					inviteRemove.push(users[i].invite);
				}

				users = [];
			}

			// FIXME: Need to notify we had an error somewhere...
			console.log('PGHE:r=' + (data && data.result)
						  + ',e=' + (m && m.error)
						  + ',d=' + (m && m.error_detail)
						  + ',isRealError=' + isRealError);

			if (callback)
			{
				callback(this, false);
			}

			return isRealError;
		}

		return false;
	}
};
