///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
// Local viewing user state (i.e. preferences)
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

(function(g)
{
	var lib = g.lib;

	function viewerUser()
	{
		this.init = function()
		{
		};

		this.getVal = function(key)
		{
			return lib.getConfigValue(key);
		};

		this.setVal = function(key, val)
		{
			return lib.setConfigValue(key, val);
		};
	}

	g.models.ViewerUser = viewerUser;

})(glympse);
