/**
 * Twitter -> #event (hashtag posts) -> invite lookup
 * @author Glympse Inc., (c) 2012
 */
glympse.models.net.TwitterGroup = function (cfg, cfgInvite)
{
	var idGroup = cfgInvite.id;

	var isLoaded = false;
	var removeList = [];
	var inviteGroup = [];
	var tweetGroup = [];
	var currInvites = [];

	var that = this;

	var nextSince = 0;
	var queryUrl = null;


	///////////////////////////////////////////////////////////////////////////////////
	// PROPERTIES
	///////////////////////////////////////////////////////////////////////////////////

	this.cfgInvite = cfgInvite;

	this.loaded = function () { return isLoaded; };
	this.getTwitterGroupId = function () { return idGroup; };
	this.getTicketGroup = function () { return inviteGroup; };
	this.getTweetGroup = function () { return tweetGroup; };
	this.getRemoveList = function () { return removeList; };


	///////////////////////////////////////////////////////////////////////////////////
	// PUBLICS
	///////////////////////////////////////////////////////////////////////////////////

	// Remove old search results
	this.reset = function ()
	{
		isLoaded = false;
		inviteGroup = [];
		tweetGroup = [];
		removeList = [];
	};

	// Initiate the search
	this.load = function (callback)
	{
		// This API is on the Glympse servers, which proxies our call to Twitter due to
		// new OAuth 1.0a requirements for any call to the Twitter service. We just take
		// whatever results it gives us and come back later.
		if (!queryUrl)
		{
			queryUrl = cfg.sharePath + 'svc/twtSearch/';
			//console.log('url=' + queryUrl + ', topic=' + idGroup);
		}

		var query = queryUrl + idGroup + "?since=" + nextSince;
		//console.log("Twit query URL:" + query);
		$.getJSON(query)
			.done(function (data)
				{
					parseGroupResults(data, callback);
				})
			.fail(function (xOptions, status)
				{
					console.log("TGroup Err:" + idGroup + ", status=" + status);
					parseGroupResults(null, callback);
				});
	};

	this.toString = function ()
	{
		return ("TwitterGroup: cfgInvite=" + this.cfgInvite
				+ ", id=" + idGroup
				+ ", invites=" + inviteGroup + " (cnt=" + inviteGroup.length + ")"
				+ ", tws=" + tweetGroup + " (cnt=" + tweetGroup.length + ")"
				);
	};


	///////////////////////////////////////////////////////////////////////////////////
	// CALLBACKS
	///////////////////////////////////////////////////////////////////////////////////

	// Parse returned Twitter group (hashtag) results
	function parseGroupResults(data, callback)
	{
		isLoaded = true;

		if (data && data.statuses)
		{
			nextSince = data.nextSince;

			//console.log("nextSince:" + nextSince + ", statuses:" + data.statuses.length + ", qs:" + data.queries + ", remaining:" + data.remaining);
			//for (var n in data) console.log("data[" + n + "]:" + data[n]);

			var len = data.statuses.length;
			for (var i = 0; i < len; i++)
			{
				var status = data.statuses[i];
				//console.log("name:" + status.id + ", invite=" + status.tkt + ", age=" + ((new Date().getTime() - status.created) / (1000 * 60 * 60)).toFixed(3) + " hrs, msg=" + status.msg);
				var imgProfileHttps = status.avatarHttps;
				var twt = { id: status.id
							, name: status.name
							, nameAlt: status.nameAlt
							, created: status.created
							, msg: status.msg
							, lat: status.lat
							, lng: status.lng
							, tkt: status.tkt
							, avatar: imgProfileHttps
							, avatarFull: ((imgProfileHttps) ? imgProfileHttps.split('_normal').join('') : null)
							};

				if (twt.tkt)
				{
					// Remove any old Glympses for a user if they have sent a new Glympse
					for (var j = currInvites.length - 1; j >= 0; j--)
					{
						var curr = currInvites[j];
						if (twt.id == curr.id)
						{
							removeList.push(curr);
							currInvites.splice(j, 1);
							break;
						}
					}

					currInvites.push(twt);
					inviteGroup.push(twt);
				}
				else
				{
					tweetGroup.push(twt);
				}
			}
		}

		// FIXME: need to look for newer updates to replace current info

		if (callback)
		{
			callback(that);
		}
	};
};
