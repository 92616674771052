glympse.Loc.Langs["zh_hant_tw"] =
glympse.Loc.Langs["zh_tw"] =
glympse.Loc.Langs["zh-tw"] =
{
    locale: ["zh_hant_tw","zh_tw","zh-tw"],
    generated: "2014-09-04T21:11:36.2486983Z",
    strings: {
        M_ETA: "距離預計抵達時間 ",
        M_DESTINATION: "目的地",
        M_EXPIRED: "已到期",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "群組",
        M_JOIN_GROUP: "加入群組",
        M_KMH: "公里\/小時",
        M_MPH: "英里\/小時",
        M_OFF: "關",
        M_ON: "開",
        M_SETTINGS: "設定",
        M_UNITS: "單位",
        M_CLOSE: "關閉",
        M_VIEW_MAP: "檢視地圖",
        M_SEND_GLYMPSE: "發送 Glympse",
        M_VIEW : "檢視",
        M_MAP: "地圖",
        M_AGO: "前",
        M_LAST_UPDATED: "已更新",
        M_SPEED: "速度",
        M_EXPIRES_IN: "距離到期時間",
        M_ARRIVES_IN: "距離抵達時間",
        M_DAY: "天",
        M_DAYS: "天",
        M_HOUR: "小時",
        M_HOURS: "小時",
        M_LOADING: "正在載入，請稍候...",
        M_MINUTE: "分",
        M_MINUTES: "分",
        M_NEVER: "永不",
        M_SECOND: "秒",
        M_SECONDS: "秒",
        M_REMAINING: "剩下",
        M_EXPIRED_GLYMPSE: "來自%1$s  的 Glympse 已在 %2$s 前到期",
        M_EXPIRED_GLYMPSE_BTN: "檢視上次更新",
        M_INVALID_GLYMPSE: "Glympse %1$s 已無法使用",
        M_NO_LOCATION: "正在取得 %1$s 的位置，請稍候...",
        M_NO_MEMBERS: "目前沒有人正在群組 %1$s 等待 Glympse... ",
        M_NO_USERS: "沒有任何分享主題 %1$s 的人正在等待位置...",
        M_WAITING_TWITTER: "正在等待 %1$s 分享位置",
        M_REQUEST_GLYMPSE: "%1$s 請求分享你的位置",
        M_DOWNLOAD_APP: "取得應用程式",
        M_LAUNCH_APP: "在應用程式裡查看",
        M_GET_ADDR: "正在取得地址",
        M_SHARE_LOC: "分享你的位置！",
        M_STREET: "街道",
        M_SATELLITE: "衛星",
        M_TRAFFIC: "交通",
        M_YOUR_LOCATION: "你的位置",
        M_DEALS: "優惠活動",
        M_WEATHER: "天氣",
        M_HELP: "說明",
        M_FEEDBACK: "意見回饋",
        M_DEBUG: "除錯",
        M_SUBMIT_A_BUG: "回報錯誤",
        M_SEND_MESSAGE: "傳送訊息",
        M_TAG: "標籤",
        M_ALTITUDE: "海拔",
        M_METERS: "公尺",
        M_FEET: "英尺",
        messages: [
            "快到了！",
            "瞧瞧這個！",
            "在路上！",
            "回家了！",
            "這是今天晚上我待的地方！",
            "我在這兒，你呢？",
            "嘿，瞧瞧我在哪裡！",
            "我會在那裡。",
            "我快到了！",
            "我愛上 Glympse 了！",
            "我忘了沙拉，但有帶蒜味香腸。",
            "我想我迷路了。",
            "就在附近…",
            "剛到。",
            "正要離開",
            "在這裡碰面吧。",
            "午餐時在這裡跟我碰面。",
            "等我到那裡時，跟我碰面。",
            "我在這裡，有需要任何東西嗎？",
            "快遲到了 :(",
            "等會兒見。",
            "塞車中。",
            "該你了！",
            "要吃點什麼嗎？",
            "看我的。"
        ],
        M_NEVER_LOCATION: "無法提供 %1$s 的位置",
        M_TERRAIN: "地形圖",
        M_DOWNLOAD_ACTIVE: "...以分享您的位置",
        M_DOWNLOAD_INACTIVE: "...以要求新的 Glympse",
        M_DOWNLOAD_APP_ALT: "取得 Glympse",
        M_HERE_DL_MOBILE: "取得 Glympse",
        M_HERE_DL_FULL: "取得 Glympse",
        M_NO_NAME: "一位朋友"
    }
};
