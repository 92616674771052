glympse.lib.Timer = function ()
{
	// state
	var started = false;
	var subscribers = [];
	var timer = null;

	///////////////////////////////////////////////////////////////////////////////////
	// PUBLICS
	///////////////////////////////////////////////////////////////////////////////////

	this.subscribe = function (subscriber)
	{
		if (subscribers.indexOf(subscriber) < 0)
		{
			subscribers.push(subscriber);
			if (!started)
			{
				started = true;
				schedule();
			}
		}
	};

	this.unsubscribe = function (sub)
	{
		var index = subscribers.indexOf(sub);
		if (index >= 0)
		{
			subscribers.splice(index, 1);
			if (subscribers.length === 0)
			{
				started = false;
				clearRafTimeout(timer);
			}
		}
	};


	///////////////////////////////////////////////////////////////////////////////////
	// INTERNAL
	///////////////////////////////////////////////////////////////////////////////////

	function schedule()
	{
		timer = rafTimeout(localTimerTick, 1000);
	}

	function localTimerTick()
	{
		if (started)
		{
			for (var i = subscribers.length - 1; i >= 0; i--)
			{
				subscribers[i].tick();
			}

			schedule();
		}
	}
};

