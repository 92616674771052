glympse.Loc.Langs["vi"] =
glympse.Loc.Langs["vi_vi"] =
{
    locale: ["vi","vi_vi"],
    generated: "2014-09-04T21:11:36.1706973Z",
    strings: {
        M_ETA: "Dự kiến thời gian đến",
        M_DESTINATION: "Điểm đến",
        M_EXPIRED: "Đã hết hạn",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Nhóm",
        M_JOIN_GROUP: "Tham gia Nhóm",
        M_KMH: "km\/giờ",
        M_MPH: "Dặm\/giờ",
        M_OFF: "TẮT",
        M_ON: "BẬT",
        M_SETTINGS: "Cài đặt",
        M_UNITS: "Đơn vị",
        M_CLOSE: "Đóng",
        M_VIEW_MAP: "Xem Bản đồ",
        M_SEND_GLYMPSE: "Gửi Glympse",
        M_VIEW : "Xem",
        M_MAP: "Bản đồ",
        M_AGO: "trước",
        M_LAST_UPDATED: "Đã cập nhật",
        M_SPEED: "Tốc độ",
        M_EXPIRES_IN: "Hết hạn trong",
        M_ARRIVES_IN: "Đến trong",
        M_DAY: "ngày",
        M_DAYS: "các ngày",
        M_HOUR: "giờ",
        M_HOURS: "giờ",
        M_LOADING: "Đang tải, vui lòng chờ...",
        M_MINUTE: "phút",
        M_MINUTES: "phút",
        M_NEVER: "không bao giờ",
        M_SECOND: "giây",
        M_SECONDS: "giây",
        M_REMAINING: "còn lại",
        M_EXPIRED_GLYMPSE: "Glympse từ %1$s đã hết hạn %2$s trước",
        M_EXPIRED_GLYMPSE_BTN: "Xem Cập nhật Lần cuối",
        M_INVALID_GLYMPSE: "Glympse %1$s không còn tồn tại",
        M_NO_LOCATION: "Đang lấy vị trí trong %1$s Vui lòng chờ...",
        M_NO_MEMBERS: "Hiện không có ai ở trong nhóm %1$s Chờ các Glympse...",
        M_NO_USERS: "Không có ai chia sẻ trong chủ đề %1$s Chờ các vị trí...",
        M_WAITING_TWITTER: "Chờ %1$s chia sẻ vị trí của họ",
        M_REQUEST_GLYMPSE: "Yêu cầu từ %1$s chia sẻ vị trí của bạn",
        M_DOWNLOAD_APP: "Nhận Ứng dụng",
        M_LAUNCH_APP: "Xem trong Ứng dụng",
        M_GET_ADDR: "Truy lại địa chỉ",
        M_SHARE_LOC: "Chia sẻ vị trí của bạn!",
        M_STREET: "Đường",
        M_SATELLITE: "Vệ tinh",
        M_TRAFFIC: "Giao thông",
        M_YOUR_LOCATION: "Vị trí của bạn",
        M_DEALS: "Giao dịch",
        M_WEATHER: "Thời tiết",
        M_HELP: "Trợ giúp",
        M_FEEDBACK: "Thông tin phản hồi",
        M_DEBUG: "Sửa lỗi",
        M_SUBMIT_A_BUG: "Gửi một lỗi",
        M_SEND_MESSAGE: "Gửi tin nhắn",
        M_TAG: "Gắn thẻ",
        M_ALTITUDE: "Độ cao",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Sắp đến rồi!",
            "Xem này!",
            "Đang trên đường!",
            "Về nhà thôi!",
            "Đây là nơi tôi sẽ đi chơi tối nay!",
            "Mình tới đây, bạn ở đâu?",
            "Này, nhìn xem tôi đang ở đâu!",
            " Tôi sẽ đến ngay.",
            "Tôi gần đến nơi rồi!",
            "Tôi đang yêu Glympse!",
            "Tôi đã quên mất món salad rồi, nhưng tôi đang mang xúc xích.",
            "Tôi nghĩ tôi lạc mất rồi.",
            " Ở vùng lân cận...",
            "Vừa đặt phòng.",
            "Vừa rời đi.",
            "Chúng ta hãy gặp nhau ở đây.",
            "Gặp tôi ở đây để ăn trưa nhé.",
            "Gặp tôi khi tôi đến đ.",
            "Cần bất cứ thứ gì trong khi tôi đang ở đây?",
            "Đến trễ :(",
            "Sẽ gặp bạn trong giây lát.",
            "Bị tắc đường.",
            "Gắn thẻ, bắt kịp rồi!",
            "Muốn ăn gì không?",
            "Theo dõi tôi."
        ],
        M_NEVER_LOCATION: "Vị trí cho %1$s không tồn tại",
        M_TERRAIN: "Địa hình",
        M_DOWNLOAD_ACTIVE: "...để chia sẻ vị trí của bạn",
        M_DOWNLOAD_INACTIVE: "...để yêu cầu một Glympse mới",
        M_DOWNLOAD_APP_ALT: "Nhận Glympse",
        M_HERE_DL_MOBILE: "Nhận Glympse",
        M_HERE_DL_FULL: "Nhận Glympse",
        M_NO_NAME: "Một người bạn"
    }
};
