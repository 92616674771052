(function (g)
{
	var lib = g.lib;

	lib.TimeTracker = function (category, variable, optLabel, optSampleRate)
	{
		// state
		var createTime;
		var endTime = 0;
		var delta = 0;
		var label;

		// -------> Using global sample rate settings if not individually overwritten in glympse.lib.initTracking()
		//
		// Lock to a defined sample rate for now
		// ---> Global time tracking: _gaq.push([‘_setSiteSpeedSampleRate’, 50]);
		// ---> https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration#_gat.GA_Tracker_._setSiteSpeedSampleRate
		//if (!optSampleRate)
		//{
		//	optSampleRate = 100;
		//}


		///////////////////////////////////////////////////////////////////////////////////
		// PROPERTIES
		///////////////////////////////////////////////////////////////////////////////////

		this.getDelta = function () { return delta; };
		this.getStartTime = function () { return createTime; };

		this.setLabel = function (newLabel) { label = (newLabel) ? newLabel : undefined; };


		///////////////////////////////////////////////////////////////////////////////////
		// PUBLICS
		///////////////////////////////////////////////////////////////////////////////////

		this.start = function (newTime)
		{
			createTime = (newTime) ? newTime : new Date().getTime();
			return this;
		};

		this.end = function ()
		{
			endTime = new Date().getTime();
			return this;
		};

		this.submit = function (ignoreLongDelta)
		{
			var stat = "[" + category + "][" + variable + "]" + ((optLabel) ? ("[" + optLabel + "]") : "");

			delta = ((endTime) ? endTime : new Date().getTime()) - createTime;
			if (delta > 0 && (ignoreLongDelta || delta < 60 * 60 * 1000))
			{
				console.log(stat + ": " + delta + " -- " + optSampleRate);

				//var timing = ["_trackTiming", category, variable, delta, label];
				//var timing = ["_trackTiming", category, variable, delta, label];
				//if (typeof optSampleRate !== "undefined")
				//{
				//	timing.push(optSampleRate);
				//}

				//_gaq.push(timing);
				lib.trackValue("Timing", category + "_" + variable, label, delta);
			}
			else
			{
				console.log("[TTerr]: delta=" + delta + " -- " + stat);
			}

			return this;
		};


		///////////////////////////////////////////////////////////////////////////////////
		// CTOR
		///////////////////////////////////////////////////////////////////////////////////

		this.setLabel(optLabel);
		this.start();
	};

})(glympse);
