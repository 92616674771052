glympse.Loc.Langs["ar"] =
glympse.Loc.Langs["ar_eg"] =
{
    locale: ["ar","ar_eg"],
    generated: "2014-09-04T21:11:35.3594869Z",
    strings: {
        M_ETA: "وقت الوصول المقدر",
        M_DESTINATION: "الوجهة",
        M_EXPIRED: "منتهية الصلاحية",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "مجموعة",
        M_JOIN_GROUP: "انضم إلى المجموعة",
        M_KMH: "كم\/ساعة",
        M_MPH: "ميل في الساعة",
        M_OFF: "إيقاف",
        M_ON: "تشغيل",
        M_SETTINGS: "الإعدادات",
        M_UNITS: "وحدة",
        M_CLOSE: "إغلاق",
        M_VIEW_MAP: "عرض الخريطة",
        M_SEND_GLYMPSE: "أرسل إلي Glympse",
        M_VIEW : "عرض",
        M_MAP: "الخريطة",
        M_AGO: "في الماضي",
        M_LAST_UPDATED: "محدّثة",
        M_SPEED: "السرعة",
        M_EXPIRES_IN: "تنتهي في",
        M_ARRIVES_IN: "تصل إلى",
        M_DAY: "يوم",
        M_DAYS: "أيام",
        M_HOUR: "ساعة",
        M_HOURS: "ساعات",
        M_LOADING: "جاري التحميل، يرى الانتظار ...",
        M_MINUTE: "دقيقة",
        M_MINUTES: "دقيقة",
        M_NEVER: "أبدًا",
        M_SECOND: "ثانية",
        M_SECONDS: "ثانية",
        M_REMAINING: "متبقية",
        M_EXPIRED_GLYMPSE: "Glympse  الذي بدأ من %1$s انتهت صلاحيته منذ%2$s",
        M_EXPIRED_GLYMPSE_BTN: "عرض آخر تحديث",
        M_INVALID_GLYMPSE: "Glympse %1$s  لم يعد متاحًا",
        M_NO_LOCATION: "الوصول إلي مكان%1$s  الرجاء الانتظار ...",
        M_NO_MEMBERS: "لا أحد حاليًا في مجموعة %1$s في انتظار أعضاءGlympse  ...",
        M_NO_USERS: "لم يقوم أحد بمشاركة موضوع %1$s في انتظار المواقع ",
        M_WAITING_TWITTER: "في انتظار%1$s  لمشاركة مواقعهم",
        M_REQUEST_GLYMPSE: "طلب من %1$s  لمشاركة موقعك",
        M_DOWNLOAD_APP: "احصل على التطبيق",
        M_LAUNCH_APP: "عرضه في التطبيق",
        M_GET_ADDR: "استرجاع العنوان",
        M_SHARE_LOC: "مشاركة موقعك!",
        M_STREET: "الشارع",
        M_SATELLITE: "القمر الصناعي",
        M_TRAFFIC: "حركة المرور",
        M_YOUR_LOCATION: "موقعك",
        M_DEALS: "العروض",
        M_WEATHER: "الطقس",
        M_HELP: "مساعدة",
        M_FEEDBACK: "تعليقات",
        M_DEBUG: "تصحيح الأخطاء",
        M_SUBMIT_A_BUG: "الإبلاغ عن خلل",
        M_SEND_MESSAGE: "أرسل رسالة",
        M_TAG: "علامة",
        M_ALTITUDE: "الارتفاع",
        M_METERS: "م",
        M_FEET: "قدم",
        messages: [
            "على وشك الانتهاء!",
            "ألقى نظرة",
            "في الطريق!",
            "متجه إلى البيت الآن!",
            "هذا هو المكان الذي سأتنزه فيه الليلة!",
            "لقد وصلت، أين أنت؟",
            "مهلًا، أنظر أين أنا!",
            "سأكون هناك.",
            "لقد وصلت تقريبًا!",
            "لقد أحببت Glympse!",
            "لقد نسيت السلطة، ولكن أحضرت السلامي.",
            "أعتقد أنني ضللت الطريق.",
            "في الحي...",
            "لقد دخلت للتو.",
            "سأغادر الآن.",
            "دعنا نتقابل هنا.",
            "قابلني هنا لنتناول الغداء.",
            "سأراك عند وصولي إلى هناك.",
            "هل ستحتاج إلى أي شيء أثناء وجودي هنا؟",
            "تأخرت:(",
            "أراك بعد قليل.",
            "عالقًا في المرور",
            "علامة، ظهرت فيها!",
            "هل ترغب في تناول شيء ما؟",
            "شاهدني"
        ],
        M_NEVER_LOCATION: "موقع %1$s  غير متوفر",
        M_TERRAIN: "تضاريس",
        M_DOWNLOAD_ACTIVE: "... لمشاركة موقعك",
        M_DOWNLOAD_INACTIVE: "... لطلب \"Glympse\" جديد",
		M_DOWNLOAD_APP_ALT: "احصل على تطبيق Glympse",
		M_HERE_DL_MOBILE: "احصل على تطبيق Glympse",
		M_HERE_DL_FULL: "احصل على تطبيق Glympse",
        M_NO_NAME: "أحد الأصدقاء"
    }
};
