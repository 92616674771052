glympse.Area["default"] =
{
	"centerLat": 47.6190075
	, "centerLng": -122.2665745
	, "defaultZoom": 14
	, "invalidZoom": 4
	, "invalidLat": 43.99296887
	, "invalidLng": -102.24173069
	, "allowMarkers": true
	, "allowDestinationNames": true
	//, "bounds": [{ "lat": 0, "lng": 0 }, { "lat": 0, "lng": 0}]
	, "borders": []
	, "markers": []
	, "destinations":
		[
			{ "lat": 47.620701, "lng": -122.349304, "name": "Space Needle" }
			, { "lat": 47.621677, "lng": -122.334663, "name": "Mokas Cafe and Coffee Bar" }
			, { "lat": 47.615616, "lng": -122.34462, "name": "Two Bells Tavern" }
			, { "lat": 47.650299, "lng": -122.301651, "name": "Husky Stadium" }
			, { "lat": 47.591434, "lng": -122.332481, "name": "Safeco Field" }
			, { "lat": 47.536674, "lng": -122.303925, "name": "Boeing Field-King County International Airport" }
			, { "lat": 47.592335, "lng": -122.225647, "name": "Luther Burbank Park" }
			, { "lat": 47.527924, "lng": -122.223198, "name": "Mercer Island Beach Club" }
			, { "lat": 47.606075, "lng": -122.301285, "name": "Garfield High School" }
			, { "lat": 47.6371, "lng": -122.295639, "name": "Washington Park Arboretum" }
			, { "lat": 47.669144, "lng": -122.350616, "name": "Woodland Park Zoo" }
			, { "lat": 47.665112, "lng": -122.397369, "name": "Ballard Locks" }
			, { "lat": 47.629906, "lng": -122.13578, "name": "K1 Speedway" }
			, { "lat": 47.535862, "lng": -122.197838, "name": "Seahawks Training Center" }
			, { "lat": 47.710091, "lng": -122.170753, "name": "Himitsu Teriyaki" }
			, { "lat": 47.644226, "lng": -122.377914, "name": "Interbay Golf Center" }
		]
};