glympse.Loc.Langs["el"] =
glympse.Loc.Langs["el_gr"] =
{
    locale: ["el","el_gr"],
    generated: "2014-09-04T21:11:34.2830731Z",
    strings: {
        M_ETA: "εχα",
        M_DESTINATION: "Προορισμός",
        M_EXPIRED: "Έληξε",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Ομάδα",
        M_JOIN_GROUP: "Γίνε Μέλος της Ομάδας",
        M_KMH: "χλμ\/ώρα",
        M_MPH: "μίλια\/ώρα",
        M_OFF: "ΑΠΕΝΕΡΓΟΠΟΙΗΜΕΝΟ",
        M_ON: "ΕΝΕΡΓΟΠΟΙΗΜΕΝΟ",
        M_SETTINGS: "Ρυθμίσεις",
        M_UNITS: "Μονάδες Μέτρησης",
        M_CLOSE: "Κλείσιμο",
        M_VIEW_MAP: "Προβολή Χάρτη",
        M_SEND_GLYMPSE: "Αποστολή Glympse",
        M_VIEW : "Προβολή",
        M_MAP: "Χάρτης",
        M_AGO: "πριν",
        M_LAST_UPDATED: "Ενημερώθηκε",
        M_SPEED: "Ταχύτητα",
        M_EXPIRES_IN: "Λήγει σε",
        M_ARRIVES_IN: "Φτάνει σε",
        M_DAY: "ημέρα",
        M_DAYS: "ημέρες",
        M_HOUR: "ώρα",
        M_HOURS: "ώρες",
        M_LOADING: "Φορτώνει, παρακαλούμε περίμενε...",
        M_MINUTE: "λεπτό",
        M_MINUTES: "λεπτά",
        M_NEVER: "ποτέ",
        M_SECOND: "δ\/λεπτο",
        M_SECONDS: "δ\/λεπτα",
        M_REMAINING: "απομένουν",
        M_EXPIRED_GLYMPSE: "Το Glympse του %1$s έληξε πριν %2$s",
        M_EXPIRED_GLYMPSE_BTN: "Δες την Τελευταία Ενημέρωση",
        M_INVALID_GLYMPSE: "Το Glympse %1$s δεν είναι πλέον διαθέσιμο",
        M_NO_LOCATION: "Λήψη τοποθεσίας για %1$s Παρακαλούμε, περίμενε...",
        M_NO_MEMBERS: "Αυτή τη στιγμή, δεν υπάρχει κανείς στην ομάδα %1$s Αναμονή για Glympses...",
        M_NO_USERS: "Κανείς δεν κοινοποίησε στο θέμα %1$s Αναμονή για τοποθεσίες...",
        M_WAITING_TWITTER: "Αναμονή για κοινοποίηση τοποθεσίας από %1$s ",
        M_REQUEST_GLYMPSE: "Ζήτησε από τον χρήστη %1$s να κοινοποιήσει την τοποθεσία σου",
        M_DOWNLOAD_APP: "Λήψη Εφαρμογής",
        M_LAUNCH_APP: "Προβολή εφαρμογής",
        M_GET_ADDR: "Ανάκτηση διεύθυνσης",
        M_SHARE_LOC: "Κοινοποίησε την τοποθεσία σου!",
        M_STREET: "Οδός",
        M_SATELLITE: "Δορυφόρος",
        M_TRAFFIC: "Κίνηση",
        M_YOUR_LOCATION: "Η τοποθεσία σου",
        M_DEALS: "Προσφορές",
        M_WEATHER: "Καιρός",
        M_HELP: "Βοήθεια",
        M_FEEDBACK: "Παροχή σχολίων",
        M_DEBUG: "Εντοπισμός σφάλματος",
        M_SUBMIT_A_BUG: "Αναφορά σφάλματος",
        M_SEND_MESSAGE: "Αποστολή μηνύματος",
        M_TAG: "Ετικέτα",
        M_ALTITUDE: "Υψόμετρο",
        M_METERS: "μ",
        M_FEET: "πόδια",
        messages: [
            "Σχεδόν έφτασα!",
            "Για ρίξε μια ματιά!",
            "Καθ’ οδόν!",
            "Καθ’ οδόν για το σπίτι!",
            "Να πού βρίσκομαι απόψε!",
            "Έφτασα, πού είσαι;",
            "Γεια, δες πού είμαι!",
            "Έρχομαι αμέσως.",
            "Σχεδόν έφτασα!",
            "Λατρεύω το Glympse!",
            "Ξέχασα τη σαλάτα, αλλά θα φέρω το σαλάμι.",
            "Νομίζω πως έχω χαθεί.",
            "Είμαι στη γειτονιά...",
            "Μόλις έφτασα.",
            "Μόλις φεύγω.",
            "Ας συναντηθούμε εδώ.",
            "Ας συναντηθούμε εδώ για φαγητό.",
            "Θα τα πούμε όταν φτάσω εκεί..",
            "Θέλεις κάτι όσο είμαι εδώ;",
            "Είναι αργά  =(",
            "Τα λέμε σε λίγο.",
            "Κόλλησα στην κίνηση.",
            "Ήρθε η σειρά σου!",
            "Θέλεις να φας κάτι;",
            "Παρακολούθησέ με."
        ],
        M_NEVER_LOCATION: "Η τοποθεσία του χρήστη %1$s δεν είναι διαθέσιμη",
        M_TERRAIN: "Εδάφους",
        M_DOWNLOAD_ACTIVE: "...για την κοινοποίηση της τοποθεσίας σας",
        M_DOWNLOAD_INACTIVE: "...για την αίτηση νέας Glympse",
        M_DOWNLOAD_APP_ALT: "Λήψη Glympse",
        M_HERE_DL_MOBILE: "Λήψη Glympse",
        M_HERE_DL_FULL: "Λήψη Glympse",
        M_NO_NAME: "Ένας φίλος"
    }
};
