glympse.Loc.Langs["fr"] =
glympse.Loc.Langs["fr_fr"] =
{
    locale: ["fr","fr_fr"],
    generated: "2014-09-07T02:17:29.008483Z",
    strings: {
        M_ETA: "HPA",
        M_DESTINATION: "Destination",
        M_EXPIRED: "expiré",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Groupe",
        M_JOIN_GROUP: "Rejoindre le groupe",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "O",
        M_ON: "I",
        M_SETTINGS: "Réglages",
        M_UNITS: "Unités",
        M_CLOSE: "Fermer",
        M_VIEW_MAP: "Voir la carte",
        M_SEND_GLYMPSE: "Envoyer un Glympse",
        M_VIEW : "Voir",
        M_MAP: "Carte",
        M_AGO: "il y a",
        M_LAST_UPDATED: "Mis à jour",
        M_SPEED: "Vitesse",
        M_EXPIRES_IN: "Expire dans",
        M_ARRIVES_IN: "Arrive dans",
        M_DAY: "jour",
        M_DAYS: "jours",
        M_HOUR: "h",
        M_HOURS: "h",
        M_LOADING: "Chargement, veuillez patienter...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "jamais",
        M_SECOND: "sec",
        M_SECONDS: "sec",
        M_REMAINING: "restant",
        M_EXPIRED_GLYMPSE: "Le Glympse de %1$s a expiré il y a %2$s",
        M_EXPIRED_GLYMPSE_BTN: "Voir la dernière mise à jour",
        M_INVALID_GLYMPSE: "Le Glympse %1$s n’est plus disponible",
        M_NO_LOCATION: "Recherche de l’emplacement de %1$s Veuillez patienter...",
        M_NO_MEMBERS: "Actuellement personne ne se trouve dans le groupe %1$s Attente de Glympses...",
        M_NO_USERS: "Personne ne partage ce sujet %1$s Recherche d’emplacements...",
        M_WAITING_TWITTER: "Attente de partage d’emplacement de %1$s",
        M_REQUEST_GLYMPSE: "Demande de partage d’emplacement de %1$s",
        M_DOWNLOAD_APP: "Télécharger appli",
        M_LAUNCH_APP: "Voir dans l’appli ",
        M_GET_ADDR: "Capture de l’adresse",
        M_SHARE_LOC: "Partagez votre emplacement !",
        M_STREET: "Rue",
        M_SATELLITE: "Satellite",
        M_TRAFFIC: "Circulation",
        M_YOUR_LOCATION: "Votre emplacement",
        M_DEALS: "Affaires",
        M_WEATHER: "Météo",
        M_HELP: "Aide",
        M_FEEDBACK: "Feed-back",
        M_DEBUG: "Débuger",
        M_SUBMIT_A_BUG: "Soumettre un bug",
        M_SEND_MESSAGE: "Envoyer un message",
        M_TAG: "Tag",
        M_ALTITUDE: "Altitude",
        M_METERS: "m",
        M_FEET: "P",
        messages: [
            "J’y suis presque!",
            "Jette donc un coup d’œil à ceci!",
            "En chemin!",
            "Je rentre à la maison!",
            "Voici où je vais traîner ce soir!",
            "Je suis là, où es-tu?",
            "Eh, regarde où je suis!",
            "J’arrive!",
            "J’y suis presque!",
            "Je commence à adorer Glympse!",
            "J’ai oublié la salade, mais j’amène le salami.",
            "Je crois que je suis perdu.",
            "Dans le quartier…",
            "Je viens juste aux nouvelles.",
            "Je suis en train de partir.",
            "Retrouvons-nous ici.",
            "Rendez-vous ici pour déjeuner.",
            "Retrouve moi quand j’y serai.",
            "Tu as besoin de quelque chose, tant que je suis là?",
            "Je suis en retard :(",
            "À tout de suite.",
            "Coincé dans les bouchons.",
            "Touché, c’est toi le chat!",
            "Tu veux quelque chose à manger?",
            "Regarde moi."
        ],
        M_NEVER_LOCATION: "Localisation de %1$s non disponible",
        M_TERRAIN: "Terrain",
        M_DOWNLOAD_ACTIVE: "...pour partager votre localisation",
        M_DOWNLOAD_INACTIVE: "...pour demander un nouveau Glympse",
        M_DOWNLOAD_APP_ALT: "Recevoir Glympse",
        M_HERE_DL_MOBILE: "Recevoir Glympse",
        M_HERE_DL_FULL: "Recevoir Glympse",
        M_NO_NAME: "Un ami"
    }
};
