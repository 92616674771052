(function (g)
{
	var lln = function (initData)
	{
		// state
		this.data = initData;
	};

	lln.prototype.next = null;
	lln.prototype.prev = null;
	lln.prototype.isEqual = function (o) { return (o == this); };

	g.lib.LinkedListNode = lln;


	g.lib.LinkedList = function (maxSize)
	{
		// state
		var _maxSize = maxSize;
		var _head = null;
		var _tail = null;
		var _numElements = 0;

		///////////////////////////////////////////////////////////////////////////////
		// PROPERTIES
		///////////////////////////////////////////////////////////////////////////////
		this.getMaxSize = function () { return _maxSize; };
		this.setMaxSize = function (val) { _maxSize = val; trimToMaxSize(this); };

		this.getHead = function () { return _head; };
		this.getTail = function () { return _tail; };
		this.getLength = function () { return _numElements; };


		///////////////////////////////////////////////////////////////////////////////
		// PUBLICS
		///////////////////////////////////////////////////////////////////////////////
		this.insertAfter = function (node, after)
		{
			if (node == null || after == null)
			{
				return;
			}

			node.prev = after;
			node.next = after.next;

			if (after.next == null)
			{
				_tail = node;
			}
			else
			{
				after.next.prev = node;
			}

			after.next = node;
			_numElements++;
			trimToMaxSize(this);
		};

		this.insertBefore = function (node, before)
		{
			if (node == null || before == null)
			{
				return;
			}

			node.prev = before.prev;
			node.next = before;

			if (before.prev == null)
			{
				_head = node;
			}
			else
			{
				before.prev.next = node;
			}

			before.prev = node;
			_numElements++;
			trimToMaxSize(this);
		};

		this.insertAtBeginning = function (node)
		{
			if (node == null) return;

			if (_head == null)
			{
				_head = node;
				_tail = node;
				node.next = null;
				node.prev = null;
				_numElements++;
				trimToMaxSize(this);
			}
			else
			{
				this.insertBefore(node, _head);
			}
		};

		this.append = function (node)
		{
			if (node == null) return;
			if (_tail == null) this.insertAtBeginning(node);
			else this.insertAfter(node, _tail);
		};

		this.remove = function (node)
		{
			if (node == null) return;

			if (node.next != null) node.next.prev = node.prev;
			if (node.prev != null) node.prev.next = node.next;
			if (node.isEqual(_head)) _head = node.next;
			if (node.isEqual(_tail)) _tail = node.prev;

			//	Remove references to allow garbage collection
			node.next = null;
			node.prev = null;
			_numElements--;
		};

		this.concat = function (list)
		{
			if (list == null || list.first == null)
			{
				return;
			}

			insertAfter(list.first, _tail);
			_tail = list.last;
			_numElements += list.length;
			trimToMaxSize(this);
		};

		this.toArray = function ()
		{
			var arr = new Array();
			var node = _head;

			while (node != null)
			{
				arr.push(node);
				node = node.next;
			}

			return arr;
		};

		this.getNodeIndex = function (node)
		{
			var i = 0;
			var idx = -1;
			var currNode = _head;

			while (currNode != null)
			{
				if (node.isEqual(currNode))
				{
					idx = i;
					break;
				}

				currNode = currNode.next;
				i++;
			}

			return idx;
		};


		///////////////////////////////////////////////////////////////////////////////
		// UTILITY
		///////////////////////////////////////////////////////////////////////////////

		/**
		* Ensure this ILinkedList doesn't exceed its size limits
		*/
		function trimToMaxSize(list)
		{
			var max = list.getMaxSize();
			if (max > 0)
			{
				while (list.getLength() > max)
				{
					list.remove(list.head);
				}
			}
		}
	};

})(glympse);
