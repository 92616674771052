glympse.Loc.Langs["de"] =
glympse.Loc.Langs["de_de"] =
{
    locale: ["de","de_de"],
    generated: "2014-09-04T21:11:33.8150671Z",
    strings: {
        M_ETA: "ETA",
        M_DESTINATION: "Ziel",
        M_EXPIRED: "Abgelaufen",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Gruppe",
        M_JOIN_GROUP: "Der Gruppe beitreten",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "AUS",
        M_ON: "EIN",
        M_SETTINGS: "Einstellungen",
        M_UNITS: "Einheiten",
        M_CLOSE: "Schließen",
        M_VIEW_MAP: "Karte anzeigen",
        M_SEND_GLYMPSE: "Glympse senden",
        M_VIEW : "Anzeigen",
        M_MAP: "Karte",
        M_AGO: " ",
        M_LAST_UPDATED: "Aktualisiert vor",
        M_SPEED: "",
        M_EXPIRES_IN: "Läuft ab in",
        M_ARRIVES_IN: "Kommt an in",
        M_DAY: "Tag",
        M_DAYS: "Tage",
        M_HOUR: "Std.",
        M_HOURS: "Std.",
        M_LOADING: "Wird geladen, bitte warten...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "nie",
        M_SECOND: "sek",
        M_SECONDS: "sek",
        M_REMAINING: "übrig",
        M_EXPIRED_GLYMPSE: "Das Glympse von %1$s ist vor %2$s abgelaufen",
        M_EXPIRED_GLYMPSE_BTN: "Letztes Update ansehen",
        M_INVALID_GLYMPSE: "Das Glympse %1$s ist nicht mehr verfügbar",
        M_NO_LOCATION: "Hole Standort für %1$s Bitte warten...",
        M_NO_MEMBERS: "Derzeit ist niemand in Gruppe %1$s Warte auf Glympse...",
        M_NO_USERS: "Niemand teilt etwas zum Thema %1$s Warte auf Standorte...",
        M_WAITING_TWITTER: "Warte darauf, dass %1$s seinen\/ihren Standort teilt",
        M_REQUEST_GLYMPSE: "Anfrage von %1$s, dass du deinen Standort teilst",
        M_DOWNLOAD_APP: "App holen",
        M_LAUNCH_APP: "In App anschauen",
        M_GET_ADDR: "Frage Addresse ab",
        M_SHARE_LOC: "Teilen deinen Standort!",
        M_STREET: "Straße",
        M_SATELLITE: "Satellit",
        M_TRAFFIC: "Verkehr",
        M_YOUR_LOCATION: "Dein Standort",
        M_DEALS: "Deals",
        M_WEATHER: "Wetter",
        M_HELP: "Hilfe",
        M_FEEDBACK: "Feedback",
        M_DEBUG: "Korrigieren",
        M_SUBMIT_A_BUG: "Fehler einsenden",
        M_SEND_MESSAGE: "Mitteilung senden",
        M_TAG: "Tag",
        M_ALTITUDE: "Höhe",
        M_METERS: "m",
        M_FEET: "Fuß",
        messages: [
            "Fast da!",
            "Schau dir das an!",
            "Bin unterwegs!",
            "Jetzt auf dem Weg nach Hause!",
            "Werde heute abend hier sein!",
            "Hier bin ich, wo bist du?",
            "Hey, schau dir an, wo ich bin!",
            "Ich bin gleich da.",
            "Ich bin fast da!",
            "Ich verliebe mich in Glympse!",
            "Ich habe den Salat vergessen, aber ich bringe die Salami.",
            "Ich glaube, ich habe mich verirrt.",
            "In der Gegend...",
            "Checke gerade ein.",
            "Gehe gerade weg.",
            "Lass uns hier treffen.",
            "Treffen uns hier zum Mittagessen.",
            "Wir sehen uns, wenn ich dort bin.",
            "Wo ich gerade hier bin: brauchst du etwas?",
            "Verspäte mich :(",
            "Bis gleich.",
            "Stecke im Verkehr fest.",
            "Tagge es, das bist du!",
            "Möchtest du etwas essen?",
            "Schau mir zu."
        ],
        M_NEVER_LOCATION: "Standort für %1$s nicht verfügbar",
        M_TERRAIN: "Gebiets-Ansicht",
        M_DOWNLOAD_ACTIVE: "...um Ihren Standort zu teilen",
        M_DOWNLOAD_INACTIVE: "...um einen neuen Glympse anzufordern",
        M_DOWNLOAD_APP_ALT: "Glympse holen",
        M_HERE_DL_MOBILE: "Glympse holen",
        M_HERE_DL_FULL: "Glympse holen",
        M_NO_NAME: "Ein Freund"
    }
};
