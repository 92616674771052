glympse.Loc.Langs["it"] =
glympse.Loc.Langs["it_it"] =
{
    locale: ["it","it_it"],
    generated: "2014-09-07T02:17:28.930482Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destinazione",
        M_EXPIRED: "Scaduto",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Gruppo",
        M_JOIN_GROUP: "Unisciti al Gruppo",
        M_KMH: "km\/h",
        M_MPH: "miglia all'ora",
        M_OFF: "OFF",
        M_ON: "ON",
        M_SETTINGS: "Impostazioni",
        M_UNITS: "Unità",
        M_CLOSE: "Chiudi",
        M_VIEW_MAP: "Vedi Mappa",
        M_SEND_GLYMPSE: "Invia Glympse",
        M_VIEW : "Vedi",
        M_MAP: "Mappa",
        M_AGO: "fa",
        M_LAST_UPDATED: "Aggiornato",
        M_SPEED: "Velocità",
        M_EXPIRES_IN: "Scade in",
        M_ARRIVES_IN: "Arriva in",
        M_DAY: "giorno",
        M_DAYS: "giorni",
        M_HOUR: "h",
        M_HOURS: "h",
        M_LOADING: "Sto caricando, si prega di attendere...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "mai",
        M_SECOND: "s",
        M_SECONDS: "s",
        M_REMAINING: "rimanenti",
        M_EXPIRED_GLYMPSE: "Il Glympse da %1$s è scaduto %2$s fa",
        M_EXPIRED_GLYMPSE_BTN: "Vedi ultimo aggiornamento",
        M_INVALID_GLYMPSE: "Il Glympse %1$s non è più disponibile",
        M_NO_LOCATION: "Sto ottenendo la posizione di %1$s Si prega di attendere...",
        M_NO_MEMBERS: "Nessuno è attualmente nel gruppo %1$s Aspettiamo che arrivino dei Glympse...",
        M_NO_USERS: "Nessuno condivide sul tema %1$s In attesa di posizioni...",
        M_WAITING_TWITTER: "Sto aspettando che %1$s condivida la sua posizione",
        M_REQUEST_GLYMPSE: "Richiesta da %1$s di condividere la tua posizione",
        M_DOWNLOAD_APP: "Prendi App",
        M_LAUNCH_APP: "Vedi nella App",
        M_GET_ADDR: "Sto recuperando indirizzo",
        M_SHARE_LOC: "Condividi la tua posizione!",
        M_STREET: "Indirizzo",
        M_SATELLITE: "Satellite",
        M_TRAFFIC: "Traffico",
        M_YOUR_LOCATION: "La tua posizione",
        M_DEALS: "Affari",
        M_WEATHER: "Meteo",
        M_HELP: "Aiuto",
        M_FEEDBACK: "Feedback",
        M_DEBUG: "Debug",
        M_SUBMIT_A_BUG: "Segnala un errore",
        M_SEND_MESSAGE: "Invia messaggio",
        M_TAG: "Tag",
        M_ALTITUDE: "Altitudine",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Quasi lì!",
            "Dai un'occhiata a questo!",
            "Sto arrivando!",
            "Sono diretto a casa adesso!",
            "Qui è dove mi puoi vedere in giro, stasera!",
            "Eccomi qui, tu dove sei?",
            "Ehi, guarda dove sono!",
            "Sarò lì.",
            "Sono quasi arrivato!",
            "Mi sto innamorando di Glympse!",
            "Ho dimenticato l'insalata, ma porterò il salame.",
            "Penso di essermi perso.",
            "Nei paraggi...",
            "Solo un check-in.",
            "Appena uscito.",
            "Incontriamoci qui.",
            "Vediamoci qui a pranzo.",
            "Vediamoci quando arrivo lì.",
            "Hai bisogno di qualcosa mentre sono qui?",
            "Sono in ritardo :(",
            "Ci vediamo fra poco.",
            "Bloccato nel traffico.",
            "Tag, ci sei!",
            "Vuoi qualcosa da mangiare?",
            "Guarda dove sono!"
        ],
        M_NEVER_LOCATION: "Località per %1$s non disponibile",
        M_TERRAIN: "Terreno",
        M_DOWNLOAD_ACTIVE: "...per condividere la tua località",
        M_DOWNLOAD_INACTIVE: "...per richiedere un nuovo Glympse",
        M_DOWNLOAD_APP_ALT: "Prendi Glympse",
        M_HERE_DL_MOBILE: "Prendi Glympse",
        M_HERE_DL_FULL: "Prendi Glympse",
        M_NO_NAME: "Un amico"
    }
};
