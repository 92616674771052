glympse.Loc.Langs["in"] =
glympse.Loc.Langs["in_id"] =
{
    locale: ["in","in_id"],
    generated: "2014-09-04T21:11:35.8586933Z",
    strings: {
        M_ETA: "perkiraan waktu tiba",
        M_DESTINATION: "Tujuan",
        M_EXPIRED: "Kedaluwarsa",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grup",
        M_JOIN_GROUP: "Gabung ke Grup",
        M_KMH: "km\/j",
        M_MPH: "mpj",
        M_OFF: "MATI",
        M_ON: "NYALA",
        M_SETTINGS: "Pengaturan",
        M_UNITS: "Satuan",
        M_CLOSE: "Tutup",
        M_VIEW_MAP: "Lihat Peta",
        M_SEND_GLYMPSE: "Kirim Glympse",
        M_VIEW : "Lihat",
        M_MAP: "Peta",
        M_AGO: "yang lalu",
        M_LAST_UPDATED: "Diperbarui",
        M_SPEED: "Kecepatan",
        M_EXPIRES_IN: "Kedaluwarsa dalam",
        M_ARRIVES_IN: "Tiba dalam",
        M_DAY: "hari",
        M_DAYS: "hari",
        M_HOUR: "jam",
        M_HOURS: "jam",
        M_LOADING: "Memuat, harap menunggu…",
        M_MINUTE: "mnt",
        M_MINUTES: "mnt",
        M_NEVER: "tidak pernah",
        M_SECOND: "dtk",
        M_SECONDS: "dtk",
        M_REMAINING: "sisa",
        M_EXPIRED_GLYMPSE: "Glympse dari %1$s kedaluwarsa %2$s yang lalu",
        M_EXPIRED_GLYMPSE_BTN: "Lihat Pembaruan Terakhir",
        M_INVALID_GLYMPSE: "Glympse %1$s tak lagi tersedia",
        M_NO_LOCATION: "Mencari lokasi untuk %1$s Harap menunggu...",
        M_NO_MEMBERS: "Saat ini tak ada orang di grup %1$s menunggu Glympse...",
        M_NO_USERS: "Tak ada yang berbagi dalam topik %1$s Menunggu lokasi...",
        M_WAITING_TWITTER: "Menunggu %1$s untuk berbagi lokasi mereka",
        M_REQUEST_GLYMPSE: "Rikues dari %1$s untuk berbagi lokasimu",
        M_DOWNLOAD_APP: "Dapatkan Aplikasi",
        M_LAUNCH_APP: "Lihat dalam aplikasi",
        M_GET_ADDR: "Memperoleh alamat",
        M_SHARE_LOC: "Bagikan lokasimu!",
        M_STREET: "Jalan",
        M_SATELLITE: "Satelit",
        M_TRAFFIC: "Lalu Lintas",
        M_YOUR_LOCATION: "Lokasimu",
        M_DEALS: "Transaksi",
        M_WEATHER: "Cuaca",
        M_HELP: "Bantuan",
        M_FEEDBACK: "Masukan",
        M_DEBUG: "Debug",
        M_SUBMIT_A_BUG: "Laporkan bug",
        M_SEND_MESSAGE: "Kirim pesan",
        M_TAG: "Label",
        M_ALTITUDE: "Ketinggian",
        M_METERS: "m",
        M_FEET: "kaki",
        messages: [
            "Hampir sampai!",
            "Lihat ini!",
            "Saya sedang ke sana!",
            "Sedang menuju ke rumah!",
            "Di sinilah saya nongkrong malam ini!",
            "Saya sudah sampai, kau di mana?",
            "Hei, lihat lokasi saya saat ini!",
            "Saya akan sampai segera.",
            "Saya hampir sampai!",
            "Saya jatuh cinta dengan Glympse!",
            "Saya lupa bawa salad, namun saya membawa salami.",
            "Sepertinya saya tersesat.",
            "Saya berada di dekat sini...",
            "Hanya cek masuk.",
            "Baru saja pergi.",
            "Ayo bertemu di sini.",
            "Temui saya di sini untuk makan siang.",
            "Temui saya setelah saya sampai di sana.",
            "Perlu sesuatu saat saya berada di sini?",
            "Terlambat :(",
            "Sampai jumpa sebentar lagi.",
            "Terjebak macet.",
            "Kena, kini giliranmu!",
            "Ingin makan sesuatu?",
            "Lihat saya."
        ],
        M_NEVER_LOCATION: "Lokasi untuk%1$s tidak tersedia",
        M_TERRAIN: "Medan",
        M_DOWNLOAD_ACTIVE: "...untuk berbagi lokasimu",
        M_DOWNLOAD_INACTIVE: "...untuk merikues Glympse baru",
        M_DOWNLOAD_APP_ALT: "Dapatkan Glympse",
        M_HERE_DL_MOBILE: "Dapatkan Glympse",
        M_HERE_DL_FULL: "Dapatkan Glympse",
        M_NO_NAME: "Seorang teman"
    }
};
