glympse.Intro =
{
	"animType": "linear"
	, "bgColor": "0x0C235B"
	, "bgColorMap": "0xFFFFFF"
	, "displayDelay": 0
	, "displayDuration": 1500
	, "hideDuration": 400
	, "iconHtml": { "path": "images/intro/glympse.png"
				  , "w": 284
				  , "h": 68
				  , "dx": 0
				  , "dy":0
	}
};