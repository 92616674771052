glympse.Loc.Langs["tl"] =
glympse.Loc.Langs["tl_ph"] =
{
    locale: ["tl","tl_ph"],
    generated: "2014-09-04T21:11:36.0926963Z",
    strings: {
        M_ETA: "Tinatayang oras ng pagdating",
        M_DESTINATION: "Destinasyon",
        M_EXPIRED: "Lipas na",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupo",
        M_JOIN_GROUP: "Sumali sa grupo",
        M_KMH: "kilometro kada oras",
        M_MPH: "milya kada oras",
        M_OFF: "I-OFF",
        M_ON: "I-ON",
        M_SETTINGS: "Mga setting",
        M_UNITS: "Mga Yunit",
        M_CLOSE: "Isara",
        M_VIEW_MAP: "Tignan ang mapa",
        M_SEND_GLYMPSE: "Isend sa Glympse",
        M_VIEW : "Tignan",
        M_MAP: "Mapa",
        M_AGO: "ang nakalipas",
        M_LAST_UPDATED: "Nabago na",
        M_SPEED: "Bilis",
        M_EXPIRES_IN: "Lilipas na sa",
        M_ARRIVES_IN: "Dumating sa",
        M_DAY: "araw",
        M_DAYS: "mga araw",
        M_HOUR: "oras",
        M_HOURS: "oras",
        M_LOADING: "Sandali lang po, nagloload pa. . . ",
        M_MINUTE: "minuto",
        M_MINUTES: "minuto",
        M_NEVER: "hindi kailanman",
        M_SECOND: "segundo",
        M_SECONDS: "segundo",
        M_REMAINING: "natitira",
        M_EXPIRED_GLYMPSE: "Ang Glympse simula %1$s ay lipas na %2$s ang nakakaraan",
        M_EXPIRED_GLYMPSE_BTN: "Tignan ang huling pagbabago",
        M_INVALID_GLYMPSE: "Ang Glympse %1$s ay hindi na pwede",
        M_NO_LOCATION: "Kinuhuha ang location %1$s Teka lang po...",
        M_NO_MEMBERS: "Kasalukuyang walang kahit sino ang nasa grupo%1$s Naghihintay sa mga Glympse...",
        M_NO_USERS: "Walang nagbabahagi sa paksa %1$s Naghihintay sa mga lokasyon...",
        M_WAITING_TWITTER: "Naghihintay sa mga %1$s na ibahagi ang kanilang lokasyon",
        M_REQUEST_GLYMPSE: "Pakiusap galing kay %1$s na ibahagi ang iyong lokasyon",
        M_DOWNLOAD_APP: "Gumuha ng App",
        M_LAUNCH_APP: "Tignan sa App",
        M_GET_ADDR: "Tinutunton ang address",
        M_SHARE_LOC: "Ibahagi ang iyong lokasyon!",
        M_STREET: "Kalye",
        M_SATELLITE: "Satellite",
        M_TRAFFIC: "Trapik",
        M_YOUR_LOCATION: "Ang iyong lokasyon",
        M_DEALS: "Mga kasunduan",
        M_WEATHER: "Panahon",
        M_HELP: "Tulong",
        M_FEEDBACK: "Puna",
        M_DEBUG: "Debug",
        M_SUBMIT_A_BUG: "Magsumite ng pagkakamali",
        M_SEND_MESSAGE: "Ipadala ang mensahe",
        M_TAG: "Mag-tag",
        M_ALTITUDE: "Altitude",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Malapit na 'ko dyan!",
            "Tignan mo 'to!",
            "Papunta na 'ko!",
            "Pauwi na 'ko ngayon!",
            "Dito ako ang tambayan ko ngayong gabi!",
            "Andito na ko, nasaan ka na?",
            "Uy, tignan mo kung nasaan ako!",
            "Punta ako dyan.",
            "Malapit na 'ko dyan!",
            "Inlove na ko sa Glympse!",
            "Nakalimutan 'ko yung salad, pero magdadala ako ng salami.",
            "Sa tingin ko nawawala ako.",
            "Nasa kabahayanan namin... ",
            "Sumasaglit lang.",
            "Aalis pa lang.",
            "Magkita tayo dito.",
            "Magkita tayo dito para magtanghalian.",
            "Salubungin mo ko pagdating ko dyan.",
            "Ano pang kailangan mo hangga't nandito ako?",
            "Late na 'ko :(",
            "Kita tao maya-maya.",
            "Lubog sa trapik.",
            "Nai-tag kita,Taya ka na!",
            "Gusto mo ba ng makakain?",
            "Panuorin mo 'ko."
        ],
        M_NEVER_LOCATION: "Ang lokasyon sa %1$s ay hindi pupwede",
        M_TERRAIN: "Lupain",
        M_DOWNLOAD_ACTIVE: "...para ibahagi ang iyong lokasyon",
        M_DOWNLOAD_INACTIVE: "...para humiling ng bagong Glympse",
        M_DOWNLOAD_APP_ALT: "Kumuha ng Glympse",
        M_HERE_DL_MOBILE: "Kumuha ng Glympse",
        M_HERE_DL_FULL: "Kumuha ng Glympse",
        M_NO_NAME: "Isang kaibigan"
    }
};
