glympse.Loc.Langs["es_es"] =
{
    locale: ["es_es","es_es"],
    generated: "2014-09-07T02:17:29.632491Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destino",
        M_EXPIRED: "Expirado",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupo",
        M_JOIN_GROUP: "Unirse al grupo",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "OFF",
        M_ON: "ON",
        M_SETTINGS: "Configuración",
        M_UNITS: "Unidades",
        M_CLOSE: "Cerrar",
        M_VIEW_MAP: "Ver Mapa",
        M_SEND_GLYMPSE: "Enviar Glympse",
        M_VIEW : "Ver",
        M_MAP: "Mapa",
        M_AGO: "hace",
        M_LAST_UPDATED: "Actualizado",
        M_SPEED: "Velocidad",
        M_EXPIRES_IN: "Expira en",
        M_ARRIVES_IN: "Llega en",
        M_DAY: "día",
        M_DAYS: "días",
        M_HOUR: "hora",
        M_HOURS: "horas",
        M_LOADING: "Cargando, espere por favor...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "nunca",
        M_SECOND: "seg",
        M_SECONDS: "seg",
        M_REMAINING: "restante",
        M_EXPIRED_GLYMPSE: "El Glympse de %1$s expiró hace %2$s",
        M_EXPIRED_GLYMPSE_BTN: "Ver última actualización",
        M_INVALID_GLYMPSE: "El Glympse %1$s ya no está disponible",
        M_NO_LOCATION: "Obteniendo la ubicación de %1$s Espere, por favor...",
        M_NO_MEMBERS: "Ahora mismo no hay nadie en el grupo %1$s Esperando por Glympses...",
        M_NO_USERS: "Nadie está compartiendo en el tema %1$s Esperando ubicaciones...",
        M_WAITING_TWITTER: "Esperando a que %1$s comparta su ubicación",
        M_REQUEST_GLYMPSE: "Petición de %1$s para que compartas tu ubicación",
        M_DOWNLOAD_APP: "Descargar la App",
        M_LAUNCH_APP: "Ver en la App",
        M_GET_ADDR: "Recuperando dirección",
        M_SHARE_LOC: "¡Comparte tu ubicación!",
        M_STREET: "Calle",
        M_SATELLITE: "Satelite",
        M_TRAFFIC: "Tráfico",
        M_YOUR_LOCATION: "Tu ubicación",
        M_DEALS: "Ofertas",
        M_WEATHER: "El tiempo",
        M_HELP: "Ayuda",
        M_FEEDBACK: "Comentarios",
        M_DEBUG: "Depurar",
        M_SUBMIT_A_BUG: "Reportar un error",
        M_SEND_MESSAGE: "Enviar mensaje",
        M_TAG: "Etiqueta",
        M_ALTITUDE: "Altitud",
        M_METERS: "m.",
        M_FEET: "pies",
        messages: [
            "¡Ya casi estoy allí!",
            "¡Mira esto!",
            "¡De camino!",
            "¡Voy para casa!",
            "¡Aquí es donde voy a estar esta noche!",
            "Estoy aquí, ¿dónde estás?",
            "¡Mira dónde estoy!",
            "Estaré allí enseguida.",
            "¡Ya casi estoy allí!",
            "¡Me estoy enamorando de Glympse!",
            "Me olvidé de la ensalada, pero llevo el salami.",
            "Creo que me he perdido.",
            "En el vecindario...",
            "Apenas llegando.",
            "Saliendo justo ahora.",
            "Encontrémonos allí.",
            "Quedamos aquí para comer.",
            "Nos vemos cuando llegue allí.",
            "¿Necesitas algo mientras estoy aquí?",
            "Llego tarde :(",
            "Te veo en un momento.",
            "Atascado por el tráfico.",
            "¡Es tu turno!",
            "¿Quieres algo de comer?",
            "Mírame."
        ],
        M_NEVER_LOCATION: "La ubicación de %1$s no está disponible",
        M_TERRAIN: "Relieve",
        M_DOWNLOAD_ACTIVE: "...para compartir tu ubicación",
        M_DOWNLOAD_INACTIVE: "...para pedir un nuevo Glympse",
        M_DOWNLOAD_APP_ALT: "Descargar Glympse",
        M_HERE_DL_MOBILE: "Descargar Glympse",
        M_HERE_DL_FULL: "Descargar Glympse",
        M_NO_NAME: "Un amigo"
    }
};
