glympse.uiTheme =
{
    application: {
        scaleTargetSuffix: "",
        defaultDownloadLink: "http://glympse.com/download"
    },
    global: {},
    appHeader: {
        btnTxt: {
            fontStyle: "bold",
            shadowBlur: 0.1,
            shadowOffset: [1, 1],
            listening: false
        },
        btnBg: {
            fill: null
        },
        cBtnHeight: 28,
        cBtnPaddingText: 30,
        cBtnTextFontSize: 16,
        clrBg: {
            bg: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 50],
                fillLinearGradientColorStops: [0, "#214080", 1, "#0C235B"]
            }
        },
        clrBtnStart: "#f05b22",
        clrText: "#F7F7F7",
        ctaPadding: {
            right: 10
        },
        dlBtn: {
            colorDown: "#FFFFFF",
            colorUp: "#FFFFFF",
            bgDown: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 28],
                fillLinearGradientColorStops: [0.02, "#F05B22", 1, "#F9A11B"]
            },
            bgUp: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 28],
                fillLinearGradientColorStops: [0.02, "#F9A11B", 1, "#F05B22"]
            }
        },
        logoPadding: {
            top: 10,
            left: 10
        },
        logoSpriteName: "logo",
        textPadding: 10,
        txtFontSize: 18,
        txtInfo: {
            fill: "#68a9ea",
            shadowColor: "#0C235B"
        },
        txtLeftMargin: 16,
        txtLeftMarginMin: 4,
        txtRightMargin: 4
    },
    avatar: {
        border: "2px solid #FFFFFF",
        defClrActive: "#68A9EA",
        defClrBalloon: "rgba(35,31,32,0.9)",
        imgAvatarBckgrdColor: "#FFFFFF",
        balloonBckgrdColor: "rgba(35, 31, 32, 0.9)"
    },
    destination: {
        bgCornerRadius: 4,
        cBgColor: "rgba(34,31,31,0.9)",
        shadowColor: "rgba(0,0,0,0.84)"
    },
    inviteView: {
        cEtaColorAir: "#221F1F",
        etaTrailOptions: null
    },
    lockButton: {
        animType: "ease-out",
        btnBorder: null,
        clrBtnBgStroke: "transparent",
        fillCircUp: "#231F20",
        fillCircDown: "#ff8000"
    },
    mapMarker: {
        defaultBg: "rgba(34,31,31,0.9)",
        defaultColor: "#F7F7F7"
    },
    options: {
        btnBorder: null,
        clrBgStroke: null,
        fillCircUp: "rgba(35, 31, 32, 0.8)",
        fillCircDown: "#FF8000"
    },
    optionsPanel: {
        btn: {
            colorDown: "#FFFFFF",
            colorUp: "#FFFFFF",
            bgDown: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 30],
                fillLinearGradientColorStops: [0, '#1c1e50', 1, '#214080']
            },
            bgUp: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 30],
                fillLinearGradientColorStops: [0, '#214080', 1, '#1c1e50']
            },
            width: 230
        },
        defClrBgBack: "#ECECEC",
        defItemHighlight: "#F9A11B",
        defItemNotSelected: "#8D96A9",
        defItemOff: "#707A8E",
        defItemSelected: "#3057A5",
        selectBg: "#FFFFFF",
        unitToggle: {
            hasShadow: true,
            background: {
                bg: "#D7D7D7"
            },
            foreground: {
                color: "#FFFFFF",
                bg: {
                    fillLinearGradientStartPoint: [0, 0],
                    fillLinearGradientEndPoint: [0, 30],
                    fillLinearGradientColorStops: [0, "#335DB1", 1, "#264990"]
                }
            }
        },
        trafficToggle: {
            hasShadow: true,
            width: 70,
            toggleColor: null,
            enabledBg: {
                bg: {
                    fillLinearGradientStartPoint: [0, 0],
                    fillLinearGradientEndPoint: [0, 28],
                    fillLinearGradientColorStops: [0, "#F05B22", 1, "#F9A11B"]
                }
            },
            disabledBg: {
                bg: "#D7D7D7"
            }
        },
        defaultColor: "#BEC8D2",
        mapTypeCircParams: null,
        panelCornerRadius: 4,
        mapTypeControl: {
            type: "check",
            fgConfig: {
                listening: false,
                visible: false
            },
            bgConfig: null
        },
        uiColor: "#BEC8D2",
        labelFontWeight: "bold"
    },
    simpleDialog: {
        bgAvatar: "#FFFFFF",
        btnFill: "#F7F7F7",
        avatarCornerRadius: 5,
        defBgInfo: "#F0F0EF",
        defBgScreen: "rgba(0, 0, 0, 0.82)",
        dlgInfoColor: "#FFFFFF",
        okButton: {
            colorUp: "#FFFFFF",
            colorDown: "#FFFFFF",
            bgDown: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 30],
                fillLinearGradientColorStops: [0, '#1c1e50', 1, '#214080']
            },
            bgUp: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 30],
                fillLinearGradientColorStops: [0, '#214080', 1, '#1c1e50']
            }
        },
        txtVersionFill: "rgb(77,77,77)"
    },
    startPoint: {
        colorDefaults: {
            strokeAlpha: 0.5,
            strokeScale: 0.95,
            strokeColor: "#323334",
            fillAlpha: 1,
            fillScale: 1
        }
    },
    userHeader: {
        avatar: {
            x: 5,
            y: 5,
            width: 60,
            height: 60,
            listening: false,
            activeBorder: "#F05b22",
            inactiveBorder: "#FFFFFF"
        },
        avatarBg: {
            x: 0,
            y: 0,
            width: 70,
            height: 70,
            fill: '#FFFFFF',
            listening: true,
            cornerRadius: 5,
            shadowColor: 'rgba(0,0,0,0.4)',
            shadowBlur: 8
        },
        clrCheckIn: "#FC9A1C",
        clrExpired: "#DA1D5D",
        clrExpires: "#68A9EA",
        clrName: "#231F20",
        clrUpdated: "#BCBDBF",
        clrSpeed: "#424143",
        container: {
            bgDown: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 100],
                fillLinearGradientColorStops: [0.02, "#d5d5d6", 1, "#f4f4f4"]
            },
            bgUp: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 100],
                fillLinearGradientColorStops: [0.02, "#f5f5f6", 1, "#FFFFFF"]
            },
            height: 100
        },
        fontStyleEta: "bold",
        fontStyleName: "bold",
        fontStyleSpeed: "bold",
        showInfoIcon: true,
        tabSepStyles: {
            borderColor: "#F2F2F4",
            borderWidth: 1,
            fill: "#FFFFFF",
            height: 7
        }
    },
    userDetail: {
        avatar: {
            borderColor: "#ffffff",
            borderWidth: 1,
            cfgBgShadow: true,
            cornerRadius: 5,
            cornerRadiusAlt: 0,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.4)",
            size: 60
        },
        bgRadius: 4,
        bgRadiusAlt: 0,
        btn: {
            colorDown: "#F7F7F7",
            colorUp: "#F7F7F7",
            bgDown: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 30],
                fillLinearGradientColorStops: [0, "#1c1e50", 1, "#214080"]
            },
            bgUp: {
                fillLinearGradientStartPoint: [0, 0],
                fillLinearGradientEndPoint: [0, 30],
                fillLinearGradientColorStops: [0, "#214080", 1, "#1c1e50"]
            },
            margin: "10px auto 20px auto",
            width: 150,
            fontStyle: "bold"
        },
        cfgInfoFillColor: "#424143",
        cfgInfoFontStyle: "bold",
        clrCheckIn: "#FC9A1C",
        clrExpiresText: "#68A9EA",
        clrProgressBg: {
            fillLinearGradientStartPoint: [0, 0],
            fillLinearGradientEndPoint: [0, 10],
            fillLinearGradientColorStops: [0, "#989898", 0.3333, "#d5d5d5", 0.7333, "#d5d5d5", 1, "#cbcbcb"]
        },
        clrProgressFg: {
            fillLinearGradientStartPoint: [0, 0],
            fillLinearGradientEndPoint: [0, 10],
            fillLinearGradientColorStops: [0, "#F9A11D", 1, "#F05C24"]
        },
        defBgInfo: "#F0F0EF",
        defBgInfoAlt: "#FFFFFF",
        defBgScreen: "rgba(0, 0, 0, 0.82)",
        defTextCommon: "#424143",
        defUpdated: "#BCBDBF",
        defLabel: "#97999b",
        destAlt: null,        // id of an alternate icon to use for the destination (different than "dest")
        destFontStyle: "bold",
        panelHeader: null,
        separatorColor: "rgba(0,0,0,0.1)",
        separatorColorAlt: "rgba(0,0,0,0.1)",
        msgBoxStyles: {
        },
        nameContainer: {
            margin: "2px 10px 0 0"
        },
        usrName: {
            color: "#231F20",
            fontSize: 25,
            fontSizeMin: 16,
            fontStyle: "bold"
        },
        usrMessage: {
            defBg: "#FFFFFF",
            defBgAlt: "#F0F0EF",
            color: "#6D6F70",
            textAlign: "left"
        },
        color: 0xf0f0f0,
        useAltProgress: false,
        useAltBtnBg: false,
        version: {
            color: "rgb(197,197,197)",
            colorAlt: "#e8e8e8",
            fontSize: 9
        }
    },
    zoomButtons: {
        imgMinusPos: {
            x: 11,
            y: 58
        },
        btnBorder: null,
        btnGroupMarginTop: null,
        clrBgStroke: "transparent",
        fillBtnBackground: "rgba(35, 31, 32, 0.75)",
        fillCircOver: "#7D797A",
        fillCircUp: "#231F20",
        fillCircDown: "#FF8000"
    }
};
