glympse.Loc.Langs["hu"] =
glympse.Loc.Langs["hu_hu"] =
{
    locale: ["hu","hu_hu"],
    generated: "2014-09-04T21:11:35.7494919Z",
    strings: {
        M_ETA: "várható érkezés",
        M_DESTINATION: "Célállomás",
        M_EXPIRED: "Lejárt",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Csoport",
        M_JOIN_GROUP: "Csatlakozás a csoporthoz",
        M_KMH: "km\/h",
        M_MPH: "Mph (mérföld\/óra)",
        M_OFF: "KIKAPCSOLÁS",
        M_ON: "BEKAPCSOLÁS",
        M_SETTINGS: "Beállítások",
        M_UNITS: "TÁV",
        M_CLOSE: "Bezár",
        M_VIEW_MAP: "Térkép megtekintése",
        M_SEND_GLYMPSE: "Glympse küldése",
        M_VIEW : "Megtekintés",
        M_MAP: "Térkép",
        M_AGO: "ezelőtt",
        M_LAST_UPDATED: "Frissítve",
        M_SPEED: "Sebesség",
        M_EXPIRES_IN: "Lejár:",
        M_ARRIVES_IN: "Érkezik:",
        M_DAY: "nap",
        M_DAYS: "napok",
        M_HOUR: "óra",
        M_HOURS: "órák",
        M_LOADING: "Töltés, kérlek várj…",
        M_MINUTE: "perc",
        M_MINUTES: "perc",
        M_NEVER: "soha",
        M_SECOND: "másodperc",
        M_SECONDS: "másodperc",
        M_REMAINING: "maradt",
        M_EXPIRED_GLYMPSE: "A %1$s-kor indított Glympse %2$s val\/vel ezelőtt lejárt",
        M_EXPIRED_GLYMPSE_BTN: "Utolsó frissítés megtekintése",
        M_INVALID_GLYMPSE: "A %1$s Glympse már nem elérhető",
        M_NO_LOCATION: "Hely keresése %1$s-hoz\/hez\/höz. Kérlek várj...",
        M_NO_MEMBERS: "Jelenleg nincs senki a %1$s csoportban. Várakozás Glympse-ekre...",
        M_NO_USERS: "Senki sem osztott meg a\/az %1$s témában. Várakozás helyszínekre…",
        M_WAITING_TWITTER: "Várakozás %1$s-ra\/re, hogy megossza a tartózkodási helyét",
        M_REQUEST_GLYMPSE: "%1$s azt kéri, hogy megoszd a tartózkodási helyed",
        M_DOWNLOAD_APP: "App letöltése",
        M_LAUNCH_APP: "Megtekintés az alkalmazásban ",
        M_GET_ADDR: "Cím visszaállítása ",
        M_SHARE_LOC: "Oszd meg a tartózkodási helyed! ",
        M_STREET: "Utca",
        M_SATELLITE: "Műhold",
        M_TRAFFIC: "Forgalom",
        M_YOUR_LOCATION: "A te tartózkodási helyed",
        M_DEALS: "Irány",
        M_WEATHER: "Időjárás",
        M_HELP: "Segítség",
        M_FEEDBACK: "Visszajelzés",
        M_DEBUG: "Hibakeresés",
        M_SUBMIT_A_BUG: "Hiba jelentése",
        M_SEND_MESSAGE: "Üzenet küldése",
        M_TAG: "Megjelölés",
        M_ALTITUDE: "Tengerszint feletti magasság",
        M_METERS: "méter",
        M_FEET: "láb",
        messages: [
            "Mindjárt ott vagyok!",
            "Ezt nézd meg!",
            "Ide tartok!",
            "Éppen hazafelé megyek!",
            "Ma este itt leszek!",
            "Én itt vagyok, és te?",
            "Ide nézz, hol vagyok!",
            "Pont itt leszek",
            "Mindjárt ott vagyok!",
            "Imádom a Glympse-et!",
            "A salátát elfelejtettem, de szalámit viszek.",
            "Azt hiszem, eltévedtem…",
            "A környéken járok...",
            "Csak becsekkolok.",
            "Indulok.",
            "Találkozzunk itt!",
            "Ebédelj itt velem!",
            "Itt találkozzunk, ha odaérek.",
            "Szeretnél innen valamit?",
            "Késésben vagyok :(",
            "Nemsokára találkozunk.",
            "Dugóban vagyok.",
            "Meg vagy jelölve!",
            "Szeretnél enni valamit?",
            "Majd figyelj :)"
        ],
        M_NEVER_LOCATION: "%1$s tartózkodási helye nem elérhető ",
        M_TERRAIN: "Terület",
        M_DOWNLOAD_ACTIVE: "...a helyed megosztásához",
        M_DOWNLOAD_INACTIVE: "... egy új Glympse felkéréséhez",
        M_DOWNLOAD_APP_ALT: "Glympse letöltése",
        M_HERE_DL_MOBILE: "Glympse letöltése",
        M_HERE_DL_FULL: "Glympse letöltése",
        M_NO_NAME: "Egy barát"
    }
};
