glympse.Loc.Langs["nb"] =
glympse.Loc.Langs["nb_no"] =
{
    locale: ["nb","nb_no"],
    generated: "2014-09-04T21:11:34.9382815Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destinasjon",
        M_EXPIRED: "Utløpt",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Gruppe",
        M_JOIN_GROUP: "Bli med i gruppe",
        M_KMH: "km\/t",
        M_MPH: "mph",
        M_OFF: "AV",
        M_ON: "PÅ",
        M_SETTINGS: "Innstillinger",
        M_UNITS: "Enheter",
        M_CLOSE: "Avslutt",
        M_VIEW_MAP: "Se kart",
        M_SEND_GLYMPSE: "Send Glympse",
        M_VIEW : "Se ",
        M_MAP: "Kart",
        M_AGO: "siden",
        M_LAST_UPDATED: "Oppdatert",
        M_SPEED: "Hastighet",
        M_EXPIRES_IN: "Utløper om",
        M_ARRIVES_IN: "Ankommer om",
        M_DAY: "dag",
        M_DAYS: "dager",
        M_HOUR: "time",
        M_HOURS: "timer",
        M_LOADING: "Laster, vennligst vent...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "aldri",
        M_SECOND: "sek",
        M_SECONDS: "sek",
        M_REMAINING: "gjenstår",
        M_EXPIRED_GLYMPSE: "Glympse fra %1$s utløpte for %2$s siden",
        M_EXPIRED_GLYMPSE_BTN: "Se siste oppdatering",
        M_INVALID_GLYMPSE: " Glympse %1$s er ikke lenger tilgjengelig",
        M_NO_LOCATION: "Henter lokasjon for %1$s Vennligst vent...",
        M_NO_MEMBERS: "For øyeblikket ingen i gruppen %1$s Venter på Glympses...",
        M_NO_USERS: "Ingen deler i emne %1$s Venter på lokasjoner...",
        M_WAITING_TWITTER: "Venter på at %1$s skal dele sin lokasjon",
        M_REQUEST_GLYMPSE: "Forespørsel fra %1$s om å dele din lokasjon",
        M_DOWNLOAD_APP: "Få app",
        M_LAUNCH_APP: "Se i app",
        M_GET_ADDR: "Henter addresse",
        M_SHARE_LOC: "Del din lokasjon!",
        M_STREET: "Gate",
        M_SATELLITE: "Satellitt",
        M_TRAFFIC: "Traffik",
        M_YOUR_LOCATION: "Din lokasjon",
        M_DEALS: "Avtaler",
        M_WEATHER: "Vær",
        M_HELP: "Hjelp",
        M_FEEDBACK: "Tilbakemeldinger",
        M_DEBUG: "Feilsøk",
        M_SUBMIT_A_BUG: "Send inn en feil",
        M_SEND_MESSAGE: "Send melding",
        M_TAG: "Tag",
        M_ALTITUDE: "Høyde",
        M_METERS: "m",
        M_FEET: "fot",
        messages: [
            "Snart klar!",
            "Sjekk ut dette!",
            "På tur!",
            "Dratt hjem nå!",
            "Det er her jeg henger i kveld!",
            "Her er jeg, hvor er du?",
            "Hei, se hvor jeg er!",
            "Jeg kommer snart.",
            "Jeg er snart fremme!",
            "Jeg forelsker meg i Glympse!",
            "Jeg glemte salaten, men jeg kommer med salamien.",
            "Jeg tror jeg har rotet meg bort.",
            "I nabolaget...",
            "Bare sjekker inn.",
            "Drar nå.",
            "La oss møtes her.",
            "Møt meg her til lunsj.",
            "Møt meg når jeg kommer dit.",
            "Trenger du noe mens jeg er her?",
            "Blir forsinket :(",
            "Ser deg snart.",
            "Sitter fast i traffik.",
            "Tag, du har’n!",
            "Vil du ha noe å spise?",
            "Se på meg."
        ],
        M_NEVER_LOCATION: "Beliggenhet for %1$s er ikke tilgjengelig",
        M_TERRAIN: "Terreng",
        M_DOWNLOAD_ACTIVE: "...for å dele din beliggenhet",
        M_DOWNLOAD_INACTIVE: "...for å be om en ny Glympse",
        M_DOWNLOAD_APP_ALT: "Få Glympse",
        M_HERE_DL_MOBILE: "Få Glympse",
        M_HERE_DL_FULL: "Få Glympse",
        M_NO_NAME: "En venn"
    }
};
