glympse.Loc.Langs["ms"] =
glympse.Loc.Langs["ms_my"] =
{
    locale: ["ms","ms_my"],
    generated: "2014-09-04T21:11:35.9366943Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destinasi",
        M_EXPIRED: "Luput",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Kumpulan",
        M_JOIN_GROUP: "Sertai Kumpulan",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "TUTUP",
        M_ON: "BUKA",
        M_SETTINGS: "Seting",
        M_UNITS: "Unit",
        M_CLOSE: "TUTUP",
        M_VIEW_MAP: "Lihat Peta",
        M_SEND_GLYMPSE: "Hantar Glympse",
        M_VIEW : "View",
        M_MAP: "Peta",
        M_AGO: "lalu",
        M_LAST_UPDATED: "Dikemas kini",
        M_SPEED: "Kelajuan",
        M_EXPIRES_IN: "Luput pada",
        M_ARRIVES_IN: "Tiba dalam",
        M_DAY: "hari",
        M_DAYS: "hari",
        M_HOUR: "jam",
        M_HOURS: "jam",
        M_LOADING: "Sedang memuatkan, sila tunggu...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "tidak pernah",
        M_SECOND: "saat",
        M_SECONDS: "saat",
        M_REMAINING: "baki",
        M_EXPIRED_GLYMPSE: "Glympse daripada %1$s luput %2$s yang lalu",
        M_EXPIRED_GLYMPSE_BTN: "Lihat Kemas Kini Akhir",
        M_INVALID_GLYMPSE: "Glympse %1$s tidak lagi tersedia",
        M_NO_LOCATION: "Mendapatkan lokasi untuk %1$s Sila tunggu...",
        M_NO_MEMBERS: "Tiada siapa yang berada di dalam kumpulan %1$s sekarang Tunggu untuk Glympses...",
        M_NO_USERS: "Tiada siapa yang berkongsi dalam topik %1$s Menunggu lokasi...",
        M_WAITING_TWITTER: "Menunggu %1$s untuk berkongsi lokasi mereka",
        M_REQUEST_GLYMPSE: "Permintaan daripada %1$s untuk berkongsi lokasi anda",
        M_DOWNLOAD_APP: "Dapatkan Aplikasi",
        M_LAUNCH_APP: "Lihat dalam Apl",
        M_GET_ADDR: "Mendapatkan semula alamat",
        M_SHARE_LOC: "Kongsi lokasi anda!",
        M_STREET: "Jalan",
        M_SATELLITE: "Satelit",
        M_TRAFFIC: "Trafik",
        M_YOUR_LOCATION: "Lokasi anda",
        M_DEALS: "Tawaran",
        M_WEATHER: "Cuaca",
        M_HELP: "Bantuan",
        M_FEEDBACK: "Maklum balas",
        M_DEBUG: "Nyahpepijat",
        M_SUBMIT_A_BUG: "Hantar pepijat",
        M_SEND_MESSAGE: "Hantar mesej",
        M_TAG: "Tag",
        M_ALTITUDE: "Altitud",
        M_METERS: "m",
        M_FEET: "kaki",
        messages: [
            "Hampir sampai!",
            "Lihat ini!",
            "Sedang dalam perjalanan my way!",
            "Menuju ke rumah sekarang!",
            "Di sini tempat saya melepak malam ini!",
            "Saya sudah sampai, di manakah anda?",
            "Hei, tengok di mana saya!",
            "Saya akan ke sana.",
            "Saya hampir sampai!",
            "Saya jatuh cinta dengan Glympse!",
            "Saya terlupa salad, tetapi saya bawa salami.",
            "Saya fikir saya sesat.",
            "Di kawasan kejiranan...",
            "Baru daftar masuk.",
            "Baru bertolak.",
            "Mari jumpa di sini.",
            "Jumpa saya di sini untuk makan tengah hari.",
            "Jumpa saya apabila saya sampai di sana.",
            "Perlu apa apa-apa sewaktu saya di sini?",
            "Terlewat :(",
            "Jumpa anda sebentar lagi.",
            "Terperangkap dalam kesesakan lalu lintas.",
            "Tag diri anda!",
            "Ingin apa-apa untuk dimakan?",
            "Saksikan saya."
        ],
        M_NEVER_LOCATION: "Lokasi untuk %1$s tidak tersedia",
        M_TERRAIN: "Rupa Bumi",
        M_DOWNLOAD_ACTIVE: "...untuk kongsikan lokasi anda",
        M_DOWNLOAD_INACTIVE: "...untuk meminta Glympse baharu",
        M_DOWNLOAD_APP_ALT: "Dapatkan Glympse",
        M_HERE_DL_MOBILE: "Dapatkan Glympse",
        M_HERE_DL_FULL: "Dapatkan Glympse",
        M_NO_NAME: "Seorang kenalan"
    }
};
