glympse.Loc.Langs["da"] =
glympse.Loc.Langs["da_dk"] =
{
    locale: ["da","da_dk"],
    generated: "2014-09-04T21:11:34.7822795Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destination",
        M_EXPIRED: "Udløbet",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Gruppe",
        M_JOIN_GROUP: "Giv gruppen besked",
        M_KMH: "km\/t",
        M_MPH: "miles i timen",
        M_OFF: "OFF",
        M_ON: "ON",
        M_SETTINGS: "Indstillinger",
        M_UNITS: "Units",
        M_CLOSE: "Luk",
        M_VIEW_MAP: "Se kortet",
        M_SEND_GLYMPSE: "Send Glympse",
        M_VIEW : "Visning",
        M_MAP: "Kort",
        M_AGO: "siden",
        M_LAST_UPDATED: "Opdateret",
        M_SPEED: "Hastighed",
        M_EXPIRES_IN: "Udløber om",
        M_ARRIVES_IN: "Ankommer om",
        M_DAY: "dag",
        M_DAYS: "dage",
        M_HOUR: "hr",
        M_HOURS: "timer",
        M_LOADING: "Indlæser, vent venligst...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "aldrig",
        M_SECOND: "sek.",
        M_SECONDS: "sek.",
        M_REMAINING: "tilbage",
        M_EXPIRED_GLYMPSE: "Glympse’et fra %1$s udløb for %2$s siden",
        M_EXPIRED_GLYMPSE_BTN: "Se seneste opdatering",
        M_INVALID_GLYMPSE: "Glympse’et %1$s er ikke længere tilgængeligt ",
        M_NO_LOCATION: "Henter placering for %1$s Vent venligst… ",
        M_NO_MEMBERS: "Der er i øjeblikket ingen i gruppen %1$s Afventer Glympses...",
        M_NO_USERS: "Der er ingen der deler noget i emne %1$s Afventer placeringer...",
        M_WAITING_TWITTER: "Venter på at %1$s deler deres placering ",
        M_REQUEST_GLYMPSE: "Anmodning fra %1$s om at dele din placering ",
        M_DOWNLOAD_APP: "Få app",
        M_LAUNCH_APP: "Se i app’en ",
        M_GET_ADDR: "Indhenter adresse ",
        M_SHARE_LOC: "Del din placering! ",
        M_STREET: "Vej",
        M_SATELLITE: "Satellit",
        M_TRAFFIC: "Trafik",
        M_YOUR_LOCATION: "Din placering ",
        M_DEALS: "Aftaler",
        M_WEATHER: "Vejr",
        M_HELP: "Hjælp",
        M_FEEDBACK: "Tilbagemelding",
        M_DEBUG: "Fejlfinding",
        M_SUBMIT_A_BUG: "Indrapporter en fejl ",
        M_SEND_MESSAGE: "Send besked ",
        M_TAG: "Tag",
        M_ALTITUDE: "Højde",
        M_METERS: "m",
        M_FEET: "fod",
        messages: [
            "Næsten fremme!",
            "Tjek lige det her!",
            "Er på vej!",
            "På vej hjem nu!",
            "Her hænger jeg ud i aften!",
            "Jeg er her, hvor er du?",
            "Hej - se lige, hvor jeg er henne!",
            "Jeg er der straks.",
            "Jeg er næsten fremme!",
            "Jeg er ved at forelske mig i Glympse!",
            "Af sti af sted!",
            "Jeg tror jeg er faret vild.",
            "I nabolaget…",
            "Er lige ankommet.",
            "Er netop på vej til at gå.",
            "Lad os mødes her.",
            "Mød mig her til frokost.",
            "Mød mig, når jeg når frem.",
            "Skal jeg tage noget med herfra?",
            "Er forsinket :(",
            "Ses om lidt.",
            "Sidder fast i trafikken.",
            "Du er blevet tagget!",
            "Lyst til noget at spise?",
            "Se mig."
        ],
        M_NEVER_LOCATION: "Placering for %1$s er ikke tilgængelig",
        M_TERRAIN: "Terræn",
        M_DOWNLOAD_ACTIVE: "...for at dele din placering",
        M_DOWNLOAD_INACTIVE: "...for at anmode om ny Glympse",
        M_DOWNLOAD_APP_ALT: "Få Glympse",
        M_HERE_DL_MOBILE: "Få Glympse",
        M_HERE_DL_FULL: "Få Glympse",
        M_NO_NAME: "En ven"
    }
};
