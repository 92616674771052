glympse.Loc.Langs["ja"] =
glympse.Loc.Langs["ja_jp"] =
{
    locale: ["ja","ja_jp"],
    generated: "2014-09-04T21:11:33.7370661Z",
    strings: {
        M_ETA: "到着予定",
        M_DESTINATION: "目的地",
        M_EXPIRED: "期限切れ",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "グループ",
        M_JOIN_GROUP: "グループに参加",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "オフ",
        M_ON: "オン",
        M_SETTINGS: "設定",
        M_UNITS: "単位",
        M_CLOSE: "閉じる",
        M_VIEW_MAP: "地図を見る",
        M_SEND_GLYMPSE: "Glympse 送信",
        M_VIEW : "表示",
        M_MAP: "地図",
        M_AGO: "前",
        M_LAST_UPDATED: "更新",
        M_SPEED: "速度",
        M_EXPIRES_IN: "残り時間は",
        M_ARRIVES_IN: "到着予定は",
        M_DAY: "日",
        M_DAYS: "日",
        M_HOUR: "時間",
        M_HOURS: "時間",
        M_LOADING: "読み込み中です。お待ち下さい...",
        M_MINUTE: "分",
        M_MINUTES: "分",
        M_NEVER: "未",
        M_SECOND: "秒",
        M_SECONDS: "秒",
        M_REMAINING: "残り時間",
        M_EXPIRED_GLYMPSE: "%1$sからのGlympseは %2$s 前に期限切れになりました",
        M_EXPIRED_GLYMPSE_BTN: "最終更新情報を見る",
        M_INVALID_GLYMPSE: "%1$sのGlympseはもはや利用できません",
        M_NO_LOCATION: "%1$sのロケーションを確認中 お待ちください...",
        M_NO_MEMBERS: "グループ %1$sにはメンバーがいません。Glympse待機中...",
        M_NO_USERS: "トピック%1$sは誰にも共有されていません。ロケーション待機中...",
        M_WAITING_TWITTER: "%1$sのロケーション共有を待機中",
        M_REQUEST_GLYMPSE: "%1$sがあなたのロケーションの共有をリクエストしています",
        M_DOWNLOAD_APP: "アプリを入手",
        M_LAUNCH_APP: "アプリで見る",
        M_GET_ADDR: "住所検索中",
        M_SHARE_LOC: "ロケーションを共有しましょう!",
        M_STREET: "ストリート",
        M_SATELLITE: "航空写真",
        M_TRAFFIC: "交通状況",
        M_YOUR_LOCATION: "あなたのロケーション",
        M_DEALS: "オススメ",
        M_WEATHER: "天気",
        M_HELP: "ヘルプ",
        M_FEEDBACK: "ご意見・ご感想",
        M_DEBUG: "デバッグ",
        M_SUBMIT_A_BUG: "バグを報告する",
        M_SEND_MESSAGE: "メッセージを送信する",
        M_TAG: "タグ",
        M_ALTITUDE: "高度",
        M_METERS: "メートル",
        M_FEET: "フィート",
        messages: [
            "あと少しで到着です!",
            "確認してください!",
            "今、向かっています!",
            "家に向かっています!",
            "私が今夜過ごす場所はここです!",
            "到着しました。どこにいますか?",
            "私の現在地を見てください!",
            "すぐに行きます。",
            "あと少しで到着します!",
            "Glympseは手放せません!",
            "サラダを忘れたけど、サラミを持って行きます。",
            "道に迷ったみたいです。",
            "近くにいます...",
            "チェックインしたところです。",
            "今から出ます。",
            "ここで会いましょう。",
            "昼食にここで会いましょう。",
            "そこに到着したら会ってください。",
            "ここにいる間、何か必要なものありますか?",
            "到着が遅れています :(",
            "では、すぐに。",
            "渋滞にはまりました。",
            "タグ付けしてください!",
            "食べたいものありますか?",
            "私をウォッチしてください。"
        ],
        M_NEVER_LOCATION: "%1$sの現在地は利用できません",
        M_TERRAIN: "地形",
        M_DOWNLOAD_ACTIVE: "...あなたの現在地を共有",
        M_DOWNLOAD_INACTIVE: "...新しいGlympseをリクエスト",
        M_DOWNLOAD_APP_ALT: "Glympseを入手",
        M_HERE_DL_MOBILE: "Glympseを入手",
        M_HERE_DL_FULL: "Glympseを入手",
        M_NO_NAME: "友人"
    }
};
