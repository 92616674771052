glympse.IMG["ss20"] = {
    "meta": {"name":"ss20.png","w":441,"h":391}
    , "airlineActive": {"x":123,"y":188,"w":70,"h":90}
    , "airlineColor": {"x":267,"y":188,"w":70,"h":90}
    , "airlineExpired": {"x":195,"y":188,"w":70,"h":90}
    , "btnSlide": {"x":1,"y":310,"w":80,"h":80}
    , "check": {"x":400,"y":203,"w":36,"h":32}
    , "checkin": {"x":325,"y":131,"w":42,"h":42}
    , "checkinDest": {"x":236,"y":310,"w":48,"h":48}
    , "chevDown": {"x":400,"y":259,"w":36,"h":20}
    , "chevUp": {"x":400,"y":237,"w":36,"h":20}
    , "dest": {"x":141,"y":310,"w":50,"h":62}
    , "info": {"x":400,"y":125,"w":38,"h":38}
    , "infoHit": {"x":400,"y":85,"w":38,"h":38}
    , "layers": {"x":400,"y":43,"w":40,"h":40}
    , "lock": {"x":286,"y":310,"w":35,"h":42}
    , "logo": {"x":1,"y":1,"w":267,"h":66}
    , "markerActive": {"x":83,"y":310,"w":56,"h":72}
    , "markerColor": {"x":186,"y":69,"w":56,"h":72}
    , "markerExpired": {"x":339,"y":188,"w":56,"h":72}
    , "markerHalo": {"x":270,"y":1,"w":128,"h":128}
    , "markerMap": {"x":193,"y":310,"w":41,"h":52}
    , "minus": {"x":400,"y":281,"w":35,"h":12}
    , "myIcon": {"x":400,"y":1,"w":40,"h":40}
    , "nubCenter": {"x":400,"y":295,"w":18,"h":19}
    , "plus": {"x":400,"y":165,"w":35,"h":36}
    , "pointerLeft": {"x":270,"y":131,"w":53,"h":46}
    , "tabBg": {"x":1,"y":69,"w":183,"h":76}
    , "unknown": {"x":1,"y":188,"w":120,"h":120}
    , "unlock": {"x":323,"y":310,"w":34,"h":42}
    , "watermark": {"x":1,"y":147,"w":148,"h":39}
};
