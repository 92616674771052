/**
 Loc string handling
 **/
glympse.Loc = function (defaultLanguage, allowLocalLanguage)
{
	var locDefaultFallback = 'en';
	var origDefaultLanguage = defaultLanguage;
	var gLangs = glympse.Loc.Langs;

	var that = this;


	///////////////////////////////////////////////////////////////////////////////
	// PROPERTIES
	///////////////////////////////////////////////////////////////////////////////

	this.curr = null;
	this.data = null;
	this.msgs = null;

	///////////////////////////////////////////////////////////////////////////////
	// PUBLICS
	///////////////////////////////////////////////////////////////////////////////

	this.setCurrLang = function (lang)
	{
		this.curr = lang || defaultLanguage;
		this.data = null;

		fillLocalesData();

		this.msgs = this.data.messages || [];
	};

	// Get a string with given tag
	// Falls all the way back to locDefaultFallback language if not present in default language (used
	// for filling in missing loc entries for a given language)
	this.getString = function (id)
	{
		var str = this.data[id];

		if (!str)
		{
			// store "not found" for subsequent calls
			str = '__' + id + '__';
			this.data[id] = str;
		}

		return str;
	};

	this.toString = function ()
	{
		var cnt = 0;
		var langs = [];

		for (var lang in gLangs)
		{
			if (gLangs.hasOwnProperty(lang))
			{
				cnt++;
				langs.push(lang);
			}
		}

		return ('Loc: defaultLang:' + defaultLanguage
			+ ", strings:" + this.data
			+ ', # of langs:' + cnt
			+ ', supported langs:' + langs
		);
	};


	///////////////////////////////////////////////////////////////////////////////
	// INTERNAL
	///////////////////////////////////////////////////////////////////////////////

	function fillLocalesData()
	{
		var lang = that.curr.replace('_', '-');

		updateLocaleReference(lang);

		// check if there is an alternative locale, if so add it to the strings objects array
		// e.g. "sr-Latn-ME"
		var langs = lang.split('-');
		langs.pop();

		while (langs.length)
		{
			lang = langs.join('-');
			updateLocaleReference(lang);
			langs.pop();
		}

		// The baseline *should* have every string
		updateLocaleReference(locDefaultFallback);
	}

	function updateLocaleReference(locale)
	{
		var loc = gLangs[locale];

		if (!loc || !loc.strings)
		{
			// console.log('no strings for locale', locale);
			return;
		}

		var strings = loc.strings;

		// console.log('updating locale references: ', locale, loc, strings);

		if (!that.data)
		{
			that.data = strings;
			return;
		}

		for (var id in strings)
		{
			if (strings.hasOwnProperty(id) && !that.data[id])
			{
				// console.log('filling missed string:', defaultLanguage, ' <- ', (locale + '.' + id));
				that.data[id] = strings[id];
			}
		}
	}


	function getBrowserLang()
	{
		var nav = navigator,
			browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
			i, len,
			language,
			languages = nav['languages'];

		// support for HTML 5.1 "navigator.languages"
		if (Array.isArray && Array.isArray(languages) || Object.prototype.toString.call(languages) === '[object Array]')
		{
			for (i = 0, len = languages.length; i < len; i++)
			{
				language = languages[i];
				if (language)
				{
					return language;
				}
			}
		}

		// support for other well known properties in browsers
		for (i = 0, len = browserLanguagePropertyKeys.length; i < len; i++)
		{
			language = nav[browserLanguagePropertyKeys[i]];
			if (language)
			{
				return language;
			}
		}

		return null;
	}


	///////////////////////////////////////////////////////////////////////////////
	// CTOR/INIT
	///////////////////////////////////////////////////////////////////////////////

	defaultLanguage = defaultLanguage || (allowLocalLanguage && (getBrowserLang() || '').toLowerCase()) || locDefaultFallback;
	//console.log('[loc] defaultLocale: ' + defaultLanguage + ' (orig=' + origDefaultLanguage + ')');
	this.setCurrLang();
};

// Market string container
glympse.Loc.Langs = [];
