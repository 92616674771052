glympse.Loc.Langs["fi"] =
glympse.Loc.Langs["fi_fi"] =
{
    locale: ["fi","fi_fi"],
    generated: "2014-09-07T02:17:29.9600952Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Kohde",
        M_EXPIRED: "Päättynyt",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Ryhmä",
        M_JOIN_GROUP: "Liity ryhmään",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "POIS",
        M_ON: "PÄÄLLE",
        M_SETTINGS: "Asetukset",
        M_UNITS: "Yksiköt",
        M_CLOSE: "Sulje",
        M_VIEW_MAP: "Näytä kartalla",
        M_SEND_GLYMPSE: "Lähetä Glympse",
        M_VIEW : "Näytä",
        M_MAP: "Kartta",
        M_AGO: "sitten",
        M_LAST_UPDATED: "Päivitetty",
        M_SPEED: "Nopeus",
        M_EXPIRES_IN: "Päättyy",
        M_ARRIVES_IN: "Saapuu",
        M_DAY: "päivä",
        M_DAYS: "päivää",
        M_HOUR: "h",
        M_HOURS: "h",
        M_LOADING: "Ladataan, odota hetki...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "ei koskaan",
        M_SECOND: "s",
        M_SECONDS: "s",
        M_REMAINING: "jäljellä",
        M_EXPIRED_GLYMPSE: "Glympse käyttäjältä %1$s päättyi %2$s sitten",
        M_EXPIRED_GLYMPSE_BTN: "Katso viimeisin päivitys",
        M_INVALID_GLYMPSE: "Glympse %1$s ei ole enää saatavilla",
        M_NO_LOCATION: "Haetaan käyttäjän %1$s sijaintia Odota hetki...",
        M_NO_MEMBERS: "Tällä hetkellä ryhmässä %1$s ei ole ketään Odotetaan Glympses...",
        M_NO_USERS: "Kukaan ei ole jakanut sijaintiaan aiheessa %1$s Odotetaan sijainteja...",
        M_WAITING_TWITTER: "Odotetaan, että %1$s jakaa sijaintinsa",
        M_REQUEST_GLYMPSE: "Pyydä käyttäjää %1$s jakamaan sijaintinsa",
        M_DOWNLOAD_APP: "Osta sovellus",
        M_LAUNCH_APP: "Katso sovelluksessa",
        M_GET_ADDR: "Haetaan osoitetta",
        M_SHARE_LOC: "Jaa sijaintisi!",
        M_STREET: "Katu",
        M_SATELLITE: "Satelliitti",
        M_TRAFFIC: "Liikenne",
        M_YOUR_LOCATION: "Sijaintisi",
        M_DEALS: "Tarjoukset",
        M_WEATHER: "Sää",
        M_HELP: "Ohje",
        M_FEEDBACK: "Palaute",
        M_DEBUG: "Vianetsintä",
        M_SUBMIT_A_BUG: "Lähetä virheilmoitus",
        M_SEND_MESSAGE: "Lähetä viesti",
        M_TAG: "Tunniste",
        M_ALTITUDE: "Korkeus",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Melkein perillä!",
            "Tarkista tämä!",
            "Matkalla!",
            "Nyt kotia kohti!",
            "Hengailen täällä tänä iltana!",
            "Olen täällä, missä sinä olet?",
            "Hei, katso missä olen!",
            "Tulen kohta sinne.",
            "Olen melkein siellä!",
            "Olen rakastunut sovellukseen Glympse!",
            "Unohdin salaatin, mutta tuon salamia.",
            "Olen varmaankin eksynyt.",
            "Lähistöllä...",
            "Juuri menossa sisään.",
            "Juuri lähdössä.",
            "Tavataan täällä.",
            "Tavataan täällä lounasaikaan.",
            "Tavataan, kun pääsen sinne.",
            "Tarvitsetko mitään täältä?",
            "Myöhässä :(",
            "Nähdään pian.",
            "Jumissa liikenteessä.",
            "Nyt on sinun vuorosi!",
            "Haluatko jotain syömistä?",
            "Katso minua."
        ],
        M_NEVER_LOCATION: "Henkilön %1$s sijainti ei ole käytettävissä",
        M_TERRAIN: "Maasto",
        M_DOWNLOAD_ACTIVE: "...jakaaksesi sijaintisi",
        M_DOWNLOAD_INACTIVE: "...pyytääksesi uuden Glympse",
        M_DOWNLOAD_APP_ALT: "Osta Glympse",
        M_HERE_DL_MOBILE: "Osta Glympse",
        M_HERE_DL_FULL: "Osta Glympse",
        M_NO_NAME: "Ystävä"
    }
};
