glympse.Loc.Langs["pt_pt"] =
glympse.Loc.Langs["pt_pt"] =
{
    locale: ["pt_pt","pt_pt"],
    generated: "2014-09-04T21:11:34.6262775Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destino",
        M_EXPIRED: "Expirado",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupo",
        M_JOIN_GROUP: "Juntar-se ao Grupo",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "DESLIGAR",
        M_ON: "LIGAR",
        M_SETTINGS: "Definições",
        M_UNITS: "Unidades",
        M_CLOSE: "Fechar",
        M_VIEW_MAP: "Ver Mapa",
        M_SEND_GLYMPSE: "Enviar Glympse",
        M_VIEW : "Ver",
        M_MAP: "Mapa",
        M_AGO: "atrás",
        M_LAST_UPDATED: "Atualizado",
        M_SPEED: "Velocidade",
        M_EXPIRES_IN: "Expira em ",
        M_ARRIVES_IN: "Chega em",
        M_DAY: "dia",
        M_DAYS: "dias",
        M_HOUR: "hora",
        M_HOURS: "horas",
        M_LOADING: "A carregar, por favor aguarde...",
        M_MINUTE: "minutos",
        M_MINUTES: "minutos",
        M_NEVER: "nunca",
        M_SECOND: "segundos",
        M_SECONDS: "segundos",
        M_REMAINING: "remanescentes",
        M_EXPIRED_GLYMPSE: "O Glympse de %1$s expirou há %2$s atrás",
        M_EXPIRED_GLYMPSE_BTN: "Ver Última Atualização",
        M_INVALID_GLYMPSE: "O Glympse %1$s já não está disponível",
        M_NO_LOCATION: "Obtendo a localização para %1$s Por favor, aguardar...",
        M_NO_MEMBERS: "Atualmente, não está ningúem no grupo %1$s Esperando por Glympses...",
        M_NO_USERS: "Ninguém está a partilhar no tópico %1$s Esperando por localizações...",
        M_WAITING_TWITTER: "Esperando que %1$s partilhem a sua localização ",
        M_REQUEST_GLYMPSE: "Pedido de %1$s para partilhar a tua localização",
        M_DOWNLOAD_APP: "Obter a Aplicação",
        M_LAUNCH_APP: "Ver na Aplicação",
        M_GET_ADDR: "A obter endereço",
        M_SHARE_LOC: "Partilha a tua localização!",
        M_STREET: "Rua",
        M_SATELLITE: "Satélite",
        M_TRAFFIC: "Trânsito",
        M_YOUR_LOCATION: "A tua localização",
        M_DEALS: "Ofertas",
        M_WEATHER: "Condições meteorológicas",
        M_HELP: "Ajuda",
        M_FEEDBACK: "Comentários",
        M_DEBUG: "Depurar",
        M_SUBMIT_A_BUG: "Submeter um bug",
        M_SEND_MESSAGE: "Enviar mensagem",
        M_TAG: "Tag",
        M_ALTITUDE: "Altitude",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Quase a chegar!",
            "Vê isto!",
            "Estou a caminho!",
            "Fui para casa agora!",
            "Aqui é onde eu estou esta noite!",
            "Eu estou aqui, onde estás tu?",
            "Ei, vê onde eu estou!",
            "Estarei lá em pouco tempo.",
            "Estou quase lá!",
            "Eu estou a apaixonar-me por Glympse!",
            "Esqueci-me da salada, mas estou a levar o salame.",
            "Acho que estou perdido.",
            "Na vizinhança...",
            "Acabando de chegar.",
            "Acabando de sair agora.",
            "Vamos encontrar-nos aqui.",
            "Encontra-te aqui comigo para almoçarmos.",
            "Encontra-te comigo quando eu chegar lá.",
            "Precisas de alguma coisa enquanto eu estou aqui?",
            "Vou chegar tarde :(",
            "Vemo-nos em pouco tempo.",
            "Parado(a) no trânsito.",
            "Apanhei-te, agora és tu!",
            "Queres comer qualquer coisa?",
            "Vê-me."
        ],
        M_NEVER_LOCATION: "A localização de %1$s não está disponível",
        M_TERRAIN: "Terreno",
        M_DOWNLOAD_ACTIVE: "...partilhe a sua localização",
        M_DOWNLOAD_INACTIVE: "...para pedir um novo Glympse",
        M_DOWNLOAD_APP_ALT: "Obter Glympse",
        M_HERE_DL_MOBILE: "Obter Glympse",
        M_HERE_DL_FULL: "Obter Glympse",
        M_NO_NAME: "Um(a) amigo\/a"
    }
};
