/**
 * SpriteSheetConfig - Config settings for a given sprite sheet
 */
(function(g, document)
{
	g.lib.SpriteSheet = function (cfg, path, img)
	{
		///////////////////////////////////////////////////////////////////////////////
		// FIELDS
		///////////////////////////////////////////////////////////////////////////////

		var loaded = (cfg && img);


		///////////////////////////////////////////////////////////////////////////////
		// PROPERTIES
		///////////////////////////////////////////////////////////////////////////////

		this.getCfg = function () { return cfg; };
		this.getImg = function () { return img; };
		this.getPath = function () { return path; };
		this.isLoaded = function () { return loaded; };

		this.setCfg = function (val) { cfg = val; };
		this.setImg = function (val) { img = val; };
		this.setPath = function (val) { path = val; };


		///////////////////////////////////////////////////////////////////////////////
		// PUBLICS
		///////////////////////////////////////////////////////////////////////////////

		this.toString = function ()
		{
			return "[SpriteSheet]: path=" + path + ", isLoaded=" + this.isLoaded();
		};

		this.load = function (callback, args)
		{
			function finishImageLoad()
			{
				img.onload = null;
				loaded = true;
				callback(args);
			}

			// Ensure we have the sprite sheet before continuing
			// FIXME: Find a better way to use CORS-based request of an img
			// ---> Have to change mime type to force the preflight request + include Origin in header
			// ---> Double load gets the image finally via cache
			// ---> Need to test via all browsers... this may not work properly --> IE seems to have issues
			var loc = window.location;
			var a = document.createElement("a");

			a.href = path;
			//console.log(">>>>>> Loading sprite: " + path);

			// IE7 always returns "" for a.hostname
			var doCors = ((a.hostname && a.hostname !== loc.hostname) || a.port !== loc.port || a.protocol !== loc.protocol);
			//console.log("DoCors=" + doCors);
			//console.log("req.hostname: " + a.hostname + ", win.hostname: " + loc.hostname + " -- " + cfg.ss)// &&
			//console.log("same domain: " + (a.hostname == loc.hostname && a.port == loc.port && a.protocol == loc.protocol));

			img = new Image();
			img.onload = finishImageLoad;

			if (doCors)
			{
				$(img).attr("crossOrigin", "");
				$.ajax({
					type: "get",
					url: path,
					contentType: "text/plain",
					//crossDomain: 'true',
					success: function ()
					{
						$(img).attr("src", path);
					},
					error: function ()
					{
						console.log("CORS load error");
						callback(args || this);
					}
				});
			}
			else
			{
				img.src = path;
			}
		};
	};

})(glympse, document);
