glympse.Loc.Langs["he"] =
glympse.Loc.Langs["he_il"] =
{
    locale: ["he","he_il"],
    generated: "2014-09-04T21:11:35.6714909Z",
    strings: {
        M_ETA: "זמן הגעה משוער",
        M_DESTINATION: "יעד",
        M_EXPIRED: "פג",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "קבוצה",
        M_JOIN_GROUP: "הצטרף לקבוצה",
        M_KMH: "קמ״ש",
        M_MPH: "מיילים לשעה",
        M_OFF: "כבה",
        M_ON: "הפעל",
        M_SETTINGS: "הגדרות",
        M_UNITS: "יחידות",
        M_CLOSE: "סגור",
        M_VIEW_MAP: "הצג מפה",
        M_SEND_GLYMPSE: "שלח Glympse",
        M_VIEW : "הצג",
        M_MAP: "מפה",
        M_AGO: "לפני",
        M_LAST_UPDATED: "עודכן",
        M_SPEED: "מהירות",
        M_EXPIRES_IN: "פג ב",
        M_ARRIVES_IN: "מגיע ב",
        M_DAY: "יום",
        M_DAYS: "ימים",
        M_HOUR: "שעה",
        M_HOURS: "שעות",
        M_LOADING: "טוען, המתן בבקשה...",
        M_MINUTE: "דקה",
        M_MINUTES: "דקות",
        M_NEVER: "לעולם",
        M_SECOND: "שניות",
        M_SECONDS: "שניות",
        M_REMAINING: "נותר",
        M_EXPIRED_GLYMPSE: "הGlympse מ%1$s פג לפני %2$s",
        M_EXPIRED_GLYMPSE_BTN: "הצג עדכון אחרון",
        M_INVALID_GLYMPSE: "Glympse %1$s כבר לא זמין",
        M_NO_LOCATION: "מקבל מיקום עבור %1$s המתן בבקשה...",
        M_NO_MEMBERS: "כרגע אף אחד בקבוצה %1$s לא מחכה לגלימפסים",
        M_NO_USERS: "אף אדם שמשתף בנושא %1$s לא מחכה למיקומים",
        M_WAITING_TWITTER: "מחכה ל%1$s שישתף\/תשתף את המיקום שלו\/שלה",
        M_REQUEST_GLYMPSE: "בקש מ%1$s שישתף\/תשתף את המיקום שלך",
        M_DOWNLOAD_APP: "הבא יישומון",
        M_LAUNCH_APP: "הצג באפליקציה",
        M_GET_ADDR: "מאחזר כתובת",
        M_SHARE_LOC: "שתף את המיקום שלך!",
        M_STREET: "רחוב",
        M_SATELLITE: "לווין",
        M_TRAFFIC: "תנועה",
        M_YOUR_LOCATION: "המיקום שלך",
        M_DEALS: "עסקאות",
        M_WEATHER: "מזג אויר",
        M_HELP: "עזרה",
        M_FEEDBACK: "משוב",
        M_DEBUG: "דיבאג",
        M_SUBMIT_A_BUG: "שלח באג",
        M_SEND_MESSAGE: "שלח הודעה",
        M_TAG: "תייג",
        M_ALTITUDE: "גובה",
        M_METERS: "מ'",
        M_FEET: "רגל",
        messages: [
            "כמעט הגעתי!",
            "תראה את זה!",
            "בדרך!",
            "בדרך הביתה עכשיו!",
            "לכאן אני יוצא\/ת הערב!",
            "הנה אני, איפה את\/ה?",
            "היי, תראה\/י איפה אני!",
            "אני כבר מגיע\/ה.",
            "כבר שם!",
            "אני מתאהב\/ת בגלימפס!",
            "שכחתי את הסלט, אבל אני מביא\/ה את הסלמי.",
            "אני חושב\/ת שהלכתי לאיבוד.",
            "בשכונה...",
            "בדיוק עושה צ׳ק אין.",
            "בדיוק עוזב\/ת עכשיו.",
            "בוא\/י ניפגש פה.",
            "תפגוש\/תפגשי אותי כאן לארוחת צהריים.",
            "תפגוש\/תפגשי אותי כשאני מגיע לשם.",
            "צריך\/ה משהו בזמן שאני כאן?",
            "מאחר\/ת: (",
            "נתראה עוד מעט",
            "תקוע\/ה בפקק.",
            "תופסת, אתה התופס!",
            "רוצה לאכול משהו?",
            "תראה\/י אותי."
        ],
        M_NEVER_LOCATION: "המיקום עבור %1$s לא זמין",
        M_TERRAIN: "קרקע",
        M_DOWNLOAD_ACTIVE: "לשיתוף מיקומך...",
        M_DOWNLOAD_INACTIVE: "לבקש Glympse חדש...",
        M_DOWNLOAD_APP_ALT: "הבא את Glympse",
        M_HERE_DL_MOBILE: "הבא את Glympse",
        M_HERE_DL_FULL: "הבא את Glympse",
        M_NO_NAME: "çáø"
    }
};
