glympse.Loc.Langs["ru"] =
glympse.Loc.Langs["ru_ru"] =
{
    locale: ["ru","ru_ru"],
    generated: "2014-09-04T21:11:34.7042785Z",
    strings: {
        M_ETA: "овп",
        M_DESTINATION: "Направление",
        M_EXPIRED: "Срок истек",
		M_CURRENT_LOCATION: "Текущее положение",
		M_CHECKIN: "Метка",
        M_GROUP: "Группа",
        M_JOIN_GROUP: "Присоединиться к группе",
        M_KMH: "км\/ч",
        M_MPH: "миль\/ч",
        M_OFF: "ВЫКЛ",
        M_ON: "ВКЛ",
        M_SETTINGS: "Настройки",
        M_UNITS: "Мера",
        M_CLOSE: "Закрыть",
        M_VIEW_MAP: "Просмотр карты",
        M_SEND_GLYMPSE: "Отправить Glympse",
        M_VIEW : "Просмотр",
        M_MAP: "Карта",
        M_AGO: "назад",
        M_LAST_UPDATED: "Обновлено",
        M_SPEED: "Скорость",
        M_EXPIRES_IN: "истекает через",
        M_ARRIVES_IN: "Прибывает через",
        M_DAY: "день",
        M_DAYS: "дня(ей)",
        M_HOUR: "ч",
        M_HOURS: "ч",
        M_LOADING: "Загрузка, пожалуйста, подождите...",
        M_MINUTE: "мин",
        M_MINUTES: "мин",
        M_NEVER: "никогда",
        M_SECOND: "сек",
        M_SECONDS: "сек",
        M_REMAINING: "остается",
        M_EXPIRED_GLYMPSE: " Glympse от %1$s истек %2$s назад",
        M_EXPIRED_GLYMPSE_BTN: "Просмотр последнего обновления",
        M_INVALID_GLYMPSE: " Glympse %1$s больше не доступен",
        M_NO_LOCATION: "Получение местонахождения для %1$s Пожалуйста, подождите...",
        M_NO_MEMBERS: "В группе %1$s пока никого нет Ожидание Glympses...",
        M_NO_USERS: "Никто не делится в теме %1$s Ожидание местоположений...",
        M_WAITING_TWITTER: "Ожидание, пока %1$s поделятся своим местоположением",
        M_REQUEST_GLYMPSE: "Запрос от %1$s с просьбой поделиться вашим местонахождением",
        M_DOWNLOAD_APP: "Скачать приложение",
        M_LAUNCH_APP: "Просмотр в приложении",
        M_GET_ADDR: "Извлечение адреса",
        M_SHARE_LOC: "Поделитесь своим местоположением!",
        M_STREET: "Улица",
        M_SATELLITE: "Спутник",
        M_TRAFFIC: "Дорожное движение",
        M_YOUR_LOCATION: "Ваше местоположение",
        M_DEALS: "Выгодные предложения",
        M_WEATHER: "Погода",
        M_HELP: "Справка",
        M_FEEDBACK: "Отзыв",
        M_DEBUG: "Отладка",
        M_SUBMIT_A_BUG: "Отправить отчет об ошибке",
        M_SEND_MESSAGE: "Отправить сообщение",
        M_TAG: "Ярлык",
        M_ALTITUDE: "Высота",
        M_METERS: "метров",
        M_FEET: "футов",
        messages: [
            "Почти готово!",
            "Посмотрите на это!",
            "Уже иду!",
            "Сейчас иду домой!",
            "Вот, где я буду тусоваться вечером!",
            "Вот и я, а где ты?",
            "Эй, посмотри, где я!",
            "Сейчас буду там.",
            "Уже почти там!",
            "Я влюбляюсь в Glympse!",
            "Я забыл про салат, но принесу колбасу.",
            "Я думал, что потерялся.",
            "В окрестностях...",
            "Просто вписываюсь.",
            "Уже ухожу.",
            "Давай встретимся здесь.",
            "Встретимся здесь и пообедаем.",
            "Встречай меня, когда доберусь туда.",
            "Нужно что-нибудь, пока я здесь?",
            "Опаздываю:(",
            "Увидимся через мгновение.",
            "Застрял в пробке.",
            "Ярлык, вот и ты!",
            "Хочешь поесть?",
            "Наблюдай за мной."
        ],
        M_NEVER_LOCATION: "Местоположение %1$s неизвестно",
        M_TERRAIN: "Тип местности",
        M_DOWNLOAD_ACTIVE: "...чтобы поделиться вашим местоположением",
        M_DOWNLOAD_INACTIVE: "...чтобы запросить новый Glympse",
        M_DOWNLOAD_APP_ALT: "Получить Glympse",
        M_HERE_DL_MOBILE: "Получить Glympse",
        M_HERE_DL_FULL: "Получить Glympse",
        M_NO_NAME: "Ваш друг"
    }
};
