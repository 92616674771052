glympse.Loc.Langs["nl"] =
glympse.Loc.Langs["nl_nl"] =
{
    locale: ["nl","nl_nl"],
    generated: "2014-09-07T02:17:30.1316974Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Bestemming ",
        M_EXPIRED: "Verlopen ",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Groep",
        M_JOIN_GROUP: "Deelnemen aan Groep ",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "UIT",
        M_ON: "ON",
        M_SETTINGS: "Instellingen ",
        M_UNITS: "eenheden",
        M_CLOSE: "Sluiten",
        M_VIEW_MAP: "Bekijk Kaart ",
        M_SEND_GLYMPSE: "Glympse zenden",
        M_VIEW : "Bekijk",
        M_MAP: "Kaart",
        M_AGO: "geleden",
        M_LAST_UPDATED: "Bijgewerkt ",
        M_SPEED: "Snelheid",
        M_EXPIRES_IN: "Verloopt in ",
        M_ARRIVES_IN: "Arriveert in ",
        M_DAY: "dag",
        M_DAYS: "dagen",
        M_HOUR: "uur",
        M_HOURS: "uren",
        M_LOADING: "Laden, even geduld... ",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "nooit",
        M_SECOND: "sec",
        M_SECONDS: "sec",
        M_REMAINING: "resterende ",
        M_EXPIRED_GLYMPSE: "De Glympse van %1$s verliep %2$s geleden",
        M_EXPIRED_GLYMPSE_BTN: "Bekijk Laatste update",
        M_INVALID_GLYMPSE: "De Glympse %1$s is niet meer beschikbaar",
        M_NO_LOCATION: "Verkrijgt locatie van %1$s een ogenblik geduld...",
        M_NO_MEMBERS: "Niemand momenteel in groep %1$s Wachten op Glympses...",
        M_NO_USERS: "Niemand deelt in topic %1$s Wachten op locaties...",
        M_WAITING_TWITTER: "Wachten op %1$s tot zij hun locatie delen ",
        M_REQUEST_GLYMPSE: "Verzoek van %1$s om uw locatie te delen ",
        M_DOWNLOAD_APP: "Krijg App",
        M_LAUNCH_APP: "Bekijk in App",
        M_GET_ADDR: "Adres Ophalen",
        M_SHARE_LOC: "Deel uw locatie! ",
        M_STREET: "Straat",
        M_SATELLITE: "Satelliet ",
        M_TRAFFIC: "Verkeer",
        M_YOUR_LOCATION: "Uw locatie ",
        M_DEALS: "Aanbiedingen ",
        M_WEATHER: "Weer",
        M_HELP: "Hulp",
        M_FEEDBACK: "Feedback",
        M_DEBUG: "Debuggen ",
        M_SUBMIT_A_BUG: "Meld een bug ",
        M_SEND_MESSAGE: "Stuur bericht",
        M_TAG: "Tikkertje",
        M_ALTITUDE: "Hoogte",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "Bijna daar!",
            "Bekijk dit eens!",
            "Ik ben onderweg!",
            "Ik ben onderweg naar huis!",
            "Hier is waar ik vanavond uithang!",
            "Hier ben ik, waar ben jij?",
            "Hey, kijk eens waar ik ben!",
            "Ik kom er zo aan.",
            "Ik ben er bijna!",
            "Ik ben verliefd op Glympse!",
            "Ik vergat de salade, maar ik breng de salami.",
            "Ik denk dat ik verdwaald ben.",
            "In de buurt...",
            "Gewoon even laten weten waar ik ben.",
            "Ik vertrek nu pas.",
            "Laten we hier afspreken.",
            "Ontmoet me hier voor lunch.",
            "Ontmoet me als ik er ben.",
            "Heb je iets nodig terwijl ik hier ben?",
            "Ik kom iets te laat :(",
            "Ik zie je zo.",
            "Vast in het verkeer.",
            "Tikkie, jij bent hem!",
            "Wil je iets te eten?",
            "Let op mij."
        ],
        M_NEVER_LOCATION: "Locatie voor %1$s is niet beschikbaar",
        M_TERRAIN: "Gebied",
        M_DOWNLOAD_ACTIVE: "...om uw locatie te delen",
        M_DOWNLOAD_INACTIVE: "...om een nieuwe Glympse aan te vragen",
        M_DOWNLOAD_APP_ALT: "Krijg Glympse",
        M_HERE_DL_MOBILE: "Krijg Glympse",
        M_HERE_DL_FULL: "Krijg Glympse",
        M_NO_NAME: "Een vriend"
    }
};
