(function (g)
{
	g.lib.StatTracker = function (label, scale, minValClamp, cntDecimals)
	{
		// state
		var min = null;
		var max = null;
		var samples = 0;
		var total = 0;

		scale = (scale || 1);
		label = (label || "");
		cntDecimals = (cntDecimals == null || cntDecimals < 0) ? 1 : cntDecimals;


		///////////////////////////////////////////////////////////////////////////////
		// PROPERTIES
		///////////////////////////////////////////////////////////////////////////////

		this.getAvg = function () { return (samples > 0) ? (total / samples) : 0; };
		this.getMax = function () { return max; };
		this.getMin = function () { return min; };


		///////////////////////////////////////////////////////////////////////////////
		// PUBLICS
		///////////////////////////////////////////////////////////////////////////////

		this.add = function (val)
		{
			if (val == null) return;

			if (minValClamp != null) val = Math.max(minValClamp, val);

			if (min == null || min > val) min = val;
			if (max == null || max < val) max = val;

			samples++;
			total += val;
		};

		this.toString = function ()
		{
			return "avg=" + (scale * this.getAvg()).toFixed(cntDecimals) + label
				 + ", min=" + ((this.getMin() == null) ? "???" : (scale * this.getMin()).toFixed(cntDecimals)) + label
				 + ", max=" + ((this.getMax() == null) ? "???" : (scale * this.getMax()).toFixed(cntDecimals)) + label
			;
		};

		this.toShort = function (fixedPrecision)
		{
			return this.getAvg().toFixed(fixedPrecision || cntDecimals)
				 + "," + ((this.getMin() == null) ? "???" : this.getMin().toFixed(fixedPrecision || cntDecimals))
				 + "," + ((this.getMax() == null) ? "???" : this.getMax().toFixed(fixedPrecision || cntDecimals))
			;
		};
	};
})(glympse);
