glympse.IMG["ss10"] = {
    "meta": {"name":"ss10.png","w":223,"h":201}
    , "airlineActive": {"x":63,"y":98,"w":35,"h":45}
    , "airlineColor": {"x":137,"y":98,"w":35,"h":45}
    , "airlineExpired": {"x":100,"y":98,"w":35,"h":45}
    , "btnSlide": {"x":1,"y":160,"w":40,"h":40}
    , "check": {"x":203,"y":63,"w":18,"h":16}
    , "checkin": {"x":166,"y":67,"w":21,"h":21}
    , "checkinDest": {"x":126,"y":160,"w":24,"h":24}
    , "chevDown": {"x":203,"y":93,"w":18,"h":10}
    , "chevUp": {"x":203,"y":81,"w":18,"h":10}
    , "dest": {"x":174,"y":98,"w":25,"h":31}
    , "info": {"x":203,"y":22,"w":19,"h":19}
    , "infoHit": {"x":203,"y":1,"w":19,"h":19}
    , "layers": {"x":99,"y":76,"w":20,"h":20}
    , "lock": {"x":152,"y":160,"w":18,"h":21}
    , "logo": {"x":1,"y":1,"w":134,"h":33}
    , "markerActive": {"x":73,"y":160,"w":28,"h":36}
    , "markerColor": {"x":95,"y":36,"w":28,"h":36}
    , "markerExpired": {"x":43,"y":160,"w":28,"h":36}
    , "markerHalo": {"x":137,"y":1,"w":64,"h":64}
    , "markerMap": {"x":103,"y":160,"w":21,"h":26}
    , "minus": {"x":203,"y":105,"w":18,"h":6}
    , "myIcon": {"x":77,"y":76,"w":20,"h":20}
    , "nubCenter": {"x":203,"y":113,"w":9,"h":10}
    , "plus": {"x":203,"y":43,"w":18,"h":18}
    , "pointerLeft": {"x":137,"y":67,"w":27,"h":23}
    , "tabBg": {"x":1,"y":36,"w":92,"h":38}
    , "unknown": {"x":1,"y":98,"w":60,"h":60}
    , "unlock": {"x":172,"y":160,"w":17,"h":21}
    , "watermark": {"x":1,"y":76,"w":74,"h":20}
};
