// Slovene or Slovenian - 09/13/2012
glympse.Loc.Langs["sl"] =
{
	strings:
	{
		M_ETA: "eta",
		M_DESTINATION: "Destinacija",
		M_EXPIRED: "Poteklo",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
		M_GROUP: "Skupina",
		M_JOIN_GROUP: "Pridružite skupini",
		M_KMH: "km\/h",
		M_MPH: "mph",
		M_SETTINGS: "Nastavitve",
		M_VIEW_MAP: "Prikažite zemljevid",
		M_SEND_GLYMPSE: "Pošlji Glympse",
		M_VIEW: "Pogled",
		M_MAP: "Karta",
		M_AGO: "nazaj",
		M_LAST_UPDATED: "Nazadnje posodobljeno:",
		M_SPEED: "Hitrost",
		M_EXPIRES_IN: "Poteče:",
		M_ARRIVES_IN: "Prihod",
		M_DAY: "dan",
		M_DAYS: "dni",
		M_HOUR: "h",
		M_HOURS: "h",
		M_LOADING: "Nalaga, prosimo počakajte...",
		M_MINUTE: "min",
		M_MINUTES: "min",
		M_NEVER: "nikoli",
		M_SECOND: "sek",
		M_SECONDS: "sek",
		M_REMAINING: "ostal",
		M_EXPIRED_GLYMPSE: "Glympse od %1$s poteklo %2$s nazaj",
		M_EXPIRED_GLYMPSE_BTN: "Ogled Zadnja sprememba",
		M_INVALID_GLYMPSE: "Glympse %1$s ni več na voljo",
		M_NO_LOCATION: "Kako lokacijo za %1$s Počakaj...",
		M_NEVER_LOCATION: "Location for %1$s not available",
		M_NO_MEMBERS: "Nihče je trenutno v skupini %1$s Čakam Glympses...",
		M_NO_USERS: "Delitev nihče temo %1$s Čakam na lokacijah...",
		M_WAITING_TWITTER: "Čakam na %1$s deliti svojo lokacijo",
		M_REQUEST_GLYMPSE: "Zahteva %1$s deliti svojo lokacijo",
		M_DOWNLOAD_APP: "Prijava Glympse",
		M_LAUNCH_APP: "LZačetek  Glympse",
		M_GET_ADDR: "Pridobivanje naslov",
		M_SHARE_LOC: "Deliti svojo lokacijo!",
		M_STREET: "Ulica",
		M_SATELLITE: "Satelit",
		M_TRAFFIC: "Prometa",
		M_YOUR_LOCATION: "Vaša lokacija",
		M_DEALS: "Ponudba",
		M_WEATHER: "Vreme",
		M_HELP: "Pomaga",
		M_FEEDBACK: "Povratne",
		M_DEBUG: "Odpravljati napak",
		M_SUBMIT_A_BUG: "Predloži napako",
		M_SEND_MESSAGE: "Pošlji sporočilo",
		M_TAG: "Oznako",
		messages: [
			"Skoraj sem tam!",
			"Poglej si tole!",
			"Na moji poti!",
			"Grem zdaj domov!"
        ],
        M_AV_DONT_ASK: "Don't ask me again",
        M_AV_TITLE: "Automatically viewing in Glympse:",
        M_CONTINUE: "Continue",
        M_CANCEL: "Cancel"
	}
};
