glympse.IMG["ss-alt10"] = {
    "meta": {"name":"ss-alt10.png","w":248,"h":212}
    , "airlineActive": {"x":90,"y":135,"w":35,"h":45}
    , "airlineColor": {"x":127,"y":135,"w":35,"h":45}
    , "airlineExpired": {"x":53,"y":135,"w":35,"h":45}
    , "dest": {"x":219,"y":95,"w":23,"h":36}
    , "h_check": {"x":27,"y":187,"w":15,"h":15}
    , "h_checkin": {"x":130,"y":73,"w":21,"h":21}
    , "h_checkinDest": {"x":1,"y":187,"w":24,"h":24}
    , "h_chevDown": {"x":115,"y":101,"w":18,"h":10}
    , "h_chevUp": {"x":95,"y":101,"w":18,"h":10}
    , "h_layers": {"x":77,"y":113,"w":20,"h":20}
    , "h_lock": {"x":194,"y":154,"w":14,"h":16}
    , "h_logo": {"x":1,"y":37,"w":150,"h":34}
    , "h_logo_white": {"x":1,"y":1,"w":150,"h":34}
    , "h_markerHalo": {"x":153,"y":1,"w":64,"h":64}
    , "h_minus": {"x":153,"y":129,"w":17,"h":4}
    , "h_myIcon": {"x":99,"y":113,"w":20,"h":20}
    , "h_nubCenter": {"x":135,"y":101,"w":9,"h":10}
    , "h_plus": {"x":194,"y":135,"w":17,"h":17}
    , "h_pointerLeft": {"x":219,"y":133,"w":28,"h":24}
    , "h_tabBg": {"x":1,"y":73,"w":92,"h":38}
    , "h_unlock": {"x":121,"y":113,"w":18,"h":17}
    , "h_watermark": {"x":1,"y":113,"w":74,"h":20}
    , "here": {"x":1,"y":135,"w":50,"h":50}
    , "hereBlue": {"x":95,"y":73,"w":33,"h":26}
    , "markerActive": {"x":219,"y":1,"w":28,"h":45}
    , "markerColor": {"x":164,"y":135,"w":28,"h":45}
    , "markerExpired": {"x":219,"y":48,"w":28,"h":45}
    , "markerMap": {"x":219,"y":159,"w":21,"h":26}
    , "unknown": {"x":153,"y":67,"w":60,"h":60}
};
