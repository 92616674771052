glympse.defaults =
{
	  'allowLocalLang': true
	, 'allowSlowAnimation': false
	, 'altUi': false
	, 'areas': [ 'default' ]
	, 'attributionOffset': 0
	, 'autoCenterOnAll': true
	, 'autolockOnStart': true
	, 'autoSize': true
	, 'balloonColor': 'rgba(35,31,32,0.9)'
	, 'balloonMode': 0
	, 'balloonRadius': 4
	, 'bgColor': '0xFFFFFF'
	, 'bgColorMap': '0xFFFFFF'
	, 'botDestRadius': 0
	, 'botDestShuffle': true
	, 'botDestStop': false
	, 'botStartOffset': 0.25
	, 'botTimeScale': 1.0
 	, 'broadcast': null
	, 'currLang': 'en'
	, 'dbgPaddings': false
	, 'delayedPointsInterval': 7000
	, 'animatePointMaxInterval': 10000
	, 'destBalloon': {
		color: 'rgba(34,31,31,0.9)',
		radius: 4,
		textColor: '#F7F7F7',
		textSize: 12
		,nub: [4, 6]
		//,shadow: {
		//	blur: 5,
		//	offset: [1, 1],
		//	color: 'rgba(0,0,0,0.84)'
		//}
	}
	, 'destIcon': null
	, 'destIgnore': false
	, 'destMode': 0
	, 'disableAddressLookup': false
	, 'disableFrameUpdate': false
	, 'disableRafTimeout': false
	, 'disableScaling': false
	, 'disableSummary': false
	, 'etaColor': null
	, 'etaDisableQuery': false
	, 'etaTrailMode': 'active'	// 'all', 'none' (undefined = 'active')
	, 'etaTrailOptions': {
		color: null,
		dashes: [ 1, 12 ],
		dashOffset: 0,
		lineWidth: 5,
		alpha: 0.70,
		lineCap: 'round',	// butt
		lineJoin: 'round'	// miter
	}
	, 'etaUpdateInterval': 30000
	, 'expiredPeriodRemove': 0
	, 'forcedDestName': null
	, 'forceFrameUpdate': false
	, 'fuzzRatio': 0
	, 'fuzzSettings': {
		defaultRadius: 1609,
		error: 0.25,
		gfx: {
			path: undefined,	// set to null to disable icon
			opacity: 0.75,
			scale: 0.1
		},
		hideDistance: 1609,
		minSize: 15,
		mode: null,
		noAnimations: false,
		positionDuration: 2.0,
		pulse: {
			duration: 3.0,
			lower: 0.9,
			upper: 1.1
		},
		reduceOnUserEnter: false,
		reduceRatio: 0,
		styleFixed: {
			fillColor: 'rgba(42,42,41, 0.333)',
			strokeWeight: 1,
			strokeColor: 'rgba(42,42,42, 0.466)'
		},
		styleFixedHere: {
			fillColor: 'rgba(42,42,41, 0.333)',
			lineWidth: 1,
			strokeColor: 'rgba(42,42,42, 0.466)'
		},
		stylePulse: {
			fillColor: 'rgba(42,42,41, 0.25)',
			strokeWeight: 0,
			strokeColor: 'rgba(42,42,42, 0.25)'
		},
		stylePulseHere: {
			fillColor: 'rgba(42,42,41, 0.25)',
			lineWidth: 0,
			strokeColor: 'rgba(42,42,42, 0.25)'
		}
	}
	, 'haloActive': false
	, 'hideApp': false
	, 'hideDialog': 0
	, 'hideExpiredDestination': false
	, 'hideWatermark': false
	, 'hitDebug': false
	, 'includeEtaTrail': true
	, 'includeTrail': false
	, 'mapLocked': false
	, 'mapLockUpdatesIgnore': true
	, 'mapType': null//'normal'
	, 'nodl': false
	//, 'noEtaTrail': false
	, 'noUpdates': false
	, 'padding': 'default'
	, 'scaleSettings': {
		power: 1,	// 0: linear, 1: quad, 2: cubic, 3: quart, 4: quint
		scaleMax: 1.0,
		scaleMin: 0.5,
		zoomMax: 18,
		zoomMin: 5
	}
	, 'services': 'https://api.glympse.com/v2/'
	, 'sharePath': 'https://glympse.com/'
	, 'showAltitude': false
	, 'showControls': true
	//, 'showEta': true
	, 'showFullView': true
	, 'showFuzzUserIcon': false
	, 'showHeader': true
	, 'showHere': false
	, 'showShadows': 255
	, 'trailLength': 600
	, 'trailLengthAirline': 0
	, 'trailLengthBots': 300
	, 'trailLineWidth': 5
	, 'useBatch': false
	, 'userIcon': null
	, 'userIconAvatar': 0
	, 'useStartNub': false
	, 'watermarkBreakpoint': 320
	, 'zoomMax': -1
};
