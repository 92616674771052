(function (g)
{
	var lib = g.lib;

	lib.GeoJsonUtils = {
		getFeaturesByType: function(jsonDoc, type) {
			return jsonDoc.features.filter(function (feat) {
				return feat.geometry.type === type;
			});
		},

		getFeatureCoordinates: function(feature) {
			return feature.geometry.coordinates;
		},

		arrayToObjCoords: function (coords) {
			if (Array.isArray(coords[0])) {
				return coords.map(function (c) {
					return {
						lng: c[0],
						lat: c[1]
					}
				})
			} else {
				return {
					lng: coords[0],
					lat: coords[1]
				}
			}
		}
	}
})(glympse);
