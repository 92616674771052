glympse.Loc.Langs["es"] =
glympse.Loc.Langs["es_la"] =
{
    locale: ["es","es_la"],
    generated: "2014-09-07T02:17:28.6496784Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destino",
        M_EXPIRED: "Expiró",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupo",
        M_JOIN_GROUP: "Unirse a grupo",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "OFF",
        M_ON: "ON",
        M_SETTINGS: "Ajustes",
        M_UNITS: "Unidades",
        M_CLOSE: "Cerrar",
        M_VIEW_MAP: "Ver Mapa",
        M_SEND_GLYMPSE: "Enviar Glympse",
        M_VIEW : "Ver",
        M_MAP: "Mapa",
        M_AGO: "hace",
        M_LAST_UPDATED: "Actualizado",
        M_SPEED: "Velocidad",
        M_EXPIRES_IN: "Expira en",
        M_ARRIVES_IN: "Llega en",
        M_DAY: "día",
        M_DAYS: "días",
        M_HOUR: "hr",
        M_HOURS: "hrs",
        M_LOADING: "Cargando, por favor espera...",
        M_MINUTE: "min",
        M_MINUTES: "mins",
        M_NEVER: "nunca",
        M_SECOND: "seg.",
        M_SECONDS: "seg.",
        M_REMAINING: "restante",
        M_EXPIRED_GLYMPSE: "El Glympse de %1$s expiró hace %2$s",
        M_EXPIRED_GLYMPSE_BTN: "Ver última actualización",
        M_INVALID_GLYMPSE: "El Glympse %1$s ya no está disponible",
        M_NO_LOCATION: "Obteniendo ubicación para %1$s Por favor espera...",
        M_NO_MEMBERS: "Actualmente no hay nadie del grupo %1$s En espera de Glympses...",
        M_NO_USERS: "Nadie compartiendo en el tema %1$s En espera de ubicaciones...",
        M_WAITING_TWITTER: "Esperando que %1$s comparta su ubicación",
        M_REQUEST_GLYMPSE: "Solicitud de %1$s para compartir tu ubicación",
        M_DOWNLOAD_APP: "Obtén la aplicación.",
        M_LAUNCH_APP: "Ver en aplicación",
        M_GET_ADDR: "Obteniendo dirección",
        M_SHARE_LOC: "¡Comparte tu ubicación!",
        M_STREET: "Calle",
        M_SATELLITE: "Satélite",
        M_TRAFFIC: "Tráfico",
        M_YOUR_LOCATION: "Tu ubicación",
        M_DEALS: "Ofertas",
        M_WEATHER: "Clima",
        M_HELP: "Ayuda",
        M_FEEDBACK: "Comentarios",
        M_DEBUG: "Depurar",
        M_SUBMIT_A_BUG: "Reportar error",
        M_SEND_MESSAGE: "Enviar mensaje",
        M_TAG: "Etiqueta",
        M_ALTITUDE: "Altitud",
        M_METERS: "m",
        M_FEET: "ft",
        messages: [
            "¡Casi llego!",
            "¡Mira esto!",
            "¡Voy en camino!",
            "¡De camino a casa ahora!",
            "¡Aquí pasaré el rato esta noche!",
            "Estoy aquí, ¿dónde estás tú?",
            "¡Oye, mira dónde estoy!",
            "No tardo.",
            "¡Casi llego!",
            "¡Me estoy enamorando de Glympse!",
            "Se me olvidó la ensalada, pero llevo el salami.",
            "Creo que me perdí.",
            "En el vecindario...",
            "Solo reportándome.",
            "Voy de salida.",
            "Veámonos ahí.",
            "Encontrarnos aquí para el almuerzo.",
            "Te veo ahí cuando llegue.",
            "¿Necesitas algo mientras estoy aquí?",
            "Voy tarde :(",
            "Te veo en un rato.",
            "Atorado en el tráfico.",
            "¡Te toqué, tú la llevas!",
            "¿Quieres algo de comer?",
            "Mírame."
        ],
        M_NEVER_LOCATION: "La ubicación de %1$s no está disponible.",
        M_TERRAIN: "Vista de terreno",
        M_DOWNLOAD_ACTIVE: "...para compartir tu ubicación.",
        M_DOWNLOAD_INACTIVE: "...para solicitar un Glympse nuevo.",
        M_DOWNLOAD_APP_ALT: "Obtén Glympse.",
        M_HERE_DL_MOBILE: "Obtén Glympse.",
        M_HERE_DL_FULL: "Obtén Glympse.",
        M_NO_NAME: "Un Amigo"
    }
};
