(function(g, window, document, undefined)
{
	var CODE_ASPECTS = [ 'ticket_invite',
						 'ticket_request',
						 'group_request',
						 'card' ];

	var BASE32_DECODE = [
		0, 1, 2, 3, 4, 5, 6, 7, 8, 9,           // 0 1 2 3 4 5 6 7 8 9
		-1, -1, -1, -1, -1, -1, -1,             // : ; < = > ? @
		10, 11, 12, 13, 14, 15, 16, 17, 1,     // A B C D E F G H I
		18, 19, 1, 20, 21, 0, 22, 23, 24,      // J K L M N O P Q R
		25, 26, 27, 27, 28, 29, 30, 31,        // S T U V W X Y Z
		-1, -1, -1, -1, -1, -1,                // [ \ ] ^ _ `
		10, 11, 12, 13, 14, 15, 16, 17, 1,     //a b c d e f g h i
		18, 19, 1, 20, 21, 0, 22, 23, 24,      // j k l m n o p q r
		25, 26, 27, 27, 28, 29, 30, 31         // s t u v w x y z
	];

	var defCfg = '__glconf';

	// Local aliases
	var decodeURIComponent = window.decodeURIComponent;
	var localStorage = window.localStorage;

	var lib =
	{
		// Basic shuffle algorithm on a given array
		shuffle: function (items)
		{
			if (!items) return;

			var i, j, len, tmp;

			for (i = 0, len = items.length; i < len; i++)
			{
				j = i + Math.floor(Math.random() * (len - i));
				tmp = items[i];
				items[i] = items[j];
				items[j] = tmp;
			}
		}

		/**
		 * Invite codes use a [Crockford-style](https://www.crockford.com/wrmg/base32.html) base32 encoding
		 * where the letter O and the number 0 are equivalent, with the canonical form being 0.
		 * Since various layers of applications may decode and re-encode these
		 * any explicit string comparisons will need to allow for this.
		 *
		 * @param sourceString {string}
		 * @param targetString {string}
		 * @returns {boolean}
		 */
		, base32ContainsString: function (sourceString, targetString) {
			function canonicalize(s)
			{
				var equivalent = { 'I': '1', 'L': '1', 'O': '0', 'U': 'V' };
				var canonical = '0123456789ABCDEFGHJKMNPQRSTVWXYZ'.split('');
				return s.toUpperCase().split('')
					.map(function (c) { return equivalent[c] || c; })           // choose canonical equivalent for each character
					.filter(function (c) { return canonical.indexOf(c) >= 0; }) // remove non-canonical characters
					.join('');                                                  // join characters back together into string
			}
			return canonicalize(sourceString).indexOf(canonicalize(targetString)) >= 0;
		}

		/**
		 * Checks if provided invite code is client-generated
		 * @param inviteCode {string}
		 * @returns {boolean}
		 */
		, isDemobotInvite: function (inviteCode)
		{
			return lib.base32ContainsString(inviteCode, 'demobot');
		}

		, decodeCode: function (code)
		{
			/********
			Glympse invite-code format
			-----------------------------------------------------
			| 39 - 37      | 36 - 35     | 34      | 33 - 0     |
			-----------------------------------------------------
			| Reserved     | Invite Type | Version | Identifier |
			| (datacenter) | (aspect)    | (0)     |            |
			-----------------------------------------------------

			Aspects:
			00: ticket_invite,  01: ticket_request
			10: group_invite,   11: card_invite

			Datacenters:
			000: Glympse - US
			001: Glympse - Europe

			***********/
			var i, ch, r = {
				aspect: 'invalid',
				datacenter: -1,
				version: -1,
				identifier: -1
			};

			var len = (code && code.length) || 0;
			if (len < 6 || len > 9)
			{
				return r;
			}

			if (len < 8)
			{
				// Old-school invite codes = zeroed-out expansion bits
				r.datacenter = 0;
				r.version = 0;
				r.aspect = CODE_ASPECTS[0];
				return r;
			}

			// Pull out high-order bit info
			var result = 0;
			for (i = 0; i < 2; i++)
			{
				if ('-' === code[i])
				{
					continue;
				}

				var val = code.charCodeAt(i) - 48;
				var cur = (val >= 0 && val <= 74) ? BASE32_DECODE[val] : -1;

				if (cur < 0)
				{
					r.aspect = 'invalid_char';
					return r;
				}

				result = (result << 5) + cur;
			}

			r.version = ((result >> 4) & 1);
			r.datacenter = ((result >> 7) & 7);
			r.aspect = CODE_ASPECTS[((result >> 5) & 3)];

			return r;
		}
		, colorVal: function (clr) { return ((clr instanceof Number) || (typeof clr === "number")) ? clr : Number("0x" + g.lib.cleanColor(clr)); }
		, colorCss: function (clr)
		{
			var out;
			if (clr !== null && ((clr instanceof Number) || (typeof clr === "number")))
			{
				out = clr.toString(16);
				out = "000000".substr(0, 6 - out.length) + out;
			}
			else
			{
				out = g.lib.cleanColor(clr);
			}

			return "#" + out;
		}
		, cleanColor: function (clr) { return (!clr) ? "000000" : clr.replace("0x", "").replace("#", ""); }
		, toRgba: function (rgb, a, scale)
		{
			// Just bail if we're already in rgb(a) format
			if (rgb.indexOf('rgb') >= 0)
			{
				return rgb;
			}

			return "rgba(" + Math.round(parseInt(rgb.substring(1, 3), 16) * scale)
					+ "," + Math.round(parseInt(rgb.substring(3, 5), 16) * scale)
					+ "," + Math.round(parseInt(rgb.substring(5, 7), 16) * scale)
					+ "," + a
					+ ")";
		}
		, computeBackoff: function (retryCount)
		{
			return retryCount * (500 + Math.round(1000 * Math.random()));	// Incremental + random offset delay between retry in case of short availability outage
		}
		, logException: function (err, stack, tag)
		{
			var callee = (stack && stack.callee);
			var caller = (callee && callee.caller && callee.caller.toString());
			console.log("[[EXCEPTION" + ((tag) ? ("-" + tag) : "") + "]]:" + err + "\n" + ((err && err.stack) ? err.stack : (callee + "\ncaller=" + caller + "\nNO_STACK")));
		}
		, isImperial: function ()
		{
			var lang = "??";
			try
			{
				var n = navigator;
				lang = (n.language ? n.language : (n.userLanguage || n.systemLanguage || n.browserLanguage)) || '';
				var langMatches = (lang) ? lang.match(/\w{2}/g) : null;
				var country = ((langMatches && langMatches.length > 0) ? langMatches[langMatches.length - 1] : "us").toLowerCase();	// Grab the last match regardless of a locale like "en-US" or simply "de"

				//console.log("country = " + country);
				return (country === "us"
					 || country === "mm"
					 || country === "lr"
					 || country === "en"	// Some Android devices only have "en" for their navigator.language, so default to mph as US > world on viewers right now
					   );
			}
			catch (e)
			{
				g.lib.logException(e, arguments, "[lib.isImperial() - lang=" + lang + "]");
				return true;	// Default to imperial
			}
		}
		, initTracking: function (gaTrackingId)
		{
			if (typeof window.gtag === 'undefined' && gaTrackingId) {
				// Global Site Tag (gtag.js) - Google Analytics
				const script = document.createElement("script");
				script.async = true;
				script.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;
				document.body.appendChild(script);

				window.dataLayer = window.dataLayer || [];
				window.gtag = function gtag() {
					window.dataLayer.push(arguments);
				};
			}
		}
		, hasGtag() {
			return !!window.gtag;
		}
		, hasGtm() {
			return !this.hasGtag() && !!window.dataLayer;
		}
		, gaSet: function (field, value)
		{
			// Set a ga field value
			if (this.hasGtag()) {
				window.gtag("set", {field:  value});
			}
		}
		, trackAction: function (category, action)
		{
			if (this.hasGtag()) {
				window.gtag('event', action, {
					category: category
				});
			}
			if (this.hasGtm()) {
				window.dataLayer.push({
					event: action,
					category: category
				})
			}
		}
		, trackLabel: function (category, action, label)
		{

			if (this.hasGtag()) {
				window.gtag('event', action, {
					category: category,
					label: label
				});
			}
			if (this.hasGtm()) {
				window.dataLayer.push({
					event: action,
					category: category,
					label: label
				})
			}
		}
		, trackValue: function (category, action, label, value)
		{
			if (this.hasGtag()) {
				window.gtag('event', action, {
					category: category,
					label: label,
					value: value
				});
			}
			if (this.hasGtm()) {
				window.dataLayer.push({
					event: action,
					category: category,
					label: label,
					value: value
				})
			}
		}
		, decodeShortGuid: function (shortGuid, spacer)
		{
			spacer || (spacer = "-");
			//console.log("short=" + shortGuid);
			var s = shortGuid.replace(/-/g, '+').replace(/_/g, '/') + "==";
			var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, len = s.length;
			var markers = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
			for (i = 0; i < 64; i++) { e[markers.charAt(i)] = i; }
			for (x = 0; x < len; x++)
			{
				c = e[s.charAt(x)];
				/*jslint bitwise: true */
				b = (b << 6) + c;
				/*jslint bitwise: false */
				l += 6;
				while (l >= 8)
				{
					/*jslint bitwise: true */
					((a = (b >>> (l -= 8)) & 0xff) || (x < (len - 2))) && (r += w(a));
					/*jslint bitwise: false */
				}
			}

			// Flip endian-ness
			function gc(idx) { return ("0" + r.charCodeAt(idx).toString(16)).substr(-2); }
			var guid = gc(3) + gc(2) + gc(1) + gc(0) + spacer +
					   gc(5) + gc(4) + spacer +
					   gc(7) + gc(6) + spacer +
					   gc(8) + gc(9) + spacer +
					   gc(10) + gc(11) + gc(12) + gc(13) + gc(14) + gc(15);

			return guid;
		}
		, defaultDownload: function (cfg)
		{
			cfg.cantLaunch = true;
			//TODO: Do we still need this?
			cfg.callbackDownload = function () { window.open(g.uiTheme.application.defaultDownloadLink, "_blank"); };
		}
		, getAirline: function (codeIcao, codeIata)
		{
			if (codeIcao) codeIcao = codeIcao.toUpperCase();
			if (codeIata) codeIata = codeIata.toUpperCase();

			var airline;
			var airlines = g.data.airlines;

			if (codeIcao && airlines[codeIcao]) { airline = airlines[codeIcao]; airline.id = codeIcao; return airline; }
			if (codeIata && airlines[codeIata]) { airline = airlines[codeIata]; airline.id = codeIcao; return airline; }

			for (var icao in airlines)
			{
				airline = airlines[icao];
				if (codeIcao && airline.alt === codeIcao) { airline.id = icao; return airline; }
				if (codeIata && airline.alt === codeIata) { airline.id = icao; return airline; }
			}

			return null;
		}
		// HERE-specific
		, createDefaultLayers: function(provider, tileSize, dpi)
		{
			var mapTypes = {};
			var provTypes = {};
			var types = ["normal", "satellite", "terrain"];
			var variantTraffic = "traffic";
			var variantMap = "map";
			if (provider.hasTraffic) types.push(variantTraffic);

			for (var i = 0; i < types.length; i++)
			{
				//var mapTypes = {mapTypes[type][variant];}
				var type = types[i];
				var variants = {};
				var prov = new provider(type, tileSize, dpi);
				var itProv = new H.map.provider.ImageTileProvider(prov);
				itProv.getCopyrights = prov.getCopyrights;
				variants[variantMap] = new H.map.layer.TileLayer(itProv);
				variants[variantTraffic] = null;//variants[variantMap];
				mapTypes[type] = variants;
				provTypes[type] = prov;
			}

			return [mapTypes, provTypes];
		}
		// HERE-specific
		, createHereTrafficUrl: function()
		{
			var appId = "XmpXGaGLJPmmdyZCWKk4";
			var appCode = "-kxkwseuxXRu_tDdptcJgQ";
			var scheme = "normal.day.grey" + ((g.PixelScale > 1) ? ".mobile" : "");
			var size = (scheme.indexOf("mobile") >= 0) ? 512 : 256;
			return { prefix: ".traffic.maps.api.here.com/maptile/2.1/flowtile/newest/" + scheme + "/",
					 suffix: "/" + size + "/png?app_id=" + appId + "&app_code=" + appCode + "&pois=true"
			};
		}

		///////////////////////////////////////////////////////////////////////////////
		// Shared config handling
		///////////////////////////////////////////////////////////////////////////////

		, loadCfg: function (id, cfg, callback, context)
		{
			//console.log("loadCfg -- id = " + (typeof id) + " -- " + id);
			var lib = g.lib;
			var loaded = g.MSG.CfgLoaded;

			context || (context = {});

			if (typeof id === 'object')
			{
				//console.log("parse inline config");
				context.profile = lib.parseCfg(cfg, id, context.profile);
				callback.notify(loaded, context);
				return;
			}

			//var path = "//s3.amazonaws.com/static.glympse.com/branding/" + lib.decodeShortGuid(id, "/") + ".jsonp";
			var path = g.assetBase + '/branding/' + lib.decodeShortGuid(id, '/') + '.jsonp';

			$.jsonp({
				url: path, cache: true
				//, data: { next: next, oauth_token: accessToken }
					, success: function (data)
					{
						//console.log("got cfg:" + data);
						cfg._progress.updateProgressItem(g.progress.ExtCfg, 4);
						context.profile = lib.parseCfg(cfg, data, context.profile);
						callback.notify(loaded, context);
					}
					, error: function (xOptions, status)
					{
						console.log("Error loading cfg:" + id + " -- " + status);
						cfg._progress.updateProgressItem(g.progress.ExtCfg, 5);
						callback.notify(loaded, context);
					}
			});
		}
		, getSprite: function(cfg, nameItem)
		{
			var sprites = cfg.extSprites;
			var sprite = null, len = sprites.length;
			for (var i = 0/*len - 1*/; i < len; i++)//>= 0; i--)
			{
				var sp = sprites[i];
				var item = sp.getCfg()[nameItem];
				//console.log("item[" + i + "]=" + item + ", search=" + nameItem + " -- ss:" + sp + " -- " + JSON.stringify(item));
				if (item)
				{
					sprite = { cfg: item, loaded: sp.isLoaded(), img: sp.getImg() };
					break;
				}
			}
			return sprite;
		}
		, parseCfg: function (cfgCurr, cfgNew, profile)
		{
			// New sprite sheet
			var path = cfgNew.imgPath;
			var newSpriteSheet;

			g.PixelScale = (typeof glK !== "undefined") ? glK.getPixelScale() : cfgCurr.baseScale;

			if (path && cfgNew.IMG)
			{
				var scaleTarget = "ss" + Math.round(g.PixelScale * 10).toFixed(0);
				if (cfgNew.IMG[scaleTarget])
				{
					newSpriteSheet = new g.lib.SpriteSheet(cfgNew.IMG[scaleTarget]
														  , ((path.indexOf("http:") < 0 && path.indexOf("//") < 0) ? cfgCurr.basePath : "") + path + scaleTarget + ".png"
														  , null);
				}
			}

			// Only update profile stuff if we're already themed
			if (cfgCurr.initialized)
			{
				if (newSpriteSheet)
				{
					cfgCurr.extSprites.push(newSpriteSheet);	// Set up for loading
				}
			}
			else
			{
				if (newSpriteSheet)
				{
					cfgCurr.ssCfg = newSpriteSheet.getCfg();
					cfgCurr.ss = newSpriteSheet.getPath();
					//cfgCurr.extSprites.unshift = [newSpriteSheet];	// Replace the original sprite sheet info with the new theme's sprite info
					cfgCurr.extSprites.unshift(newSpriteSheet);		// Insert new sprite sheet to the front as the default, but maintain original/other sprite sheets for css/fallback support
				}

				// New default settings, but save important stuff first
				var svcs = cfgCurr.services;
				//cfgNew.defaults.trailLength = 600;
				$.extend(cfgCurr, cfgNew.defaults);
				cfgCurr.services = svcs;

				// Hack for HH - disable any non-0 auto-remove setting
				//cfgCurr.expiredPeriodRemove = 0;

				// New intro
				$.extend(cfgCurr.intro, cfgNew.intro);

				// New area
				var area = cfgNew.area;
				if (area)
				{
					//cfgCurr.area = area;
					$.extend(true, g.Area[cfgCurr.areas[0]], area);	// FIXME: Hokey current config Area reference...

					var b = area.bounds;
					if (b)
					{
						//console.log("add bounds");
						var b0 = new g.LatLng(b[0].lat, b[0].lng);
						var b1 = new g.LatLng(b[1].lat, b[1].lng);
						area.bounds = (!b0.equals(b1)) ? [b0, b1] : null;
					}
				}

				// Add back in original hosted/query string params, if allowed
				var flags = g.excludeConfig;
				var excludeFlags = cfgCurr.configExcludes || 0;
				//console.log("exclude flags=" + excludeFlags);
				/*jslint bitwise: true */
				if (!(excludeFlags & flags.Hosted)) $.extend(cfgCurr, cfgCurr.paramsHosted);
				if (!(excludeFlags & flags.QueryString)) $.extend(cfgCurr, cfgCurr.paramsQuery);
				/*jslint bitwise: false */

				g.Application.customInit(cfgCurr);
			}

			// New user profiles
			if (cfgNew.profiles && cfgNew.profiles.length > 0)
			{
				if (!cfgCurr.initialized)
				{
					cfgCurr.profiles = cfgNew.profiles;
				}

				// Update given profile
				if (profile && newSpriteSheet)
				{
					var old = profile;
					profile = g.models.InviteLoader.getProfile(profile.id, cfgNew);
					profile.ss = newSpriteSheet;
					profile.getMessage = old.getMessage;
					profile.requestRoute = old.requestRoute;
				}
			}

			// Update launch/download settings
			if (cfgNew.downloadOnly)
			{
				g.lib.defaultDownload(cfgCurr);
			}

			// Ensure we only update core settings once
			cfgCurr.initialized = true;

			return profile;
		}


		///////////////////////////////////////////////////////////////////////////////
		// Text formatting helpers
		///////////////////////////////////////////////////////////////////////////////

		, durationToStr: function (duration, cfg)
		{
			if (duration < 0 || isNaN(duration))
			{
				duration = 0;
			}

			var cnt = 0;
			//var days = Math.floor(duration / 86400);
			var hours = Math.floor(duration / 3600) % 24;
			var minutes = Math.floor(duration / 60) % 60;
			var seconds = (duration % 60);
			var str = "";
			var units = (cfg) ? (" " + g.lib.elapsedToStr(cfg, duration, true)) : "";

			//if (days > 0)
			//{
			//	cnt++;
			//	str = days + "d:";
			//}

			if (hours > 0)
			{
				cnt++;
				str += ((/*days > 0 &&*/hours < 10) ? "0" : "") + hours + ":";
			}

			if (cnt < 2)// if (minutes > 0)
			{
				cnt++;
				str += ((minutes < 10) ? "0" : "") + Math.floor(minutes);
			}

			return (str + ((cnt < 2) ? (":" + ((seconds < 10) ? "0" : "") + Math.floor(seconds)) : "") + units);
		}
		, elapsedToStr: function (cfg, elapsed, unitsOnly)
		{
			var spc = " ";
			var d = cfg.loc.data;

			if (elapsed < 0 || isNaN(elapsed))
			{
				elapsed = 0;
			}

			if (elapsed < 60)
			{
				elapsed = Math.round(elapsed);
				return (((unitsOnly) ? "" : (elapsed + spc)) + ((1 === elapsed) ? d.M_SECOND : d.M_SECONDS));
			}
			else if (elapsed < 3600)
			{
				var minutes = Math.round(elapsed / 60);
				return (((unitsOnly) ? "" : (minutes + spc)) + ((1 === minutes) ? d.M_MINUTE : d.M_MINUTES));
			}
			else if (elapsed < 86400)
			{
				var hours = Math.round(elapsed / 3600);//.toFixed(1);
				return (((unitsOnly) ? "" : (hours + spc)) + ((1 === hours) ? d.M_HOUR : d.M_HOURS));
			}
			var days = Math.round(elapsed / 86400);//.toFixed(1);
			return (((unitsOnly) ? "" : (days + spc)) + ((1 === days) ? d.M_DAY : d.M_DAYS));
		}
		, secondsElapsed: function (ts)
		{
			return Math.round((+new Date - ts) / 1000);
		}
		, simpleTime: function(val, loc, result)
		{
			if (!val || val < 0 || isNaN(val))
			{
				val = 0;
			}

			var units = loc.getString('M_MINUTES');
			var t = Math.floor(val / (24 * 60 * 60));
			if (t)
			{
				units = loc.getString('M_DAYS');
			}
			else
			{
				t = Math.floor(val / (60 * 60));
				if (t)
				{
					units = loc.getString('M_HOURS');
				}
				else
				{
					t = Math.floor(val / 60);
					if (!t)
					{
						t = ((val && Math.floor(val)) || '--');
						units = (val && loc.getString('M_SECONDS')) || units;
					}
				}
			}

			result.t = t;
			result.units = units;
		}

		//TODO: localized date format?
		, formatDate: function (ts)
		{
			var lbl = '';
			var ext = '';

			if (ts)
			{
				try
				{
					// Works in modern browsers..
					var time = new Date(ts).toLocaleTimeString([], { timeZoneName: 'short', hour:'2-digit', minute:'2-digit' });
					var items = time.split(' ');
					lbl = items.shift();
					ext = items.join(' ');
				}
				catch (e)
				{
					var date = new Date(ts);
					var h = date.getHours();
					var m = date.getMinutes();
					var ampm = ((h >= 12) ? 'PM' : 'AM');

					h = h % 12;
					h = (h || 12);

					lbl = h + ':' + ((m < 10) ? '0' : '') + num;
					ext = ampm;
				}
			}

			return { lbl: lbl, ext: ext };
		}


		, syncRAF: function ()
		{
			var vendors = ["o", "ms", "moz", "webkit"];
			for (var x = vendors.length - 1; x >= 0 && !window.requestAnimationFrame; x--)
			{
				//console.log("trying:" + vendors[x]);
				window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
				window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame']
										   || window[vendors[x] + 'CancelRequestAnimationFrame'];
			}

			// If there isn't native browser support for rAF, then don't try to
			// emulate as it's a browser that is too old to be performant anyway
			//g.doFrameUpdate = (window.requestAnimationFrame != null);
			return (window.requestAnimationFrame !== null);
		}

		///////////////////////////////////////////////////////////////////////////////
		// Cookies
		///////////////////////////////////////////////////////////////////////////////

		, domain: window.location.hostname
		, cfgCookie: defCfg
		, getCookie: function(id)
		{
			if (localStorage)
			{
				var val = localStorage.getItem(id);

				// Some implementations have the localStorage interface available, but not
				// writable (i.e. iOS Safari), so need to fall through to cookie lookup just in case.
				if (val !== null)
				{
					return val;
				}
			}

			var cookies = document.cookie.split(';');

			if (cookies)
			{
				for (var i = cookies.length - 1; i >= 0; i--)
				{
					var c = cookies[i];
					var idx = c.indexOf('=');
					var x = c.substr(0, idx);

					if (decodeURIComponent(x.replace(/^\s+|\s+$/g, '')) === id)
					{
						return decodeURIComponent(c.substr(idx + 1));
					}
				}
			}

			return null;
		}
		, setCookie: function(id, val, daysExpire)
		{
			if (localStorage)
			{
				try
				{
					// Some implementations exception when localStorage is defined, but
					// not available (i.e. iOS Safari in Private mode)
					if (val === undefined)
					{
						localStorage.removeItem(id);
					}
					else
					{
						localStorage.setItem(id, val);
					}

					return;
				}
				catch (e)
				{
					console.log('localStorage error', e);
				}
			}

			var d = new Date();
			d.setTime(d.getTime() + (daysExpire|| 365)  * 86400 * 1000);
			document.cookie = id + '=' + val + '; expires=' + d.toGMTString() + '; domain=' + lib.domain + '; path=/';
		}
		, getConfigValue: function(id)
		{
			var config = lib.getCookie(defCfg);
			return (config) ? JSON.parse(config)[id] : null;
		}
		, setConfigValue: function(id, val)
		{
			var config = lib.getCookie(defCfg);

			config = (config) ? JSON.parse(config) : {};
			config[id] = val;

			lib.setCookie(defCfg, JSON.stringify(config));
		}

		/////////////////////////////////////////////////////////////////////////////////////////////
		// Canvas viewer-specific -- FIXME: Break this out so Css viewer doesn't have to load this
		/////////////////////////////////////////////////////////////////////////////////////////////

		, setFill: function(target, val)
		{
			var valType = (typeof val);
			if (valType === 'string')
			{
				target.setFill(val);
			}
			else if (valType === 'object')
			{
				target.setAttrs(val);
			}
			else
			{
				console.log('[lib]: Cannot set fill of type "' + valType + '". Value passed: ', val);
			}
		}
		, scaleTextToFit: function (o, txt, startSize, minSize, maxWidth, doEllipsis, maxHeight)
		{
			o.setWidth("auto");
			o.setFontSize(startSize);
			o.setText(txt);

			var len, currWidth;
			while (true)
			{
				currWidth = o.getTextWidth();
				if (currWidth <= maxWidth) return;
				if (--startSize < minSize) break;
				o.setFontSize(startSize);
			}

			if (maxHeight > o.getHeight())
			{
				o.setWidth(maxWidth);
				o.setWrap("word");

				if (doEllipsis && txt)
				{
					len = txt.length - 1;
					while (o.getHeight() > maxHeight && len)
					{
						o.setText(txt.substr(0, len--) + "...");
					}
				}

				return;
			}

			if (doEllipsis && txt)
			{
				len = txt.length - 1;
				while (o.getTextWidth() > maxWidth && len)
				{
					o.setText(txt.substr(0, len--) + "...");
				}
			}
		}

		// Support for multi-layer static + color rendering (i.e. user marker/destination/etc.)
		// Settings format: { w: reference_width, h: reference_height
		//						  , layer: reference_kinetic_Layer
		//						  , ssImage: sprite_sheet_image_reference (usually found in cfg.ssImage)
		//						  , color: RRGGBB_color_formatted: { r: [0-255], g: [0-255], b: [0-255] } -- null = no color transform
		//						  , items: array_of_items: [{ id: spritesheet_id, clr: flag_to_color_transform },... { id: ... } ]
		//					}
		//
		// Note, order of items in settings.items determines order in which drawn (first item = bottom item)
		// Return --> updated passed settings object with new w and h params that accommodate the largest item found. Can
		//            check settings.found to determine if any sprites were found and added
		, addGfx: function (cfg, settings)
		{
			if (!settings || !settings.items) return settings;

			var items = settings.items;
			var len = items.length;
			var i, item, img, tile, maxH = 0, scale = glK.PixelScale;
			//console.log("!! " + len);
			settings.found = false;

			for (i = 0; i < len; i++)
			{
				item = items[i];
				tile = cfg.ssCfg[item.id];
				//console.log("id=" + item.id + ", tile=" + tile + " -- " + JSON.stringify(cfg.ssCfg));
				// If not in the current sprite sheet, see if the resource exists elsewhere
				if (!tile)
				{
					var spr = g.lib.getSprite(cfg, item.id);
					if (spr)
					{
						tile = spr.cfg;
						item.sprite = spr;
					}
				}

				if (tile)
				{
					settings.found = true;
					maxH = Math.max(maxH, tile.h / scale);
					settings.w = Math.max(settings.w, tile.w / scale);
				}
			}

			function updateLayer()
			{
				if (settings.layer) settings.layer.draw();
			}

			for (i = 0; settings.found && i < len; i++)
			{
				item = items[i];
				tile = (item.sprite) ? item.sprite.cfg : cfg.ssCfg[item.id];
				if (tile)
				{
					/*var clrz = null;
					if (item.clr && settings.color != null)
					{
						if (settings.color instanceof Array)
						{
							clrz = [];
							for (var j = 0; j < settings.color.length; j++)
							{
								clrz.push(g.lib.colorCss(settings.color[j]));
							}
						}
						else
						{
							clrz = settings.color;
						}
						console.log("array = " + (settings.color instanceof Array) + " -- " + settings.color + " -- " + clrz);
					}*/
					var itemImg = (item.sprite) ? item.sprite.img : settings.ssImage;

					img = new glK.Image({
						x: Math.round((settings.w - tile.w / scale) * 0.5)
											, y: settings.h
											, width: tile.w, height: tile.h
											, image: itemImg
											, scale: { x: 1 / scale, y: 1 / scale }
											, crop: { x: tile.x, y: tile.y, width: tile.w, height: tile.h }
											, listening: false
					});

					//console.log("item.clr=" + item.clr + " -- " + settings.color);
					if (item.clr && settings.color !== null)
					{
						var c = settings.color;
						c.clip = {
							x: tile.x,
							y: tile.y,
							w: tile.w,
							h: tile.h,
							rw: itemImg.width,
							rh: itemImg.height
						};
						//console.log("c=" + JSON.stringify(c));

						img.applyFilter(glK.Filters.Color, c, updateLayer);
					}

					settings.img = img;
					if (settings.layer) settings.layer.add(img);
				}
			}

			settings.h += maxH;
			return settings;
		}

		, createCssClass: function(selector, style)
		{
			if (!document.styleSheets) return;

			var head = document.getElementsByTagName('head');
			if (!head || head.length === 0) return;

			function addRule(styleSheet, mtype)
			{
				if (!styleSheet)
				{
					return false;
				}

				var i, len, rule, st, rules;

				if (mtype === 'string')
				{
					rules = styleSheet.rules;
					len = (rules && rules.length) || 0;

					for (i = 0; i < len; i++)
					{
						rule = rules[i];
						st = rule.selectorText;
						if (st && st.toLowerCase() === selector.toLowerCase())
						{
							rule.style.cssText = style;
							return true;
						}
					}

					styleSheet.addRule(selector, style);
					return true;
				}
				else if (mtype === 'object')
				{
					try
					{
						rules = styleSheet.rule;
						len = (rules) ? rules.length : 0;

						for (i = 0; i < len; i++)
						{
							rule = rules[i];
							st = rule.selectorText;
							if (st && st.toLowerCase() === selector.toLowerCase())
							{
								rule.style.cssText = style;
								return;
							}
						}
					}
					catch (e)
					{
						// Likely due to XSS issue for some browsers
						return false;
					}

					// create a new style sheet
					styleSheet.insertRule(selector + '{' + style + '}', len);
					return true;
				}

				return false;
			}

			var ss, sheets = document.styleSheets;

			/*
			var media, mediaType;
			var i, len = sheets.length;

			//console.log('>> selector: "' + selector + '"\n>> style: ' + style + '\n>> len: ' + len);
			for (i = 0; i < len; i++)
			{
				ss = sheets[i];
				if (ss.disabled) continue;

				media = ss.media;
				mediaType = typeof media;

				//console.log('[' + i + '] __ ss: ', ss, '   __ media: ', media, '\n   __ type: ' + mediaType + '\n   __ href: ' + ss.href);

				if (mediaType === 'string')
				{
					if (media === '' || (media.indexOf('screen') >= 0))
					{
						if (addRule(ss, mediaType))
						{
							return true;
						}
					}
				}
				else if (mediaType === 'object')
				{
					try
					{
						if (media && ((!ss.href && media.mediaText === '') || (media.mediaText.indexOf('screen') >= 0)))
						{
							if (addRule(ss, mediaType))
							{
								return true;
							}
						}
					}
					catch(e)
					{
					}
				}
			}*/

			var styleSheetElement = document.createElement('style');
			styleSheetElement.type = 'text/css';

			head = head[0];
			head.insertBefore(styleSheetElement, head.firstChild);

			/*sheets = document.styleSheets;
			len = sheets.length;

			for (i = 0; i < len; i++)
			{
				if (sheets[i].disabled) continue;
				ss = sheets[i];
			}*/

			ss = sheets[0];
			return addRule(ss, typeof ss.media);
		}


		/////////////////////////////////////////////////////////////////////////////////////////////
		// Css viewer-specific -- FIXME: Break this out so Canvas viewer doesn't have to load this
		/////////////////////////////////////////////////////////////////////////////////////////////

		, genSpriteImgDom: function (cfg, ssReference, dom, imgName, left, top, center, id, ignoreWidth, ignoreHeight, scaleFactor)
		{
			if (!ssReference)
			{
				//(ssReference = cfg.ssBase);
				var sprites = cfg.extSprites;
				var len = ((sprites && sprites.length) || 0);
				for (var i = 0; i < len; i++)
				{
					var item = sprites[i].getCfg()[imgName];
					//console.log("item=" + nameItem + " -- ss:" + sprites[i] + " -- " + JSON.stringify(item));
					if (item)
					{
						ssReference = sprites[i];
						break;
					}
				}
			}

			if (!ssReference)
			{
				console.log("unknown image: " + imgName);
				return false;
			}

			var ssCfg = ssReference.getCfg();
			var img = ssCfg[imgName];
			var s = 1 / g.PixelScale * ((scaleFactor) ? scaleFactor : 1);

			//console.log("<<<< Using: " + imgName + " -- " + ssReference.getPath());

			//console.log("img=" + img);
			if (!g.transImg)
			{
				//var c = document.createElement("canvas");
				//c.width = 1;
				//c.height = 1;
				//g.transImg = c.toDataURL("image/png");
				g.transImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2NkAAIAAAoAAggA9GkAAAAASUVORK5CYII=";
				//console.log("trans=" + g.transImg);
			}

			//dom.src = cfg.imgPath + "mask.png";
			dom.src = g.transImg;
			$(dom).css("position", "relative");
			$(dom).css("left", left);
			$(dom).css("top", top);

			//console.log("name=" + imgName + " -- " + img);
			if (id) dom.id = id;
			if (center)
			{
				$(dom).css("margin", "0 auto");
				$(dom).css("display", "block");
			}
			if (!ignoreWidth)
			{
				$(dom).css("width", Math.round(img.w * s));
			}
			if (!ignoreHeight)
			{
				$(dom).css("height", Math.round(img.h * s));
			}
			$(dom).css("background", "url(" + ssReference.getPath() + ")");
			$(dom).css("background-position", "-" + Math.round(img.x * s) + "px -" + Math.round(img.y * s) + "px");

			$(dom).css("background-size", Math.round(ssCfg.meta.w * s) + "px " + Math.round(ssCfg.meta.h * s) + "px");

			return true;
		}
		/*, changeSpriteImg: function (cfg, img, name)
		{
		var imgCfg = cfg.ssCfg[name];
		img.css("background-position", ('-' + (imgCfg.x / g.PixelScale) + 'px -' + (imgCfg.y / g.PixelScale) + 'px'));
		}*/
		, setTextSize: function (o, txt, startSize, minSize, maxWidth, doEllipsis, maxHeight, callback)
		{
			if (txt)
			{
				rafTimeout(function()
				{
					g.lib._fitText(o, startSize, minSize, maxWidth, doEllipsis, maxHeight, callback);
				}, 50);
			}

			$(o).css({ fontSize: (startSize + "px"), visibility: "hidden" });
			if (!callback)
			{
				$(o).css({ maxWidth: (maxWidth + "px") });
			}
			$(o).text(txt);
		}
		, _fitText: function (o, startSize, minSize, maxWidth, doEllipsis, maxHeight, callback)
		{
			var len;
			$(o).css({ visibility: "visible" });

			while (true)
			{
				if ($(o).width() < maxWidth)
				{
					if (callback)
					{
						rafTimeout(callback, 50);
					}
					return;
				}
				if (--startSize < minSize) break;
				$(o).css({ fontSize: startSize + "px" });
			}

			var txt = $(o).text();

			if (maxHeight > $(o).height())
			{
				$(o).css({ whiteSpace: "normal", lineHeight: startSize + 2 + "px" });
				if (doEllipsis && txt)
				{
					len = txt.length - 1;
					while ($(o).height() > maxHeight && len)
					{
						$(o).text(txt.substr(0, len--) + "...");
					}
				}

				if (callback)
				{
					rafTimeout(callback, 50);
				}
				return;
			}

			if (doEllipsis && txt)
			{
				len = txt.length - 1;
				while ($(o).width() > maxWidth && len)
				{
					$(o).text(txt.substr(0, len--) + "...");
				}
			}

			if (callback)
			{
				rafTimeout(callback, 50);
			}
		}

		///////////////////////////////////////////////////////////////////////////////
		// Create FontStyle object for css mode based on property for canvas mode
		///////////////////////////////////////////////////////////////////////////////

		, getFontStyleObjectForCss: function (valStyle)
		{
			//in canvas mode there is fontStyle property support. It can be normal, bold, or italic.
			//These property should be separated to fontStyle and fontWeight for css.
			var styleObj = {};
			styleObj[(valStyle === 'italic') ? 'fontStyle' : 'fontWeight'] = valStyle;
			return styleObj;
		}
		/**
		 * Register "wheel" event listener (Cross-browser)
		 * Based on [MSDN](https://developer.mozilla.org/en-US/docs/Web/Events/wheel#Listening_to_this_event_across_browser).
		 */
		, addWheelListener: function (elem, callback, useCapture)
		{
			var prefix = '', _addEventListener, support;

			if (!_addEventListener)
			{
				// detect event model
				if (window.addEventListener)
				{
					_addEventListener = 'addEventListener';
				}
				else
				{
					_addEventListener = 'attachEvent';
					prefix = 'on';
				}

				// detect available wheel event
				support = ('onwheel' in document.createElement('div')) ? 'wheel' : // Modern browsers support "wheel"
					(document.onmousewheel !== undefined) ? 'mousewheel' : // Webkit and IE support at least "mousewheel"
						'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox
			}

			_addWheelListener(elem, support, callback, useCapture);

			// handle MozMousePixelScroll in older Firefox
			if (support === 'DOMMouseScroll')
			{
				_addWheelListener(elem, 'MozMousePixelScroll', callback, useCapture);
			}

			function _addWheelListener(elem, eventName, callback, useCapture)
			{
				elem[_addEventListener](prefix + eventName, _callback(callback), (useCapture || false));
			}

			function _callback(callback)
			{
				if (support === 'wheel')
				{
					return callback;
				}
				else
				{
					return function (originalEvent)
					{
						return callback(_normalizeEvent(originalEvent));
					}
				}
			}
			function _normalizeEvent(originalEvent)
			{
				originalEvent = (originalEvent || window.event);

				// create a normalized event object
				var event = {
					// keep a ref to the original event object
					originalEvent: originalEvent,
					target: originalEvent.target || originalEvent.srcElement,
					type: 'wheel',
					deltaMode: originalEvent.type === 'MozMousePixelScroll' ? 0 : 1,
					deltaX: 0,
					deltaY: 0,
					deltaZ: 0,
					preventDefault: function ()
					{
						originalEvent.preventDefault ?
							originalEvent.preventDefault() :
							originalEvent.returnValue = false;
					}
				};

				// calculate deltaY (and deltaX) according to the event
				if (support === 'mousewheel')
				{
					event.deltaY = -1 / 40 * originalEvent.wheelDelta;
					// Webkit also support wheelDeltaX
					originalEvent.wheelDeltaX && ( event.deltaX = -1 / 40 * originalEvent.wheelDeltaX );
				} else
				{
					event.deltaY = originalEvent.detail;
				}

				return event;
			}
		}
	};

	g.lib = lib;


	///////////////////////////////////////////////////////////////////////////////
	// POLYFILLS
	///////////////////////////////////////////////////////////////////////////////

	// Support for incomplete ECMA5 implementations (i.e. IE versions <= 8)
	// Reference: http://stackoverflow.com/questions/2790001/fixing-javascript-array-functions-in-internet-explorer-indexof-foreach-etc
	// Add ECMA262-5 Array methods if not supported natively
	//
	if (!('indexOf' in Array.prototype))
	{
		Array.prototype.indexOf = function (find, i /*opt*/)
		{
			var t = this;
			var len = t.length;
			if (i === undefined) i = 0;
			if (i < 0) i += len;
			if (i < 0) i = 0;
			for (var n = len; i < n; i++)
				if (i in t && t[i] === find)
					return i;
			return -1;
		};
	}
	if (!('lastIndexOf' in Array.prototype))
	{
		Array.prototype.lastIndexOf = function (find, i /*opt*/)
		{
			var t = this;
			var len = t.length;
			if (i === undefined) i = len - 1;
			if (i < 0) i += len;
			if (i > len - 1) i = len - 1;
			for (i++; i-- > 0;) /* i++ because from-argument is sadly inclusive */
			{
				if (i in t && t[i] === find) return i;
			}
			return -1;
		};
	}
	if (!('forEach' in Array.prototype))
	{
		Array.prototype.forEach = function (action, that /*opt*/)
		{
			var t = this;
			for (var i = 0, n = t.length; i < n; i++)
			{
				if (i in t) action.call(that, t[i], i, t);
			}
		};
	}
	if (!('map' in Array.prototype))
	{
		Array.prototype.map = function (mapper, that /*opt*/)
		{
			var t = this;
			var len = t.length;
			var other = new Array(len);
			for (var i = 0; i < len; i++)
			{
				if (i in t) other[i] = mapper.call(that, t[i], i, t);
			}
			return other;
		};
	}
	if (!('filter' in Array.prototype))
	{
		Array.prototype.filter = function (filter, that /*opt*/)
		{
			var t = this;
			var other = [], v;
			for (var i = 0, len = t.length; i < len; i++)
			{
				if (i in t && filter.call(that, v = t[i], i, t)) other.push(v);
			}
			return other;
		}
	}
	if (!('trim' in String.prototype))
	{
		String.prototype.trim = function () { return this.replace(/^\s+|\s+$/g, ''); };
	}

	if (!Array.isArray)
	{
		Array.isArray = function (arg)
		{
			return Object.prototype.toString.call(arg) === '[object Array]';
		}
	}

	// Pulled from Mozilla: https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Function/bind
	// (on 27 Mar 2013)
	// TODO: Check out https://github.com/kriskowal/es5-shim/blob/master/es5-shim.js for possible replacement
	/*
	if (!Function.prototype.bind)
	{
		Function.prototype.bind = function (oThis)
		{
			if (typeof this !== "function")
			{
				// closest thing possible to the ECMAScript 5 internal IsCallable function
				throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
			}

			var aArgs = Array.prototype.slice.call(arguments, 1),
				fToBind = this,
				fNOP = function () { },
				fBound = function ()
				{
					return fToBind.apply(this instanceof fNOP && oThis
										   ? this
										   : oThis,
										 aArgs.concat(Array.prototype.slice.call(arguments)));
				};

			fNOP.prototype = this.prototype;
			fBound.prototype = new fNOP();

			return fBound;
		};
	}*/

	var doCustomEvtPoly = (!window.CustomEvent);
	if (!doCustomEvtPoly)
	{
		try
		{
			var ev = new window.CustomEvent("foo", { "detail": { "bar": true } });
		}
		catch (e)
		{
			doCustomEvtPoly = true;
		}
	}
	//console.log("doCustomEvtPoly = " + doCustomEvtPoly);
	if (doCustomEvtPoly)
	{
		function customEvent(event, params)
		{
			params || (params = { detail: undefined });
			params.bubbles || (params.bubbles = false);
			params.cancelable || (params.cancelable = false);

			var evt = document.createEvent("CustomEvent");

			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

			return evt;
		}

		customEvent.prototype = (window.Event && window.Event.prototype);
		window.CustomEvent = customEvent;
	}
})(glympse, window, document);
