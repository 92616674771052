glympse.Loc.Langs["en"] =
glympse.Loc.Langs["en_us"] =
{
    locale: ["en","en_us"],
    generated: "2014-09-04T21:11:33.5810641Z",
    strings: {
        M_ALTITUDE: "Altitude",
        M_FEET: "ft",
        M_METERS: "m",
        M_ETA: "eta",
        M_DESTINATION: "Destination",
        M_EXPIRED: "Expired",
        M_CURRENT_LOCATION: "Current Location",
        M_CHECKIN: "Check In",
        M_GROUP: "Group",
        M_JOIN_GROUP: "Join Group",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "OFF",
        M_ON: "ON",
        M_SETTINGS: "Settings",
        M_UNITS: "Units",
        M_CLOSE: "Close",
        M_VIEW_MAP: "View Map",
        M_SEND_GLYMPSE: "Send Glympse",
        M_VIEW : "View",
        M_MAP: "Map",
        M_AGO: "ago",
        M_LAST_UPDATED: "Updated",
        M_SPEED: "Speed",
        M_EXPIRES_IN: "Expires in",
        M_ARRIVES_IN: "Arrives in",
        M_DAY: "day",
        M_DAYS: "days",
        M_HOUR: "hr",
        M_HOURS: "hrs",
        M_LOADING: "Loading, please wait...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "never",
        M_SECOND: "sec",
        M_SECONDS: "sec",
        M_REMAINING: "remaining",
        M_EXPIRED_GLYMPSE: "The Glympse from %1$s expired %2$s ago",
        M_EXPIRED_GLYMPSE_BTN: "View Last Update",
        M_INVALID_GLYMPSE: "The Glympse %1$s is no longer available",
        M_NO_LOCATION: "Getting location for %1$s Please wait...",
        M_NO_MEMBERS: "Nobody currently in group %1$s Waiting for Glympses...",
        M_NO_USERS: "Nobody sharing in topic %1$s Waiting for locations...",
        M_WAITING_TWITTER: "Waiting for %1$s to share their location",
        M_REQUEST_GLYMPSE: "Request from %1$s to share your location",
        M_DOWNLOAD_APP: "Get App",
        M_LAUNCH_APP: "View in App",
        M_GET_ADDR: "Retrieving address",
        M_SHARE_LOC: "Share your location!",
        M_STREET: "Street",
        M_SATELLITE: "Satellite",
        M_TRAFFIC: "Traffic",
        M_YOUR_LOCATION: "Your location",
        M_DEALS: "Deals",
        M_WEATHER: "Weather",
        M_HELP: "Help",
        M_FEEDBACK: "Feedback",
        M_DEBUG: "Debug",
        M_SUBMIT_A_BUG: "Submit a bug",
        M_SEND_MESSAGE: "Send message",
        M_TAG: "Tag",
        messages: [
            "Almost there!",
            "Check this out!",
            "On my way!",
            "Headed home now!",
            "Here's where I'm hangin out tonight!",
            "Here I am, where are you?",
            "Hey, look where I am!",
            "I'll be right there.",
            "I'm almost there!",
            "I'm falling in love with Glympse!",
            "I forgot the salad, but I'm bringing the salami.",
            "I think I'm lost.",
            "In the neighborhood...",
            "Just checking in.",
            "Just leaving now.",
            "Let's meet here.",
            "Meet me here for lunch.",
            "Meet me when I get there.",
            "Need anything while I'm here?",
            "Running late :(",
            "See you in a bit.",
            "Stuck in traffic.",
            "Tag, you're it!",
            "Want anything to eat?",
            "Watch me."
        ],
        M_NEVER_LOCATION: "Location for %1$s not available",
        M_TERRAIN: "Terrain",
        M_DOWNLOAD_ACTIVE: "...to share your location",
        M_DOWNLOAD_INACTIVE: "...to request a new Glympse",
        M_DOWNLOAD_APP_ALT: "Get Glympse",
        M_POWERED_BY: "Powered By",
        M_HERE_HEADER_MOBILE: "",
        M_HERE_DL_MOBILE: "Get Glympse",
        M_HERE_DL_FULL: "Get Glympse",
        M_HERE_HEADER_FULL: "",
        M_NO_NAME: "A friend"
    }
};
