glympse.Loc.Langs["pt"] =
glympse.Loc.Langs["pt_br"] =
{
    locale: ["pt","pt_br"],
    generated: "2014-09-07T02:17:29.164485Z",
    strings: {
        M_ETA: "eta",
        M_DESTINATION: "Destino",
        M_EXPIRED: "Expirado",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupo",
        M_JOIN_GROUP: "Entrar no Grupo",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "DESLIGADO",
        M_ON: "LIGADO",
        M_SETTINGS: "Configurações",
        M_UNITS: "Unidades",
        M_CLOSE: "Fechar",
        M_VIEW_MAP: "Ver Mapa",
        M_SEND_GLYMPSE: "Enviar Glympse",
        M_VIEW : "Visualizar",
        M_MAP: "Mapa",
        M_AGO: "atrás",
        M_LAST_UPDATED: "Atualizado",
        M_SPEED: "Velocidade",
        M_EXPIRES_IN: "Expira em",
        M_ARRIVES_IN: "Chega em",
        M_DAY: "dia",
        M_DAYS: "dias",
        M_HOUR: "hr",
        M_HOURS: "hrs",
        M_LOADING: "Carregando, aguarde...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "nunca",
        M_SECOND: "seg",
        M_SECONDS: "seg",
        M_REMAINING: "restante",
        M_EXPIRED_GLYMPSE: "O Glympse de %1$s expirou %2$s atrás",
        M_EXPIRED_GLYMPSE_BTN: "Exibir última atualização",
        M_INVALID_GLYMPSE: "O Glympse %1$s não está mais disponível",
        M_NO_LOCATION: "Obtendo localização para %1$s Por favor, aguarde...",
        M_NO_MEMBERS: "Ninguém atualmente no grupo %1$s Aguardando Glympses...",
        M_NO_USERS: "Ninguém está compartilhando o tópico %1$s Aguardando localizações...",
        M_WAITING_TWITTER: "Aguardando %1$s compartilhar sua localização ",
        M_REQUEST_GLYMPSE: "Solicitação de %1$s para compartilhar sua localização",
        M_DOWNLOAD_APP: "Obter App",
        M_LAUNCH_APP: "Visualizar em App",
        M_GET_ADDR: "Recuperando endereço",
        M_SHARE_LOC: "Compartilhe a sua localização!",
        M_STREET: "Rua",
        M_SATELLITE: "Satélite",
        M_TRAFFIC: "Tráfego",
        M_YOUR_LOCATION: "Sua localização",
        M_DEALS: "Ofertas",
        M_WEATHER: "Tempo",
        M_HELP: "Ajuda",
        M_FEEDBACK: "Comentários",
        M_DEBUG: "Corrigir",
        M_SUBMIT_A_BUG: "Apresentar um bug",
        M_SEND_MESSAGE: "Enviar mensagem",
        M_TAG: "Tag",
        M_ALTITUDE: "Altitude",
        M_METERS: "m",
        M_FEET: "pé",
        messages: [
            "Quase lá!",
            "Saca só isso!",
            "Estou a caminho!",
            "Indo para casa agora!",
            "Aqui é onde vou ficar essa noite!",
            "Estou aqui, cadê você?",
            "Ei, olha onde eu estou!",
            "Estarei lá.",
            "Estou quase lá!",
            "Estou me apaixonando pelo Glympse!",
            "Eu esqueci a salada, mas estou trazendo o salame.",
            "Acho que estou perdido.",
            "Nas redondezas...",
            "Só checando.",
            "Tô saindo agora.",
            "Vamos nos encontrar aqui.",
            "Me encontra aqui para almoçar.",
            "Me encontra quando eu chegar.",
            "Precisa de alguma coisa enquanto estou aqui?",
            "Atrasado :(",
            "Vejo você daqui a pouco.",
            "Preso no trânsito.",
            "Tag, te peguei!",
            "Quer alguma coisa pra comer?",
            "Olha pra mim."
        ],
        M_NEVER_LOCATION: "A localização de %1$s está indisponível",
        M_TERRAIN: "Terreno",
        M_DOWNLOAD_ACTIVE: "...para compartilhar sua localização",
        M_DOWNLOAD_INACTIVE: "...para solicitar um novo Glympse",
        M_DOWNLOAD_APP_ALT: "Obter Glympse",
        M_HERE_DL_MOBILE: "Obter Glympse",
        M_HERE_DL_FULL: "Obter Glympse",
        M_NO_NAME: "Um amigo"
    }
};
