glympse.Loc.Langs["sv"] =
glympse.Loc.Langs["sv_se"] =
{
    locale: ["sv","sv_se"],
    generated: "2014-09-07T02:17:30.2096984Z",
    strings: {
        M_ETA: "ETA",
        M_DESTINATION: "Destination",
        M_EXPIRED: "Har gått ut",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grupp",
        M_JOIN_GROUP: "Gå med i grupp",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "AV",
        M_ON: "PÅ",
        M_SETTINGS: "Inställningar",
        M_UNITS: "Enheter",
        M_CLOSE: "Stäng",
        M_VIEW_MAP: "Se karta",
        M_SEND_GLYMPSE: "Skicka Glympse",
        M_VIEW : "Se",
        M_MAP: "Karta",
        M_AGO: "sedan",
        M_LAST_UPDATED: "Uppdaterad",
        M_SPEED: "Hastighet",
        M_EXPIRES_IN: "Går ut om",
        M_ARRIVES_IN: "Anländer om",
        M_DAY: "dag",
        M_DAYS: "dagar",
        M_HOUR: "h",
        M_HOURS: "h",
        M_LOADING: "Laddar, var god vänta...",
        M_MINUTE: "min",
        M_MINUTES: "min",
        M_NEVER: "aldrig",
        M_SECOND: "sek",
        M_SECONDS: "sek",
        M_REMAINING: "återstår",
        M_EXPIRED_GLYMPSE: "Glympse från %1$s gick ut för %2$s sedan",
        M_EXPIRED_GLYMPSE_BTN: "Se senaste uppdatering",
        M_INVALID_GLYMPSE: "Glympse %1$s är inte längre tillgänglig",
        M_NO_LOCATION: "Hämtar position för %1$s Vänta...",
        M_NO_MEMBERS: "För närvarade ingen i grupp %1$s Väntar på Glympses...",
        M_NO_USERS: "Ingen delar i ämnen %1$s Väntar på positioner...",
        M_WAITING_TWITTER: "Väntar på att %1$s ska dela sin position",
        M_REQUEST_GLYMPSE: "Begäran från %1$s att dela din position",
        M_DOWNLOAD_APP: "Hämta app",
        M_LAUNCH_APP: "Se i app",
        M_GET_ADDR: "Hämtar adress",
        M_SHARE_LOC: "Dela din position!",
        M_STREET: "Gata",
        M_SATELLITE: "Satellit ",
        M_TRAFFIC: "Trafik",
        M_YOUR_LOCATION: "Din position",
        M_DEALS: "Erbjudanden",
        M_WEATHER: "Väder",
        M_HELP: "Hjälp",
        M_FEEDBACK: "Synpunkter",
        M_DEBUG: "Felsök",
        M_SUBMIT_A_BUG: "Rapportera en bugg",
        M_SEND_MESSAGE: "Skicka meddelande",
        M_TAG: "Tagg",
        M_ALTITUDE: "Nadmorská výška",
        M_METERS: "metre",
        M_FEET: "stopy",
        messages: [
            "Nästan framme!",
            "Spana in det här!",
            "På väg!",
            "På väg hem nu!",
            "Här hänger jag i kväll!",
            "Jag är här, var är du?",
            "Kolla var jag är!",
            "Jag kommer dit.",
            "Jag är snart framme!",
            "Jag kärar ner mig i Glympse!",
            "Jag glömde sallad, men jag tar med salami.",
            "Jag har gått vilse.",
            "I närheten...",
            "Checkar in.",
            "Går nu.",
            "Vi möts här.",
            "Möt mig här för lunch.",
            "Möt mig där.",
            "Behöver du nåt när jag ändå är här?",
            "Är försenad :(",
            "Ses snart.",
            "Fast i trafiken.",
            "Kull, du har den!",
            "Vill du ha något att äta?",
            "Du ska få se."
        ],
        M_NEVER_LOCATION: "Platsangivelse för %1$s inte tillgänglig",
        M_TERRAIN: "Terräng",
        M_DOWNLOAD_ACTIVE: "...för att dela din platsangivelse",
        M_DOWNLOAD_INACTIVE: "...för att begära en ny Glympse",
        M_DOWNLOAD_APP_ALT: "Hämta Glympse",
        M_HERE_DL_MOBILE: "Hämta Glympse",
        M_HERE_DL_FULL: "Hämta Glympse",
        M_NO_NAME: "En vän"
    }
};
