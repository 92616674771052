glympse.Loc.Langs["tr"] =
glympse.Loc.Langs["tr_tr"] =
{
    locale: ["tr","tr_tr"],
    generated: "2014-09-04T21:11:35.2502855Z",
    strings: {
        M_ETA: "tvz",
        M_DESTINATION: "Varış yeri",
        M_EXPIRED: "Süre doldu",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "Grup",
        M_JOIN_GROUP: "Gruba Katıl",
        M_KMH: "km\/sa",
        M_MPH: "mil\/sa",
        M_OFF: "KAPALI",
        M_ON: "AÇIK",
        M_SETTINGS: "Ayarlar",
        M_UNITS: "Birim",
        M_CLOSE: "Kapat",
        M_VIEW_MAP: "Haritayı Görüntüle",
        M_SEND_GLYMPSE: "Glympse Gönder",
        M_VIEW : "Görüntüle",
        M_MAP: "Harita",
        M_AGO: "önce",
        M_LAST_UPDATED: "Güncellendi",
        M_SPEED: "Hız",
        M_EXPIRES_IN: "Bitiş süresi:",
        M_ARRIVES_IN: "Varış süresi:",
        M_DAY: "gün",
        M_DAYS: "gün",
        M_HOUR: "sa",
        M_HOURS: "sa",
        M_LOADING: "Yükleniyor, lütfen bekleyin...",
        M_MINUTE: "dk",
        M_MINUTES: "dk",
        M_NEVER: "asla",
        M_SECOND: "sn",
        M_SECONDS: "sn",
        M_REMAINING: "kalan",
        M_EXPIRED_GLYMPSE: "%1$s kullanıcısından gelen Glympse %2$s önce bitti",
        M_EXPIRED_GLYMPSE_BTN: "Son Güncellemeyi Görüntüle",
        M_INVALID_GLYMPSE: "Glympse %1$s artık mevcut değil",
        M_NO_LOCATION: "%1$s için konum alınıyor Lütfen bekleyin...",
        M_NO_MEMBERS: "%1$s grubunda şu anda kimse yok Glympse’ler bekleniyor...",
        M_NO_USERS: "%1$s konusunda kimse paylaşmıyor Konumlar bekleniyor...",
        M_WAITING_TWITTER: "%1$s kullanıcılarının konumlarını paylaşmasını bekliyor",
        M_REQUEST_GLYMPSE: "%1$s kullanıcısından konumunuzu paylaşmasını talep edin",
        M_DOWNLOAD_APP: "Uygulamayı Edinin",
        M_LAUNCH_APP: "Uygulamada Görüntüle",
        M_GET_ADDR: "Adresi alıyor",
        M_SHARE_LOC: "Konumunuzu paylaşın!",
        M_STREET: "Sokak",
        M_SATELLITE: "Uydu",
        M_TRAFFIC: "Trafik",
        M_YOUR_LOCATION: "Konumunuz",
        M_DEALS: "Fırsatlar",
        M_WEATHER: "Hava",
        M_HELP: "Yardım",
        M_FEEDBACK: "Geri Bildirim",
        M_DEBUG: "Sorun giderme",
        M_SUBMIT_A_BUG: "Bir sorun bildirin",
        M_SEND_MESSAGE: "Mesaj gönder",
        M_TAG: "Etiketle",
        M_ALTITUDE: "Yükseklik",
        M_METERS: "m",
        M_FEET: "fit",
        messages: [
            "Neredeyse oradayım!",
            "Şuna bak!",
            "Yoldayım!",
            "Şimdi eve doğru gidiyorum!",
            "Bu akşam burada takılıyorum!",
            "Ben buradayım, sen neredesin?",
            "Hey, bak ben neredeyim!",
            "Hemen orada olacağım.",
            "Neredeyse oradayım!",
            "Glympse’e aşık oluyorum!",
            "Salatayı unuttum, ama salamı getiriyorum.",
            "Galiba kayboldum.",
            "Yakınlardayım...",
            "Şimdi geldim.",
            "Şimdi ayrılıyorum.",
            "Hadi burada buluşalım.",
            "Öğle yemeği için burada buluşalım.",
            "Oraya gelince beni karşıla.",
            "Hazır buradayken bir şeye ihtiyacın var mı?",
            "Geç kalıyorum :(",
            "Birazdan görüşürüz.",
            "Trafiğe takıldım.",
            "Ebesin!",
            "Yiyecek bir şey ister misin?",
            "İzle beni."
        ],
        M_NEVER_LOCATION: "%1$s için konum mevcut değil",
        M_TERRAIN: "Arazi",
        M_DOWNLOAD_ACTIVE: "...konumunuzu paylaşmak için",
        M_DOWNLOAD_INACTIVE: "...yeni bir Glympse istemek için",
        M_DOWNLOAD_APP_ALT: "Glympse Edinin",
        M_HERE_DL_MOBILE: "Glympse Edinin",
        M_HERE_DL_FULL: "Glympse Edinin",
        M_NO_NAME: "Bir arkadaş"
    }
};
