// Application
///////////////////////////////////////////////////////////////////////////////
(function(g, window, document)
{
	var lib = g.lib;

	lib.Progress = function (host)
	{
		///////////////////////////////////////////////////////////////////////////////
		// FIELDS
		///////////////////////////////////////////////////////////////////////////////

		// ui
		var container;
		var progressItems = {};
		var progressList = [];

		// consts
		var cDiv = "div";

		// CTOR
		container = document.createElement(cDiv);
		$(container).css({ position: "absolute", width: "100%", bottom: 10, lineHeight: 0, zIndex: 10000, padding: 0, margin: 0, border: 0, background: "transparent", opacity: 1.6, textAlign:"center" });
		host.appendChild(container);


		///////////////////////////////////////////////////////////////////////////////
		// PUBLICS
		///////////////////////////////////////////////////////////////////////////////

		this.shutdown = function()
		{
			if (!progressItems) return;	// We've been shutdown, so ignore
			//return;
			for (var i = progressList.length - 1; i >= 0; i--)
			{
				container.removeChild(progressList[i][0]);
			}

			host.removeChild(container);

			progressItems = null;
			progressList = null;
			container = null;
		};

		this.listItems = function ()
		{
			if (!progressItems) return;	// We've been shutdown, so ignore

			for (var o in progressItems)
			{
				console.log("item: " + o);
			}
		};

		this.registerItem = function (id, status)
		{
			if (!progressItems) return;	// We've been shutdown, so ignore

			//var valid = (id !== null);

			if (!id) return;
			if (progressItems[id])
			{
				console.log("ERROR: " + id + " already registered!");
				return;
			}

			var o = $(document.createElement(cDiv));
			var border = "1px solid #f05b22";
			//var cc = { borderTop: ((valid) ? border : 0), borderBottom: ((valid) ? border : 0), width: (valid) ? 8 : 2, height: 8, margin: "0 0 0 0", padding: 0, display: "inline-block", background: "transparent" };
			var cc = { border: 0, borderTop: border, borderBottom: border, borderRight: border, width: 8, height: 8, margin: 0, padding: 0, display: "inline-block", background: "transparent" };
			o.css(cc);

			//if (valid)
			//{
			if (progressList.length === 0)
			{
				cc.borderLeft = border;
				o.css(cc);
			}
			progressItems[id] = o;
			var len = progressList.length;
			if (len > 0)
			{
				$(progressList[len - 1]).css({ borderRight: 0 });
			}
			progressList.push(o);

			this.updateProgressItem(id, status);
			//}

			container.appendChild(o[0]);
		};

		this.updateProgressItem = function(id, status)
		{
			if (!progressItems) return;	// We've been shutdown, so ignore

			var o = progressItems[id];

			if (!o)
			{
				console.log("[Progress]: Unknown \"" + id + "\"");
				return;
			}

			o.css({ background: ((status === 1) ? "#f05b22"	// loading
							  : ((status === 2) ? "#da1d52"	// error
							  : ((status === 3) ? "#f9a11b"	// loaded - status base
							  : ((status === 4) ? "#68a9ea"	// loaded - status alternate 1
							  : ((status === 5) ? "#3057a5"	// loaded - status alternate 2
							  : "transparent"				// no status - all others (i.e. 0 = registered only)
							  )))))
			});
		};
	};


})(glympse, window, document);
