glympse.Profiles =
{
	"users":
		[
			  { "name": "Adam Ericson", "avatar": "images/avatars/Zak.jpeg" }
			, { "name": "Brandon Panter", "avatar": "images/avatars/Brandon.jpeg" }
			, { "name": "Alec Smith", "avatar": "images/avatars/Danny.jpeg" }
			, { "name": "Larry Baker", "avatar": "images/avatars/Greg.jpeg" }
			, { "name": "Nickie Grant", "avatar": "images/avatars/Ashlyn.jpeg" }
			, { "name": "Maria Altinok", "avatar": "images/avatars/Cami.jpeg" }
			, { "name": "Emma Walsh", "avatar": "images/avatars/Ella.jpeg" }
		]
	, "profiles":
		[
			{
				"id": "default"
				, "color": "0x68A9EA"
				, "name": ""
				, "avatar": null
				, "iconType": "overhead"
				, "messages": []
				, "icon": null
				, "iconHtml": null
//				, "frames" : { "id":"XXX-YYY-ZZZ", "numFrames":4, "fps":8 }
			}
		]
};
