glympse.Loc.Langs["ko"] =
glympse.Loc.Langs["ko_kr"] =
{
    locale: ["ko","ko_kr"],
    generated: "2014-09-04T21:11:34.0490701Z",
    strings: {
        M_ETA: "도착",
        M_DESTINATION: "도착지",
        M_EXPIRED: "종료됨",
		M_CURRENT_LOCATION: "Current Location",
		M_CHECKIN: "Check In",
        M_GROUP: "그룹",
        M_JOIN_GROUP: "그룹에 참가",
        M_KMH: "km\/h",
        M_MPH: "mph",
        M_OFF: "OFF",
        M_ON: "ON",
        M_SETTINGS: "설정",
        M_UNITS: "단위",
        M_CLOSE: "닫기",
        M_VIEW_MAP: "지도보기",
        M_SEND_GLYMPSE: "Glympse 보내기",
        M_VIEW : "보기",
        M_MAP: "지도",
        M_AGO: "전",
        M_LAST_UPDATED: "업데이트 =",
        M_SPEED: "속도",
        M_EXPIRES_IN: "만료 =",
        M_ARRIVES_IN: "도착",
        M_DAY: "일",
        M_DAYS: "일",
        M_HOUR: "시간",
        M_HOURS: "시간",
        M_LOADING: "正在加载，请稍侯……",
        M_MINUTE: "분",
        M_MINUTES: "분",
        M_NEVER: "never",
        M_SECOND: "초",
        M_SECONDS: "초",
        M_REMAINING: "남은",
        M_EXPIRED_GLYMPSE: "%1$s의 Glympse가 %2$s전에 만료되었습니다",
        M_EXPIRED_GLYMPSE_BTN: "마지막 업데이트 보기",
        M_INVALID_GLYMPSE: "Glympse %1$s를 더 이상 사용할 수 없습니다",
        M_NO_LOCATION: "%1$s의 위치를 가져오고 있습니다. 잠시 기다려 주세요",
        M_NO_MEMBERS: "현재 %1$s그룹에 아무도 없습니다. Glympses를 기다리는 중입니다",
        M_NO_USERS: "%1$s에 대해 공유하는 사람이 없습니다. 위치정보를 기다리고 있습니다",
        M_WAITING_TWITTER: "%1$s가 위치를 공유하길 기다리고 있습니다",
        M_REQUEST_GLYMPSE: "%1$s가 내 위치를 요청하였습니다",
        M_DOWNLOAD_APP: "앱 구매하기",
        M_LAUNCH_APP: "앱에서 보기",
        M_GET_ADDR: "주소 가져오기",
        M_SHARE_LOC: "위치를 공유하세요!",
        M_STREET: "거리",
        M_SATELLITE: "위성",
        M_TRAFFIC: "교통",
        M_YOUR_LOCATION: "내 위치",
        M_DEALS: "할인 딜",
        M_WEATHER: "날씨",
        M_HELP: "도움말",
        M_FEEDBACK: "의견",
        M_DEBUG: "디버그",
        M_SUBMIT_A_BUG: "버그 제출하기",
        M_SEND_MESSAGE: "메시지 보내기",
        M_TAG: "태그",
        M_ALTITUDE: "고도",
        M_METERS: "미터",
        M_FEET: "피트",
        messages: [
            "거의 다 왔어요!",
            "이것 좀 보세요!",
            "가는 길이예요!",
            "집에 가는 길!",
            "오늘 여기 갈 거예요!",
            "저는 다 왔는데 어디세요?",
            "나 어디 있는지 봐봐!",
            "바로 갈게요.",
            "저는 거의 다 왔어요!",
            "Glympse 완전 좋아!",
            "샐러드는 잊어버렸는데 살라미는 가져갈게요.",
            "길을 잃어버린 것 같아요.",
            "근처에..",
            "지금 막 왔어.",
            "이제 막 떠나.",
            "여기서 만나요.",
            "여기서 점심 먹어요..",
            "거기 도착하면 만나.",
            "내가 여기 있는 동안 필요한 것 없어?",
            "늦을 것 같아 ",
            "곧 봐요",
            "차가 막혀요.",
            "잡았다. 니가 술래야!",
            "뭐 먹을래?",
            "나 봐봐."
        ],
        M_NEVER_LOCATION: "%1$에 대한 위치를 이용할 수 없습니다.",
        M_TERRAIN: "지형",
        M_DOWNLOAD_ACTIVE: "내 위치를 공유하려면...",
        M_DOWNLOAD_INACTIVE: "새로운 글림스를 요청하려면...",
        M_DOWNLOAD_APP_ALT: "글림스(Glympse) 구매하기",
        M_HERE_DL_MOBILE: "글림스(Glympse) 구매하기",
        M_HERE_DL_FULL: "글림스(Glympse) 구매하기",
        M_NO_NAME: "친구"
    }
};
